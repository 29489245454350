import axios from 'axios';
import {LearnersAddProjects, LearnersSinglelogin} from "../../../../helper/base_url";

const addActiveProject = async (projectTitle, videoUrl, setMessage) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('User not authenticated. Please log in.');
      return; // Stop execution if no token is found
    }

    // First API call for single login or any other functionality
    try {
      const response = await fetch(`${LearnersSinglelogin}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ videoUrl }),
      });

      if (response.ok) {
        const ssoUrl = await response.text();
        window.open(ssoUrl, '_blank'); // Opens the SSO URL in a new tab
      } else {
        setMessage('Login failed. Please try again.');
        return; // Stop further execution if login fails
      }
    } catch (error) {
      setMessage('An error occurred during login. Please try again later.');
      return; // Stop further execution if the login API call fails
    }

    // Second API call to add course
    try {
      const response = await axios.post(
        `${LearnersAddProjects}`,
        { projectTitle, videoUrl },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setMessage('Course successfully added.');
      } else {
        setMessage('Failed to add course. Please try again.');
      }
    } catch (error) {
      setMessage('Error while adding the course. Please try again later.');
    }

  } catch (error) {
    setMessage('Error: Unable to process the request.');
  }
};

export default addActiveProject;
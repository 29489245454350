import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField, Grid,Paper, Box,Typography, CircularProgress, MenuItem } from "@mui/material";
import { LearnersProfile, LearnersResume } from "../../../helper/base_url";
//import ResumeUpload from "./resume";

const Userprofile = () => {
  const [, setLoading] = useState(false);
  const [, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [file, setFile] = useState(null);
  const [data, setData] = useState({
    areasofinterest1: "",
    areasofinterest2: "",
    areasofinterest3: "",
    areasofinterest4: "",
    areasofinterest5: "",
    areasofinterest6: "",
    areasofinterest7: "",
    areasofinterest8: "",
    areasofinterest9: "",
    areasofinterest10: "",
    areasofinterest11: "",
    areasofinterest12: "",
    areasofinterest13: "",
    areasofinterest14: "",
    areasofinterest15: "",
    areasofinterest16: "",
    areasofinterest17: "",
    areasofinterest18: "",
    areasofinterest19: "",
    fileInput: "",
  });
  const [userId, setUserId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);
  const token = localStorage.getItem("token");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const response = await axios.put(`${LearnersProfile}${userId}`, updatedData, {
        headers: {
          "Content-Type": "application/json",
          authorization: `jwt ${localStorage.getItem('token')}`
        },
      });
      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 2000 });
        setShowToast(true);
        setData(response.data.data);
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
        setShowToast(true);
      }
    } catch (error) {
      toast.error("Network error: Please check your connection and try again.", { autoClose: 2000 });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const updatedData = { ...data };
    await handleUserProfile(updatedData);
  };

  useEffect(() => {
    if (token) {
      setUserId(userId);
      handleUserProfile();
    }
  }, [token, userId, handleUserProfile]);

  const renderDynamicInput = () => {
    switch (data.areasofinterest3) {
      case 'select':
        return <input type="text" name="dynamicInput" style={{marginLeft: "24px", width: "100%"}} placeholder='select' onChange={handleOnChange} />;
      case 'previous internships or Work Experience':
        return <input type="text" name="dynamicInput" style={{marginLeft: "24px", width: "100%"}} placeholder='Describe your previous internships or work experience' onChange={handleOnChange} />;
      case 'Freelancing Projects':
        return <input type="text" name="dynamicInput" style={{marginLeft: "24px", width: "100%"}} placeholder='Describe your freelancing projects' onChange={handleOnChange} />;
      case 'Academic Projects':
        return <input type="text" name="dynamicInput" style={{marginLeft: "24px", width: "100%"}} placeholder='Describe your academic projects' onChange={handleOnChange} />;
      default:
        return null;
    }
  };

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setErrorMessage('');
    // setSuccessMessage('');
  };

  const handleResumeSubmit = async (event) => {
    event.preventDefault();
    setResumeLoading(true);
  
    if (!file) {
      setErrorMessage('Please select a file.');
      return;
    }
  
    if (file && file.size > 50 * 1024) { // Check file size (50 KB limit).
      setErrorMessage("File size exceeds the 50 KB limit. Please upload a smaller file.");
      setResumeLoading(false);
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('You need to be logged in.');
      }
    const formData = new FormData();
    formData.append('fileInput', file);
    //console.log('FormData:', formData);

    setLoading(true);

      const response = await axios.post(
        `${LearnersResume}`,
        formData,
        {
          headers: {
            'Authorization': `jwt ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.data.status === "Success") {
        toast.success("Resume Upload Successful");
      } else {
        setErrorMessage("Resume Upload failed: " + response.data.message || "Please try again.");
      }
    } catch (error) {
      //console.error("Error uploading resume:", error);
      setErrorMessage("Failed to upload resume. Please try again later.");
    } finally {
      setResumeLoading(false);
    }
  };  

  return (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
  {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
  <Paper elevation={3} sx={{ maxWidth: '500px', padding: 2 }}>
    <Typography variant="h5" sx={{ marginBottom: 2, textAlign: 'center' }}>
      Areas of Interest
    </Typography>
    <form onSubmit={handleSubmit}>
      <Box className="form-group students-dashboard-profile-label">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Preferred Industries"
              name="areasofinterest1"
              value={data.areasofinterest1}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., IoT, Data Science, Embedded Systems)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Topics of Interest"
              name="areasofinterest2"
              value={data.areasofinterest2}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., AI, Web Development, Data Science)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              label="Experience"
              name="areasofinterest3"
              value={data.areasofinterest3}
              onChange={handleOnChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Previous Internships or Work Experience">
                Previous Internships or Work Experience
              </MenuItem>
              <MenuItem value="Freelancing Projects">Freelancing Projects</MenuItem>
              <MenuItem value="Academic Projects">Academic Projects</MenuItem>
            </TextField>
          </Grid>
          {renderDynamicInput()}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center', fontWeight: 'bold', color: '#043885' }}>
              Certifications and Courses
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Certifications"
              name="areasofinterest4"
              value={data.areasofinterest4}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., AWS, Microsoft, Cisco)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Portfolio"
              name="areasofinterest5"
              value={data.areasofinterest5}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., www.myportfolio.com)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center', fontWeight: 'bold', color: '#043885' }}>
              Project Descriptions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Title"
              name="areasofinterest6"
              value={data.areasofinterest6}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., Software Engineer, Data Analyst)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="areasofinterest7"
              value={data.areasofinterest7}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., Experienced in full-stack development and data analysis)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Technical Skills"
              name="areasofinterest8"
              value={data.areasofinterest8}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., JavaScript, Python, React)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Languages"
              name="areasofinterest9"
              value={data.areasofinterest9}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., English, Spanish)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Hobbies"
              name="areasofinterest10"
              value={data.areasofinterest10}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., Reading, Hiking)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Preferred Job Role"
              name="areasofinterest11"
              value={data.areasofinterest11}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., Software Developer)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Preferred Location"
              name="areasofinterest12"
              value={data.areasofinterest12}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., New York, San Francisco)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Professional Summary"
              name="areasofinterest13"
              value={data.areasofinterest13}
              onChange={handleOnChange}
              fullWidth
              multiline
              placeholder="Write a brief professional summary"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="References"
              name="areasofinterest14"
              value={data.areasofinterest14}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., John Doe, Jane Smith)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Additional Information"
              name="areasofinterest15"
              value={data.areasofinterest15}
              onChange={handleOnChange}
              fullWidth
              multiline
              placeholder="(e.g., Any additional details you want to provide)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Goals and Aspirations"
              name="areasofinterest16"
              value={data.areasofinterest16}
              onChange={handleOnChange}
              fullWidth
              multiline
              placeholder="(e.g., Long-term career goals)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Current Occupation"
              name="areasofinterest17"
              value={data.areasofinterest17}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., Student, Full-time Employee)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Availability"
              name="areasofinterest18"
              value={data.areasofinterest18}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., Immediately, 1 month)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="LinkedIn Profile"
              name="areasofinterest19"
              value={data.areasofinterest19}
              onChange={handleOnChange}
              fullWidth
              placeholder="(e.g., www.linkedin.com/in/username)"
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#043885",
                    "&:hover": {
                      backgroundColor: "#052659",
                    },
                    padding: "10px 20px",
                  }}
                >
                   Save Data
                </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
    <form id="label" onSubmit={handleResumeSubmit} encType="multipart/form-data">
      <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          variant="h5"
          sx={{
            marginBottom: 3,
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#052659',
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          Upload Your Resume
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: 400,
            marginBottom: 3,
          }}
        >
          <input
            type="file"
            onChange={handleFileChange}
            required
            style={{
              width: '100%',
              padding: '12px',
              borderRadius: '8px',
              border: '1px solid #ddd',
              marginBottom: '16px',
            }}
          />
          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={resumeLoading}
            fullWidth
            sx={{
              backgroundColor: "#043885",
              '&:hover': { backgroundColor: "#052659" }, // Button hover effect
              marginTop: 2,
              padding: '10px 20px',
              borderRadius: '8px',
              textTransform: 'none',
            }} >
            {resumeLoading ? (
              <CircularProgress size={24} sx={{ color: '#052659' }} />
            ) : (
              'Upload Resume'
            )}
          </Button>
        </Box>
      </Box>
    </form>
  </Paper>
</Box>
  );
};

export default Userprofile;

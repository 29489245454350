import SmartShoeImage from "../../../../assets/dashboard-images/images/images/projects/16.png";
import Amazon from "../../../../assets/dashboard-images/images/images/15.jpg";
import Microsoft from "../../../../assets/dashboard-images/images/images/7.png";
import Internet from "../../../../assets/dashboard-images/images/images/4.png";
import SmartHome from "../../../../assets/dashboard-images/images/images/3.png";
import ESP32Arduino from "../../../../assets/dashboard-images/images/images/2.png";
import NodeMCUMATLAB from "../../../../assets/dashboard-images/images/images/9.png";
import NternetESP32Arduino from "../../../../assets/dashboard-images/images/images/projects/26.png";
import NodeRed from "../../../../assets/dashboard-images/images/images/projects/21.png";
import BluetoothTechnology from "../../../../assets/dashboard-images/images/images/16.jpg";
import JavaScript from "../../../../assets/dashboard-images/images/images/10.png";
import SmartusingESP32 from "../../../../assets/images/course.png";
import IndustrialInternet from "../../../../assets/dashboard-images/images/images/17.jpg";
import IndustrialMATLAB from "../../../../assets/dashboard-images/images/images/6.png";

//iotdata
//import IotdataSmartShoeImage from "../../../../assets/dashboard-images/images/images/projects/16.png";
//import IotdataAmazon from "../../../../assets/dashboard-images/images/images/15.jpg";
//import IotdataMicrosoft  from "../../../../assets/dashboard-images/images/images/7.png";
//import IotdataInternet from "../../../../assets/dashboard-images/images/images/4.png";
//import IotdataSmartHome from "../../../../assets/dashboard-images/images/images/3.png";
//import IotdataESP32Arduino from "../../../../assets/dashboard-images/images/images/2.png";
//import IotdataNodeMCUMATLAB from "../../../../assets/dashboard-images/images/images/17.jpg";
//import IotdataNternetESP32Arduino from "../../../../assets/dashboard-images/images/images/9.png";
//import IotdataNodeRed from "../../../../assets/dashboard-images/images/images/projects/26.png";
//import IotdataBluetoothTechnology from "../../../../assets/dashboard-images/images/images/projects/21.png";
//import IotdataJavaScript from "../../../../assets/dashboard-images/images/images/16.jpg";
import IotdataSystemsProject from "../../../../assets/dashboard-images/images/images/6.png";
//import SmartusingESP32 from "../../../assets/dashboard-images/images/images/18.jpg";
import IotdataComputing from "../../../../assets/dashboard-images/images/images/projects/4.png";
//import PowerBI from "../../../assets/dashboard-images/images/images/projects/9.png";
//import Kafka from "../../../assets/dashboard-images/images/images/projects/8.png";
import IotdataKibana from "../../../../assets/dashboard-images/images/images/projects/19.png";
import IotdataAnalytics from "../../../../assets/dashboard-images/images/images/projects/18.png";
import IotdataMERN from "../../../../assets/dashboard-images/images/images/10.png";

//IndustrialIoTInsutry
//import SmartShoeImage from "../../../assets/dashboard-images/images/images/projects/16.png";
//import Amazon from "../../../assets/dashboard-images/images/images/15.jpg";
//import Microsoft  from "../../../assets/dashboard-images/images/images/7.png";
//import Internet from "../../../assets/dashboard-images/images/images/4.png";
//import SmartHome from "../../../assets/dashboard-images/images/images/3.png";
//import ESP32Arduino from "../../../assets/dashboard-images/images/images/2.png";
//import NodeMCUMATLAB from "../../../assets/dashboard-images/images/images/17.jpg";
//import NternetESP32Arduino from "../../../assets/dashboard-images/images/images/16.jpg";
//import NodeRed from "../../../assets/dashboard-images/images/images/18.jpg";
//import BluetoothTechnology from "../../../assets/dashboard-images/images/images/19.jpg";
//import JavaScript from "../../../assets/dashboard-images/images/images/13.png";
import SystemsProject from "../../../../assets/dashboard-images/images/images/projects/30.png";
//import SmartusingESP32 from "../../../assets/dashboard-images/images/images/projects/27.png";
import Industry from "../../../../assets/dashboard-images/images/images/projects/35.png";
//import IndustrialInternet from "../../../assets/dashboard-images/images/images/10.png";


const IoTdevelopercourses = [
  {
    courseId: 0.1,
    courseTitle: "Introduction to Internet of Things",
    videoUrl: "66e8028064721c6daa143ae8",
    image: SmartShoeImage,
  },
  {
    courseId: 0.2,
    courseTitle: "Python Programming",
    videoUrl: "660e8bc9699598329d883cf6",
    image: Amazon,
  },
  {
    courseId: 0.3,
    courseTitle: "IoT and Embedded Systems with ESP32",
    videoUrl: "660e7e601e0fac451502fb0c",
    image: Microsoft,
  },
  {
    courseId: 0.4,
    courseTitle: "IoT using Raspberry Pi and Python",
    videoUrl: "66044b9cdf77f21d16e2c40c",
    image: Internet,
  },
  {
    courseId: 0.5,
    courseTitle: "Azure IoT Certification Training",
    videoUrl: "660ea20144dad02ad4c6bfcb",
    image: SmartHome,
  },
  {
    courseId: 0.6,
    courseTitle: "AWS IoT Certification Training",
    videoUrl: "660ea25494fd573437e5c251",
    image: ESP32Arduino,
  },
  {
    courseId: 0.7,
    courseTitle: "Master ESP32, Bluetooth, and BLE",
    videoUrl: "660e80340229d83cda0e6e48",
    image: NodeMCUMATLAB,
  },
  {
    courseId: 0.8,
    courseTitle: "Master WIFI communication",
    videoUrl: "66acd995d6f9082bdc424251",
    image: NternetESP32Arduino,
  },
  {
    courseId: 0.9,
    courseTitle: "Long distance wireless networks: GSM, Lora, NBIOT",
    videoUrl: "66acdc5519d25e5892c64c42",
    image: NodeRed,
  },
  {
    courseId: 0.10,
    courseTitle: "Master MQTT",
    videoUrl: "660e8acf44dad02ad4c6b89c",
    image: BluetoothTechnology,
  },
  {
    courseId: 0.11,
    courseTitle: "Full stack IoT using JavaScript, MERN stack and Raspberry Pi",
    videoUrl: "660e7f660229d83cda0e6da7",
    image: JavaScript,
  },
  {
    courseId: 0.12,
    courseTitle: "Developing Web Applications for IoT Using Python and JavaScript",
    videoUrl: "66e807c6ece5194df3bc5213",
    image: SmartusingESP32,
  },
  {
    courseId: 0.13,
    courseTitle: "IoT Data analysis using python",
    videoUrl: "660eb969cf0168138b68bec0",
    image: IndustrialInternet,
  },
  {
    courseId: 0.14,
    courseTitle: "IoT Data analysis using MATLAB",
    videoUrl: "660e7e9334780c3dfae59a8f",
    image: IndustrialMATLAB,
  }
];

//iotdata
const IoTDataScientistcourses = [
  {
    courseId: 0.1,
    courseTitle: "Introduction to Internet of Things",
    videoUrl: "66e8028064721c6daa143ae8",
    image: SmartShoeImage,
    completion: 0
  },
  {
    courseId: 0.2,
    courseTitle: "Python Programming",
    videoUrl: "660e8bc9699598329d883cf6",
    image: Amazon,
    completion: 0
  },
  {
    courseId: 0.3,
    courseTitle: "IoT and Embedded Systems with ESP32",
    videoUrl: "660e7e601e0fac451502fb0c",
    image: Microsoft,
    completion: 0
  },
  {
    courseId: 0.4,
    courseTitle: "IoT using Raspberry Pi and Python",
    videoUrl: "66044b9cdf77f21d16e2c40c",
    image: Internet,
    completion: 0
  },
  {
    courseId: 0.5,
    courseTitle: "Azure IoT Certification Training",
    videoUrl: "660ea20144dad02ad4c6bfcb",
    image: SmartHome,
    completion: 0
  },
  {
    courseId: 0.6,
    courseTitle: "AWS IoT Certification Training",
    videoUrl: "660ea25494fd573437e5c251",
    image:  ESP32Arduino,
    completion: 0
  },
  {
    courseId: 0.14,
    courseTitle: "IoT Data analysis using python",
    videoUrl: "660eb969cf0168138b68bec0", 
    image: NodeMCUMATLAB,
    completion: 0
  },
  {
    courseId: 0.7,
    courseTitle: "Master ESP32, Bluetooth, and BLE",
    videoUrl: "660e80340229d83cda0e6e48",
    image: NternetESP32Arduino,
    completion: 0
  },
  {
    courseId: 0.8,
    courseTitle: "Master WIFI communication",
    videoUrl: "66acd995d6f9082bdc424251", 
    image: NodeRed,
    completion: 0
  },
  {
    courseId: 0.9,
    courseTitle: "Long distance wireless networks: GSM, Lora, NBIOT",
    videoUrl: "66acdc5519d25e5892c64c42", 
    image: BluetoothTechnology,
    completion: 0
  },
  {
    courseId: 0.10,
    courseTitle: "Master MQTT",
    videoUrl: "660e8acf44dad02ad4c6b89c", 
    image: JavaScript,
    completion: 0
  },
  {
    courseId: 0.15,
    courseTitle: "IoT Data analysis using MATLAB",
    videoUrl: "660e7e9334780c3dfae59a8f", 
    image: IotdataSystemsProject,
    completion: 0
  },
  // {
  //   courseId: 0.16,
  //   courseTitle: "Machine Learning",
  //   videoUrl: "https://learn.huebits.in/courses/Machine-Learning-using-Python-66752d77e501170a8d812ec9", 
  //   image: SmartusingESP32,
  //   completion: 0
  // },
  {
    courseId: 0.17,
    courseTitle: "Artificial Intelligence of things",
    videoUrl: "66e80efe3939d23d140bf34c",
    image: IotdataComputing,
    completion: 0
  },
  // {
  //   courseId: 0.18,
  //   courseTitle: "Embedded Machine Learning",
  //   videoUrl: "https://learn.huebits.in/courses/Embedded-Machine-Learning-66ace0f7003a2e656e1f67c2",
  //   image: PowerBI,
  //   completion: 0
  // },
  // {
  //   courseId: 0.19,
  //   courseTitle: "Edge Computing",
  //   videoUrl: "https://learn.huebits.in/courses/Edge-Computing--66e8134ff1f27f2d14776ac9",
  //   image: Kafka,
  //   completion: 0
  // },
  {
    courseId: 0.20,
    courseTitle: "IoT Data Visualization using PowerBI, Tableau, Grafana and Kibana",
    videoUrl: "66ace17c277599313406d4f1",
    image: IotdataKibana,
    completion: 0
  },
  {
    courseId: 0.21,
    courseTitle: "IoT Data Insights with Kafka: Real-time Analytics",
    videoUrl: "66ace1fcd6f9082bdc424591",
    image: IotdataAnalytics,
    completion: 0
  },
  {
    courseId: 0.11,
    courseTitle: "Full stack IoT using JavaScript, MERN stack and Raspberry Pi",
    videoUrl: "660e7f660229d83cda0e6da7",
    image: IotdataMERN,
    completion: 0
  }
];

const IndustrialiotCourses = [
  {
    courseId: 0.1,
    courseTitle: "Introduction to Internet of Things",
    videoUrl: "66e8028064721c6daa143ae8",
    image: SmartShoeImage,
    completion: 0
  },
  {
    courseId: 0.2,
    courseTitle: "Python Programming",
    videoUrl: "660e8bc9699598329d883cf6",
    image: Amazon,
    completion: 0
  },
  {
    courseId: 0.3,
    courseTitle: "IoT and Embedded Systems with ESP32",
    videoUrl: "660e7e601e0fac451502fb0c",
    image: Microsoft,
    completion: 0
  },
  {
    courseId: 0.4,
    courseTitle: "IoT using Raspberry Pi and Python",
    videoUrl: "66044b9cdf77f21d16e2c40c",
    image: Internet,
    completion: 0
  },
  {
    courseId: 0.5,
    courseTitle: "Azure IoT Certification Training",
    videoUrl: "660ea20144dad02ad4c6bfcb",
    image: SmartHome,
    completion: 0
  },
  {
    courseId: 0.6,
    courseTitle: "AWS IoT Certification Training",
    videoUrl: "660ea25494fd573437e5c251",
    image:  ESP32Arduino,
    completion: 0
  },
  {
    courseId: 0.14,
    courseTitle: "IoT Data analysis using python",
    videoUrl: "660eb969cf0168138b68bec0", 
    image: NodeMCUMATLAB,
    completion: 0
  },
  {
    courseId: 0.10,
    courseTitle: "Master MQTT",
    videoUrl: "660e8acf44dad02ad4c6b89c",
    image: NternetESP32Arduino,
    completion: 0
  },
  {
    courseId: 0.16,
    courseTitle: "Machine Learning",
    videoUrl: "66752d77e501170a8d812ec9", 
    image: NodeRed,
    completion: 0
  },
  {
    courseId: 0.22,
    courseTitle: "Node-Red Course",
    videoUrl: "660e805c06697760666c0abf", 
    image: BluetoothTechnology,
    completion: 0
  },
  {
    courseId: 0.23,
    courseTitle: "Industrial Internet of Things",
    videoUrl: "660e8686337f1467aec01fad", 
    image: JavaScript,
    completion: 0
  },
  {
    courseId: 0.11,
    courseTitle: "Full stack IoT using JavaScript, MERN stack and Raspberry Pi",
    videoUrl: "660e7f660229d83cda0e6da7",
    image: IndustrialInternet,
    completion: 0
  },
  {
    courseId: 0.25,
    courseTitle: "MING technologies",
    videoUrl: "66e8190e268fd80c0d02f1ec", 
    image: SmartusingESP32,
    completion: 0
  },
  {
    courseId: 0.26,
    courseTitle: "Smart manufacturing and Industry4.0",
    videoUrl: "66e819953939d23d140bf840",
    image: Industry,
    completion: 0
  },
  {
    courseId: 0.24,
    courseTitle: "PLC, SCADA Systems and IoT",
    videoUrl: "66e817a16e1bbb1191401a75", 
    image: SystemsProject,
    completion: 0
  },
];

export { IoTdevelopercourses, IoTDataScientistcourses, IndustrialiotCourses };

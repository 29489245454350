import React, { useState } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import behaves from "../../assets/sign up cmp.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Grid, Button,IconButton, FormControlLabel, Checkbox } from '@mui/material';
import { FaTwitter, FaInstagram,FaLinkedin, FaYoutube } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import {OrganizationsSignup} from "../../helper/base_url";

function Signup(){
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({ username: "", password: "", confirmPassword: "", userType: "company" });

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const regExspecialChars = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z])(?!.*\s).{8,}$/;

    if (data.password.length < 8) {
      toast.error("Password should be more than 8 characters");
      setLoading(false);
    } else if (!regExspecialChars.test(data.password)) {
      toast.error("Password should contain at least 1 digit, 1 special character, and 1 lower and upper case letter");
      setLoading(false);
    } else if (data.password !== data.confirmPassword) {
      toast.error("Confirm password does not match"); 
      setLoading(false);

    } else {
      try {
      const toastId = toast.loading("Submitting your registration details...");
        const response = await axios.post(`${OrganizationsSignup}`, data, {
          headers: { "Content-Type": "application/json" },
        });

        toast.update(toastId, { render: response.data.message, type: response.data.error ? "error" : "success", isLoading: false });
        if (!response.data.error) {
          setData({ username: "", password: "", confirmPassword: "" });
          setTimeout(() => navigate("/companiessignin"), 500);
        }
      } catch (error) {
        toast.error(error.response?.data.message || "An error occurred.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="students-flex">
      <Headrepage />
      <div className="signup-pages-flex">
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
      <div className="studentssign-cssimagesflex-color" id="studentssign-cssimagesflex-color">
      <Grid container spacing={3} alignItems="center" justifyContent="center" className="studentssignup-cssimagesflex">
      <Grid item xs={12} sm={6} md={4} className="students-signup-background-color">
          <p className="center-signup">Sign Up for a Company Account</p>
              <form id="signup-form" onSubmit={handleSubmit}>
              <TextField
                type="email"
                id="username"
                name="username"
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={data.username}
                onChange={handleOnChange}
                required
                disabled={loading}
              />
              <div style={{ position: "relative" }}>
              <TextField
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                label="Password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={data.password}
                onChange={handleOnChange}
                required
                disabled={loading}
              />
              <IconButton
              onClick={() => setShowPassword(prev => !prev)}
              className="toggle-password-icon"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)"
              }}
            >
              {showPassword ? <span>👁</span> : <span>🔒</span>}
            </IconButton>
            </div>
              <TextField
                type="password"
                id="confirm_password"
                name="confirmPassword"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={data.confirmPassword}
                onChange={handleOnChange}
                required
                disabled={loading}
              />
              <FormControlLabel
                control={<Checkbox required />}
                label="I agree with the User terms and privacy policy"
                className="signup-checkbox"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
                className="students-signup-btn"
              >
                {loading ? 'Sign up...' : 'Sign up'}
              </Button>
            </form>
            <div className="companies-already-account" >
                <p>
                  <span className="signup-already-account">Already have an account? </span>
                  <Link to="/companiessignin" className="signuphere">Login here</Link>
                </p>
            </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
           <img className="students-signupimage" src={behaves} alt="Login" />
           </Grid>
           </Grid>
          </div>
        <div className="homepage-footers-images">
            <Footer />
            <footer className="homepage-footers">
            <hr className='homepage-footer-hr'/>
              <div className="footer-social">
              <div className="homepage-footer-copyright">
                <p>© Huebits[2025].All Rights Reserved.</p>
              </div>
              <div className='footer-social-icons'>
                  <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                  <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                  <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                  <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
            </div>
            </div>
            </footer>
          </div>
          </div>
      </div>
  );
};

export default Signup;

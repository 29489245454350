import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Button, Grid, IconButton, InputAdornment, Paper, Box, Typography, Link as MuiLink } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {Adminlogin} from "../../helper/base_url"

function AdminLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: "",
    userType: "admin",
    adminSecretKey: ""
  });

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingNextPage(true);

    try {
      const response = await axios.post(`${Adminlogin}`, 
        { ...data }, 
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { token, message } = response.data;

      if (response.data.error) {
        toast.error(message);
      } else {
        toast.success(message);
        localStorage.setItem('token', token);
        localStorage.setItem('role', 'admin');
        setTimeout(() => {
          navigate("/admin-dashboard");
        }, 1000);
      }
    } catch (error) {
      console.error('Error during login:', error);
      if (error.response) {
        toast.error(error.response.data.message || "An unexpected error occurred");
      } else {
        toast.error("Network error: Please check your connection and try again.");
      }
    } finally {
      setLoading(false);
      setLoadingNextPage(false);
    }
  };

  return (
    <div className="admin-dashboard-users-data">
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
      {loadingNextPage && <div className="spinner"></div>}
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={6} md={4} sx={{ marginTop: 10 }}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
            <Typography variant="h4" align="center" sx={{ marginBottom: 2 }}>Admin Login</Typography>
            <form onSubmit={submitHandler}>
              <TextField
                label="Enter Email"
                type="email"
                name="username"
                value={data.username}
                onChange={handleOnChange}
                fullWidth
                required
                disabled={loading}
                variant="outlined"
                margin="normal"
              />

              <TextField
                label="Enter Password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={data.password}
                onChange={handleOnChange}
                fullWidth
                required
                disabled={loading}
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                label="Admin Secret Key"
                type="text"
                name="adminSecretKey"
                value={data.adminSecretKey}
                onChange={handleOnChange}
                fullWidth
                required
                disabled={loading}
                variant="outlined"
                margin="normal"
              />
              <div className="users-login-btn">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  className="students-signup-btn"
                >
                  {loading ? 'Login...' : 'Log In'}
                </Button>
              </div>
              <div style={{ marginTop: 15 }}>
                <p>
                  <Link to="/adminforgot-password" className="students-frogot-password">
                    Forgot Password
                  </Link>
                </p>
              </div>
            </form>
            <Box className="students-signup-accounts" sx={{ marginTop: 2 }}>
              <Typography variant="body2">
                <span className="signup-already-account">Don't have an account? </span>
                <MuiLink 
                  component={Link} 
                  to="/admindashboard" 
                  sx={{ textDecoration: 'none', fontWeight: 'bold', color: 'primary.main' }}
                >
                  Create an account
                </MuiLink>
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminLogin;
import React,{ useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Learning,Studentslearningpaths } from "./LearningPath";
import product from "../../../assets/Stud.png";
import Graduate from "../../../assets/Graduates.png";
import { Caursers,Studentsreviews } from "./LearnersReviews";
import Bestplan from "./Bestplanyear";
import Background from "./Background";
import Workese from "./workses";
import Studentscourses from "./achievements";
import { FaTwitter, FaInstagram,FaLinkedin, FaYoutube, FaArrowUp } from 'react-icons/fa';
import Headrepage from "../../../mainpage/Headerpages/learners_hederpage";
import Footer from "../../../mainpage/Footer/Footer";
import Reveal from '../../../helper/useScrollposition';
import "./Students.css";

const Students = ({ openModel }) => {
  const footerSectionRef = useRef(null);

  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  
    return (
    <div className={`students-flex companies-flex ${openModel ? 'modal-open-opacity' : '0.50'}`}>
        <Headrepage />
        <div className="homepagecolores">
        <div className="aboutus-1">
        <div className="homepagecolor">
          <Reveal>
            <div ref={footerSectionRef} className="getstarted-flex students-getstarted-flex-1">
            <ul>
                <li className="studentsjoin-span">
                <h1 className="studentsjoin">Empower Your Future With Real Industry Experience!</h1>
                <p className="spansexports1">Gain invaluable industry experience with hands-on training, specialized courses,
                   and real-world projects in cutting-edge technologies.</p></li>
                <div className="buttonget-link">
                <Link to="/login"><button className="buttonget-1">Get Started
                <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                </button></Link>
                </div>
                </ul>
                <div>
                <img className="students-Thirdman" src={product} alt=""/>
                </div>
            </div>
            </Reveal>
            <div className="learningpath-ourney">
              <div className="learningpath-ourney-1">
              <Reveal>
                <h1 className="learningpath-ourney-1h1">Elevate Your Learning Journey With Our Unique Offerings!</h1>
                <p className="learningpath-ourney-1p">Embark on an educational adventure like no other with our student-centric platform.</p>
                <p className="learningpath-ourney-1p"> Discover what sets us apart and explore the array of benefits waiting for you</p>
              </Reveal>
              <Reveal>
              <div className="learningpath-ourney1">
              <Learning />
              </div>
              </Reveal>
              </div>
            </div>
            <div className="companies-center-learning-paths">
            <Reveal>
                <div className="companies-center-learning-paths">
                <div className="learning-paths-icon-flex">
                <h1 className="learn-transformation-experience-paths-h1">Learning Paths</h1>
                <img className="learning-paths-icon__icon" src={Graduate} alt=""/>
                </div>
                    <p className="learn-transformation-experience">Learn latest digital transformation 
                    technologies from scratch, build exciting projects, earn industrial experience.</p>
                </div>
              </Reveal>
             <div>
              <Studentslearningpaths />
             </div>
              <div>
              <Reveal>
                <Workese />
              </Reveal>
              </div>
              <div>
              <div>
                <div className="Unemployability">
                <Reveal>
                  <h1 className="Unemployability-1">Skills gaps matter more than unemployment.</h1>
                  <p className="Unemployability-p">We tailor plans to match your career goals with industry needs.</p>
                  <Caursers />
                </Reveal>
                </div>
                <Reveal>
                <div className="achievements-image">
                <h1 className="achievements-h1">Our Achievements</h1>
                <div>
                <Studentscourses />
                </div>
              </div>
              </Reveal>
              <Reveal>
              <div className="experience-learners">
              <h1 className="experience-learners-h1">Testimonials</h1>
            <p className="experience-learners-p">Discover what our learners 
               have to say about their experience with us.</p>
                <Studentsreviews />
              </div>
              </Reveal>
              <Reveal>
              <div className="Choose-bestplanchoose">
              <h1 className="bestplanchoose-students">From Learning to Earning: Pick Your Ideal Plan and Gain Experience!</h1>
                <Bestplan />
              </div>
              </Reveal>
              <Reveal>
             <div className="backgroundimage-1">
             <Background />
             </div>
             </Reveal>
             <div className="homepage-footers-images">
            <Footer />
            <footer className="homepage-footers">
            <hr className='homepage-footer-hr'/>
              <div className="footer-social">
              <div className="homepage-footer-copyright">
                <p>© Huebits[2025].All Rights Reserved.</p>
              </div>
              <div className='footer-social-icons'>
                  <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                  <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                  <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                  <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
                  {/* <Link><FaFacebook className="social-icon" /></Link> */}
                <Link onClick={scrollToFooterSection}> 
                <FaArrowUp className="homepage-social-icon-link" />
                </Link>
            </div>
            </div>
            </footer>
          </div>
        </div>
        </div>
       </div>
      </div>
      </div>
    </div>
    </div>
    )
}

export default Students;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Box } from "@mui/material";

const OrganizationsHeader = () => {
  const [menuActive, setMenuActive] = useState(false);

  // Check if user is logged in by checking both token and email in localStorage
  const isLoggedIn = localStorage.getItem("company_token") !== null;

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  const menuItems = [
    { label: "Organizations", path: "/organizations" },
    { label: isLoggedIn ? "Dashboard" : "Log in", path: isLoggedIn ? "/compainesdashboard" : "/companiessignin" },
  ];

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "#ffff",
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 2, sm: 4, md: 6 },
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
        }}
      >
        {/* Brand Name */}
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "40px", md: "40px" },
            fontWeight: "700",
            color: "#052659",
            textDecoration: "none",
          }}
          component={Link}
          to="/"
        >
          Hue<span style={{ color: "#1c84e4" }}>bits</span>
        </Typography>

        {/* Responsive Menu Icon */}
        <IconButton
          edge="end"
          onClick={toggleMenu}
          sx={{
            color: "#052659",
            display: { xs: "block", md: "none" }, // Show only on small screens
          }}
        >
          <MenuOutlinedIcon fontSize="large" />
        </IconButton>

        {/* Desktop Menu */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 3,
          }}
        >
          {menuItems.map((item, index) => (
            <Typography
              key={index}
              component={Link}
              to={item.path}
              onClick={closeMenu}
              sx={{
                textDecoration: "none",
                color: "#052659",
                fontWeight: 700,
                fontSize: "20px",
                padding: "8px 16px",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#052659",
                  color: "#fff",
                },
              }}
            >
              {item.label}
            </Typography>
          ))}
        </Box>
      </Toolbar>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="left"
        open={menuActive}
        onClose={closeMenu}
        sx={{
          "& .MuiDrawer-paper": {
            bgcolor: "#fff",
            width: 250,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={closeMenu}>
            <CloseIcon sx={{ color: "#052659" }} />
          </IconButton>
        </Box>
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              key={index}
              button
              component={Link}
              to={item.path}
              onClick={closeMenu}
              sx={{
                "& .MuiTypography-root": {
                  color: "#052659",
                  fontWeight: 700,
                  fontSize: "16px",
                  padding: "4px 16px",
                  "&:hover": {
                    backgroundColor: "#052659",
                    color: "#fff",
                  },
                },
              }}
            >
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default OrganizationsHeader;
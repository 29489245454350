import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Button, TextField, Typography, Grid, Box, Card, CircularProgress } from "@mui/material";
import {LearnersProfile } from "../../../helper/base_url";
import "react-toastify/dist/ReactToastify.css";

const Userprofile = () => {
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [data, setData] = useState({username: "", fullname: "", mobile: "", dateofbirth: "", gender: "", age: "" });
  const token = localStorage.getItem("token");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const { data: response } = await axios.put(
        `${LearnersProfile}`,
        updatedData,
        {
          headers: {
            "content-type": "application/json",
            authorization: `jwt ${token}`,
          },
        }
      );
      response.success
        ? toast.success(response.message)
        : toast.error(response.message);
        setShowToast(true);
      setData(response.data || {});
    } catch {
      toast.error("Network error. Try again.");
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handleUserProfile(data);
  };

  useEffect(() => {
    if (token) handleUserProfile();
  }, [token, handleUserProfile]);

  return (
    <div className="mylearningpaths">
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f4f6f8",
        padding: "20px",
      }}
    >
      <Card
        sx={{
          maxWidth: 490,
          width: "100%",
          padding: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          backgroundColor: "#ffffff",
        }}
      >
        {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
        <Typography variant="h4" component="h2" align="center" sx={{ mb: 3 }}>
        Basic Information
      </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
            <Grid item xs={12}>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="fullname"
                  label="username"
                  value={data.fullname || data.username || '' }
                  onChange={handleOnChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="dateofbirth"
                  label="Date of Birth"
                  type="date"
                  value={data.dateofbirth}
                  onChange={handleOnChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                  <TextField
                      label="mobile"
                      name="mobile"
                      value={data.mobile}
                      onChange={handleOnChange}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                  />
              </Grid> 
              <Grid item xs={12}>   
                  <TextField
                      fullWidth
                      select
                      name="gender"
                      value={data.gender}
                      onChange={handleOnChange}
                      SelectProps={{
                          native: true,
                      }}
                  >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                  </TextField>
               </Grid> 
               <Grid item xs={12}>   
                  <TextField
                      fullWidth
                      label="Age"
                      name="age"
                      type="number"
                      value={data.age}
                      onChange={handleOnChange}
                  />
                </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#043885",
                    "&:hover": {
                      backgroundColor: "#052659",
                    },
                    padding: "10px 20px",
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Update Profile"}
                </Button>
              </Grid>
            </Grid>
          </form>
      </Card>
    </Box>
    </div>
  );
};

export default Userprofile;
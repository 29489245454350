import React from "react";
import SmartShoeImage from "../../../assets/dashboard-images/images/images/1.png";
import Amazon from "../../../assets/dashboard-images/images/images/2.png";
import Microsoft  from "../../../assets/dashboard-images/images/images/3.png";
import Internet from "../../../assets/dashboard-images/images/images/4.png";
import SmartHome from "../../../assets/dashboard-images/images/images/5.png";
import ESP32Arduino from "../../../assets/dashboard-images/images/images/6.png";
import NodeMCUMATLAB from "../../../assets/dashboard-images/images/images/7.png";
import NternetESP32Arduino from "../../../assets/dashboard-images/images/images/8.png";
import NodeRed from "../../../assets/dashboard-images/images/images/9.png";
import BluetoothTechnology from "../../../assets/dashboard-images/images/images/10.png";
import JavaScript from "../../../assets/dashboard-images/images/images/11.png";
import SystemsProject from "../../../assets/dashboard-images/images/images/12.png";
import SmartusingESP32 from "../../../assets/dashboard-images/images/images/13.png";
import IndustrialInternet from "../../../assets/dashboard-images/images/images/14.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';

import './start.css';

const courses = [
  {
    courseId: 1,
    courseTitle: "Introduction to Internet of Things",
    videoUrl: "",
    image: SmartShoeImage,
    Skills: "",
    completion: 0
  },
  {
    courseId: 2,
    courseTitle: "To Electronics and Circuit Design",
    videoUrl: "",
    image: Amazon,
    Skills: "",
    completion: 0
  },
  {
    courseId: 3,
    courseTitle: "Embedded Systems using ESP32 and ESP-IDF",
    videoUrl: "",
    image: Microsoft,
    Skills: "",
    completion: 0
  },
  {
    courseId: 4,
    courseTitle: "Embedded Systems and IoT using STM32",
    videoUrl: "",
    image: Internet,
    Skills: "",
    completion: 0
  },
  {
    courseId: 5,
    courseTitle: "Sensor Technology and Integration",
    videoUrl: "",
    image: SmartHome,
    Skills: "",
    completion: 0
  },
  {
    courseId: 6,
    courseTitle: "PCB Design and Manufacturing",
    videoUrl: "",
    image:  ESP32Arduino,
    completion: 0
  },
  {
    courseId: 7,
    courseTitle: "Python Programming",
    videoUrl: "", 
    image: NodeMCUMATLAB,
    completion: 0
  },
  {
    courseId: 8,
    courseTitle: "IoT and Embedded Systems with ESP32",
    videoUrl: "",
    image: NternetESP32Arduino,
    completion: 0
  },
  {
    courseId: 9,
    courseTitle: "IoT using Raspberry Pi and Python",
    videoUrl: "", 
    image: NodeRed,
    completion: 0
  },
  {
    courseId: 10,
    courseTitle: "Azure IoT Certification Training",
    videoUrl: "", 
    image: BluetoothTechnology,
    completion: 0
  },
  {
    courseId: 11,
    courseTitle: "AWS IoT Certification Training",
    videoUrl: "", 
    image: JavaScript,
    completion: 0
  },
  {
    courseId: 12,
    courseTitle: "IoT Data analysis using python",
    videoUrl: "", 
    image: SystemsProject,
    completion: 0
  },
  {
    courseId: 13,
    courseTitle: "Master ESP32, Bluetooth, and BLE",
    videoUrl: "", 
    image: SmartusingESP32,
    completion: 0
  },
  {
    courseId: 14,
    courseTitle: "Master WIFI communication",
    videoUrl: "",
    image: IndustrialInternet,
    completion: 0
  },
  {
    courseId: 15,
    courseTitle: "Long distance wireless networks: GSM, Lora, NBIOT",
    videoUrl: "",
    image: IndustrialInternet,
    completion: 0
  },
  {
    courseId: 16,
    courseTitle: "Master MQTT",
    videoUrl: "",
    image: IndustrialInternet,
    completion: 0
  },
  {
    courseId: 17,
    courseTitle: "Generative AI for hardware Engineers: Flux.ai",
    videoUrl: "",
    image: IndustrialInternet,
    completion: 0
  }
];

const Start = () => {


  return (
    <div>
    <div className="mylearningpaths">
      <h1 className="mylearningpaths-h1">IoT Hardware Engineer</h1>
      <p className="mylearningpaths-p">Courses</p>
      <div className="mylearningpath">
        <div className="Wrapper-sc-fvyqwl-1 iZEyIQ AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
          <ul className="Circle-sc-fvyqwl-1">
            {courses.map((course, index) => (
              <li key={course.courseId}>
                <div className="learningpath-courses-dashboard">
                  <div>
                  <img src={course.image} alt={course.courseTitle} className="smart-shoe-development" />
                  </div>
                  <div>
                  <p className={`pythoncourses${course.courseId}`} style={{paddingTop: "10px",}}>
                  {index + 1}. {course.courseTitle}
                  </p>
                  <p className="learningpath-courses-dashboard-skills">Skills: <span className="learningpath-courses-dashboard-skills-span">{course.Skills}</span></p>
                  <div className="learningpath-courses-dashboard-progressbar">
                  <p className="learningpath-courses-dashboard-skills-completed">{course.completion}%</p>
                  <ProgressBar now={course.completion} className={`custom-progress-bar`} />
                  </div>
                  <div>
                     <div>
                    {course.completion === 100 ? (
                      <button className="learningpath-courses-dashboard-button-start">Download Certificate</button>
                    ) : course.completion >= 10 ? (
                      <button  className="learningpath-courses-dashboard-button-start">Continue Learning</button>
                    ) : (
                      <button className="learningpath-courses-dashboard-button-start">Start Learning</button>
                    )}
                  </div>
                  </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <p className="mylearningpaths-p">Recommended Projects</p>
    </div>
  </div>
);
};

export default Start;
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import Homepage from './mainpage/Homepage/homepages';
import Termsofuse from "./mainpage/Footer/termsofUse";
import PrivacyPolicy from "./mainpage/Footer/privacyPolicy";
//Adminpages
import AdminSignup from './AdminDashboard/Adminsignup/Adminsignup';
import AdminLogin from './AdminDashboard/Adminlogin/Adminlogin';
import AdminForgotpassword from "./AdminDashboard/AdminForgotpassword/Forgot_Password";
import AdminDashboard from './AdminDashboard/AdminDashboard';
//Comanies
import Companies from './organizations/Organizations/organizations';
import InternetofThing from './organizations/LearningOrganizations/InternetofThings';
import DataScience from './organizations/LearningOrganizations/DataScience';
import EmbeddedSystem from './organizations/LearningOrganizations/EmbeddedSystems';
import Brilliant from './organizations/SignupOrganizations/signup';
import Transformation from './organizations/LoginOrganizations/Login';
import Forgotpasswordcompanies from './organizations/ForgotPasswordOrganizations/ForgotPassword';
import Organizationspopup from './organizations/PopupOrganizations/organizationspopup';
import Postproject1 from './organizations/OrganizationsProjects/post_project';
import Postproject2 from './organizations/OrganizationsProjects/select_industry';
import Postproject3 from './organizations/OrganizationsProjects/skills_required';
import Postproject4 from './organizations/OrganizationsProjects/select_languages';
import Postproject5 from './organizations/OrganizationsProjects/completion_time';
import Postproject6 from './organizations/OrganizationsProjects/submit_project';
import PostprojectsUploads from './organizations/OrganizationsProjects/postprojectsuploads';
import Compainesdashboard from './organizations/OrganizationsProjects/organizations_dashboard';
//Learners_pages
import Students from './Learners/Learnerspages/Learners/Learners';
import InternetofThings from './Learners/Learnerspages/LearningPathPages/InternetOfThings';
import DataSciences from './Learners/Learnerspages/LearningPathPages/DataScience';
import EmbeddedSystems from './Learners/Learnerspages/LearningPathPages/EmbeddedSystems';
import Signup from './Learners/Learnersdashboard/Signup/signup';
import Sigin from './Learners/Learnersdashboard/Login/login';
import ForgotPassword from './Learners/Learnersdashboard/ForgotPassword/forgotPassword';
//Learners_dashboard
import Dashboardhomepage from './Learners/Learnersdashboard/Learners_Sidebar/dashboardhomepage';
import Topbar from './Learners/Learnersdashboard/Learners_Sidebar/topbar';
import Userprofile from './Learners/Learnersdashboard/Learners_data/userprofile';
import Dashboard from './Learners/Learnersdashboard/dashboard';
import SidebarComponent from './Learners/Learnersdashboard/Learners_Sidebar/Sidebar';
import Learningpath from './Learners/Learnersdashboard/learningpath';
import Iotdeveloper from "./Learners/Learnersdashboard/learningpath/Iot-developer";
import Industrialiot from "./Learners/Learnersdashboard/learningpath/Industrial-iot-and-insutry";
import Iotsecurity from "./Learners/Learnersdashboard/learningpath/Iot-security-specialist";
import Iotdatascientist from "./Learners/Learnersdashboard/learningpath/Iot-data-scientist";
import Iothardware from "./Learners/Learnersdashboard/learningpath/Iot-hardware-engineer";
import Started from './Learners/Learnersdashboard/learningpath/Customizedpath';
import Courses from './Learners/Learnersdashboard/courses/courses';
import Projects from './Learners/Learnersdashboard/projects/projects';
import Capstoneaimagic from './Learners/Learnersdashboard/capstoneaimagic/capstoneaimagic';
import LiveSessions from './Learners/Learnersdashboard/Live-Sessions/Live-Sessions';
import Work from './Learners/Learnersdashboard/Learners_data/Work';
import * as amplitude from '@amplitude/analytics-browser';
import ProtectedRoutes from './helper/organizationstoken';
import ProtectedRoute from './helper/learnersToken';
// Custom Amplitude config
// const config = {};

// export const initAmplitude = () => {
//   amplitude.init(AMPLITUDE_PROJECT_ID, undefined, config);
  amplitude.init('7d242daccb34990cf675f19b2bacaf9a', {"autocapture":true});





function App() {
    
    return (
        
        <>
            <CssBaseline />
            <div className="app">
                <Routes>
                    {/* Homepages routes */}
                    <Route path="/" element={<Homepage />} />
                    <Route path="/terms-and-conditions" element={<Termsofuse />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    {/* Admindashboard */}
                    <Route path="/admindashboard" element={<AdminSignup />} />
                    <Route path="/adminlogin" element={<AdminLogin />} />
                    <Route path="/adminforgot-password" element={<AdminForgotpassword/>} />
                    <Route path="/admin-dashboard" element={<AdminDashboard />} />
                    {/* Companies routes */}
                    <Route path="/organizations" element={<Companies />} />
                    <Route path="/internet-of-things" element={<InternetofThing/>} />
                    <Route path="/data-science" element={<DataScience/>} />
                    <Route path="/embedded-systems" element={<EmbeddedSystem/>} />
                    <Route path="/companiessignup" element={<Brilliant />} />
                    <Route path="/companiessignin" element={<Transformation />} />
                    <Route path="/companiesforgotpassword" element={<Forgotpasswordcompanies />} />
                    <Route element={<ProtectedRoutes />}>
                    <Route path="/organization" element={<Organizationspopup />} />
                    <Route path="/postproject" element={<Postproject1 />} />
                    <Route path="/selectindustry" element={<Postproject2 />} />
                    <Route path="/skillsrequired" element={<Postproject3 />} />
                    <Route path="/selectlanguages" element={<Postproject4 />} />
                    <Route path="/completiontime" element={<Postproject5 />} />
                    <Route path="/submitproject" element={<Postproject6 />} />
                    <Route path="/projects" element={<PostprojectsUploads />} />
                    <Route path="/compainesdashboard" element={<Compainesdashboard />} />
                    </Route>
                    {/* Learners Pages routes*/}
                    <Route path="/learners" element={<Students />} />
                    <Route path="/internetofthings" element={<InternetofThings />} />
                    <Route path="/embeddedsystems" element={<EmbeddedSystems />} />
                    <Route path="/datascience" element={<DataSciences />} />
                    {/* Students Authentication routes */}
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Sigin />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                     {/* Students  Dashboard routes */}
                     <Route path="/dashboardpage" element={<Dashboardhomepage />} />
                     <Route path="/dashboard/*" element={<DashboardLayout />} />
                </Routes>
            </div>
        </>
    );
}

function DashboardLayout() {
    return (
        <>
            <SidebarComponent />
            <main className="content">
                <Topbar />
                <Routes>
                    <Route element={<ProtectedRoute />}>
                    <Route index element={<Dashboard />} />
                    <Route path="internetofthings" element={<Learningpath />} />
                    <Route path="internetofthings/iot-developer-engineer" element={<Iotdeveloper />} />
                    <Route path="internetofthings/iot-data-scientist" element={<Iotdatascientist />} />
                    <Route path="internetofthings/industrial-iot-and-insutry" element={<Industrialiot />} />
                    <Route path="internetofthings/iot-security-specialist" element={<Iotsecurity />} />
                    <Route path="internetofthings/iot-hardware-engineer" element={<Iothardware />} />
                    <Route path="internetofthings/started" element={<Started />} />
                    <Route path="courses" element={<Courses />} />
                    <Route path="projects" element={<Projects />} />
                    <Route path="capstoneaimagic" element={<Capstoneaimagic />} />
                    <Route path="livesessions" element={<LiveSessions />} />
                    <Route path="userprofile" element={<Userprofile />} />
                    <Route path="work" element={<Work />} />
                    </Route>
                </Routes>
            </main>
        </>
    );
}

export default App;

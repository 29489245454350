import React from "react";
import GaugeChart from "react-gauge-chart";
import "./hoescore.css";

const VerticalProgressBar = ({ HOE }) => {
  const maxScore = 100; // Define the maximum score

  // Validate the HOE value; default to 0 if it's invalid or not provided
  const validHOE = !isNaN(HOE) && isFinite(HOE) ? HOE : 0;

  // Calculate the percentage for the gauge chart
  const percentage = Math.max(0, Math.min(1, validHOE / maxScore)); // Clamp value between 0 and 1

  return (
    <div className="hoescoreprogreses-container">
      <div className="hoescoreprogresscontainer">
        <h1 className="hoescoreprogressheading">Hoe Score</h1>
        <div className="hoescoreprogress">
          <div className="gauge-chart-container">
            <GaugeChart
              id="gauge-chart5"
              percent={percentage}
              nrOfLevels={420}
              arcsLength={[0.1, 0.1, 0.1, 0.1]}
              arcPadding={0.01}
              cornerRadius={4}
              arcWidth={0.3}
              animate={false}
              colors={["#fb7403", "#fbb003", "#bffb03", "#0BDA51"]}
              formatTextValue={() => `${validHOE}/${maxScore}`} // Display valid HOE value
              needleColor="gray"
              needleBaseColor="darkgray"
              hideText={true}
            />
            <div className="needle-base">
              <span>{validHOE}</span>
              <span>/</span>
              <span>{maxScore}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalProgressBar;
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField, Grid, Paper, Box, Typography, CircularProgress} from "@mui/material";
import {LearnersProfile} from "../../../helper/base_url";

const Userprofile = () => {
  const [loading,setLoading] = useState(false);
  const [data, setData] = useState({
    programmingskills: "",
    toolstechnologies: "",
  });
  const [userId, setUserId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const token = localStorage.getItem("token");

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const response = await axios.put(`${LearnersProfile}${userId}`, updatedData, {
        headers: {
          "content-type": "application/json",
          authorization: `jwt ${localStorage.getItem('token')}`
        },
      });

      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 2000 });
        setShowToast(true);
        setData(response.data.data);
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
        setShowToast(true);
      }
    } catch (error) {
      toast.error("Network error: Please check your connection and try again.", { autoClose: 2000 });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updatedData = { ...data };
    await handleUserProfile(updatedData);
  };

  useEffect(() => {
    if (token) {
      setUserId(userId);
      handleUserProfile();
    }
  }, [token, userId, handleUserProfile]);

  return (
     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
      {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
      <Paper elevation={3} sx={{maxWidth: '700px', padding: 2 }}>
      <Typography variant="h5" sx={{ marginBottom: 2, textAlign: 'center' }}>Technical Skills</Typography>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item xs={12} >
              <TextField
                fullWidth
                label="Programming Languages"
                name="programmingskills"
                value={data.programmingskills}
                onChange={handleOnChange}
                required
                placeholder='e.g., IoT, JavaScript, Python, Data Science'
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tools and Technologies"
                name="toolstechnologies"
                value={data.toolstechnologies}
                onChange={handleOnChange}
                required
                placeholder='e.g., IoT, Embedded Systems, Data Science'
                sx={{ marginBottom: 2 }}
              />
            <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#043885",
                    "&:hover": {
                      backgroundColor: "#052659",
                    },
                    padding: "10px 20px",
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Save Data"}
                </Button>
          </Grid>
          </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default Userprofile;
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SmartCity from "../../../assets/New folder/images/7.png";
import LightingSystem from "../../../assets/New folder/images/8.png";
import Reveal from '../../../helper/useScrollposition';
import "./Studentscaursers.css";

const Caursers = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: <button className="slick-prev"><i className="fas fa-chevron-left"></i></button>,
        nextArrow: <button className="slick-next"><i className="fas fa-chevron-right"></i></button>,
        responsive: [
            {
                breakpoint: 1024, 
                settings: {
                    slidesToShow: 1, 
                }
            },
            {
                breakpoint: 480, 
                settings: {
                    slidesToShow: 1, 
                }
            }
        ]
    };

    return (
        <div className="students-Mentorship-slider-container">
        <Slider {...settings}>
            <div className="students-Mentorship-slide-item">
            <img className="studentsMentorship-image" src={SmartCity} alt="Smart City"/>
              <ul>
                    <li><h1 className="students-Mentorship-slide-item-h1">Mentorship and Support</h1></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      1. Expert mentorship for job seekers and learning challenges
                    </p></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      2. Personalized guidance to overcome barriers
                    </p></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      3. Access to valuable industry professional networks
                    </p></li>
              </ul>
            </div>
            <div className="students-Mentorship-slide-item">
            <img className="studentsMentorship-image" src={LightingSystem} alt="Smart City"/>
              <ul>
                    <li><h1 className="students-Mentorship-slide-item-h1">Tailored Learning Solutions for Skill Advancement</h1></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      1. Tailored learning paths for all skill levels
                    </p></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      2. 100% practical, solution-oriented training
                    </p></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      3. Strategically close skill gaps with precision
                    </p></li>
                </ul>
            </div>
        </Slider>
        </div>
    );
};


// Custom slide component
const SlideItem = ({ name, code, feedback }) => (
    <div className="homepage-slide-items-color">
        <div className="homepage-slide-item">
            <p className="homepage-slide-item-p">{code}</p>
            <div className="homepage-card-body">
                <h1 className="homepage-card-title-paths">{name}</h1>
                <p className="homepage-card-title-paths-p">{feedback}</p>
            </div>
        </div>
    </div>
);

const Studentsreviews = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        // Remove custom arrows for now to test the default behavior
        //prevArrow: null,  // Removing custom arrow
        //nextArrow: null,  // Removing custom arrow
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <Reveal>
        <div>
          <div className="homepage-reviewsflexs">
        <Slider {...settings}>
            <SlideItem
                name="Kishore Rao"
                code="KR"
                feedback="Service is really good and I like his explanation. I have taken IoT course from here and they provide kit also."
            />
            <SlideItem
                name="Sai Bhaskar"
                code="SB"
                feedback="Thanks for educating us for IoT, Especially BAPUJI Sir thanks for teaching us. Learned well and good."
            />
            <SlideItem
                name="Ravikiran Munganda"
                code="RM"
                feedback="It was amazing, experienced and the best faculty at Huebits platform, it was my dream to learn projects."
            />
            <SlideItem
                name="SaSi Kanth"
                code="SK"
                feedback="It's a good organisation. Learned a lot of knowledge in just 15 days of internship."
            />
            <SlideItem
                name="Suryarao Nagiredla"
                code="SN"
                feedback="Best place to learn machine learning course. Organizers are very friendly and will provide good amount of content."
            />
            <SlideItem
                name="Divya Gedela"
                code="DG"
                feedback="It's fortune to have internship at Huebits platform. They explain the concept clearly and care about you like family."
            />
        </Slider>
      </div>
  </div>
  </Reveal>
)
}

export {Caursers,Studentsreviews};
import React from 'react';
import "./postprojects.css"

export const Completed = () => {
  return (
    <div>
        <h1>Completed</h1>
    </div>
  )
}

export default Completed;

import React, { useState } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./data.css";

function App() {
    const [interestedCourse, setInterestedCourse] = useState('');
    const [recommendations, setRecommendations] = useState({});
    const courseLinks = {
        Beginner: {
            "keyword1": "https://huebits.graphy.com/courses/Industrial-Internet-of-Things-660e8686337f1467aec01fad",
            "keyword2": "https://www.youtube.com/beginner-course-2",
        },
        Medium: {
            "keyword1": "https://example.com/medium-course-1",
        },
        Advanced: {
            "keyword1": "https://example.com/advanced-course-1",
        },
    };
    const projectLinks = {
        Beginner: {
            "keyword1": "https://www.example.com/beginner-projects/project-1",
        },
        Medium: {
            "keyword1": "https://www.example.com/medium-projects/project-1",
        },
        Advanced: {
            "keyword1": "https://www.example.com/advanced-projects/project-1",
        },
};

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost:5000/api/data', { interested_course: interestedCourse });

            setRecommendations(response.data);

            toast.success('Recommendations data successfully!');
        } catch (error) {
            //console.error('Error fetching recommendations:', error);

            if (error.response) {
                toast.error(`Server error: ${error.response.status} - ${error.response.data.message || "An unexpected error occurred."}`);
            } else if (error.request) {
                toast.error("Network error: Please check your connection and try again.");
            } else {
                toast.error(`Request error: ${error.message}`);
            }
        }
    };

    return (
        <div>
        <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
        <div className='students-interested-course'>
            <form action="/learningpath/datacourses" method="POST" onSubmit={handleSubmit} className='interested-course-form'>
                <label htmlFor="interested_course" className='interested-course-label'> You are Interested in __________? </label><br />
                <input type="text" id="interested_course" name="interested_course" className='interested-course-1'
                value={interestedCourse} onChange={(e) => setInterestedCourse(e.target.value)} /><br /> <br />
                <input type="submit" value="Submit" className='interested-course-submit' />
            </form>
            <div className='recommendations-container'>
            {Object.keys(recommendations).map((index) => (
            <div key={index}>
            <h2 className='recommendations-index'> Recommendations for {recommendations[index].level} </h2>
            <div className='recommendations-index-flex'>
            <div class="Wrapper-sc-fvyqwl-1 iZEyIQ1 AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
           <ul className="Circle-sc-fvyqwls-1">
             <li>
               <div class="Circle-sc-fvyqwl-0 jNeOpF1">
                </div>
                 </li>
               <li>
                  <div class="Circle-sc-fvyqwl-0 lfeMSB1 lfem1">
                     </div>
                    </li>
                     <li>
                   <div class="Circle-sc-fvyqwl-0 lfeMSB1 lfem1">
                    </div>
                    </li>
                     <li>
                       <div class="Circle-sc-fvyqwl-0 lfeMSB1 lfems1">
                         </div>
                         </li>
                         <li>
                    <div class="Circle-sc-fvyqwl-0 lfeMSB1 lfembs1">
                      </div>
                   </li>
             </ul>
           </div> 
           <ul className='recommendations-ul'>
           {recommendations[index].courses && recommendations[index].courses.map((item, itemIndex) => (
           <li key={itemIndex} className='recommendations-li'>
            <Link to={courseLinks[recommendations[index].level]["keyword" + (itemIndex + 1)]} target="_blank" rel="noopener noreferrer">
                {itemIndex + 1}.{item}
            </Link>
        </li>
    ))}
      </ul>
      <ul className="recommendations-ul">
       {recommendations[index].projects && recommendations[index].projects.map((item, itemIndex) => (
        <li key={itemIndex} className='recommendations-li'>
            <Link to={projectLinks[recommendations[index].level]["keyword" + (itemIndex + 1)]} target="_blank" rel="noopener noreferrer">
                {itemIndex + 1}.{item}
            </Link>
        </li>
    ))}
    </ul>
    </div>
    </div>
))
}
    </div>
    </div>
    </div>
    );
}

export default App;
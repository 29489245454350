import React, {useState, useRef} from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaArrowUp,FaLinkedin, FaYoutube  } from 'react-icons/fa';
import Headerpage from "../Headerpages/HeaderPage";
import Footer from "./Footer";
import "./termsofuse.css";

const TermsOfUse = () => {
  const [activeSection, setActiveSection] = useState("learners");
  const footerSectionRef = useRef(null);
  const AccountsSectionRef = useRef(null);
  const CourseSectionRef = useRef(null);
  const PaymentsSectionRef = useRef(null);
  const ContentSectionRef = useRef(null);
  const IntellectualSectionRef = useRef(null);
  const TerminationSectionRef = useRef(null);
  const DisclaimersSectionRef = useRef(null);
  const IndemnificationSectionRef = useRef(null);
  const GoverningSectionRef = useRef(null);
  const ChildrensSectionRef = useRef(null);
  const ContactSectionRef = useRef(null);
  const UpdatesSectionRef = useRef(null);
    
    const scrollToFooterSection = () => {
      footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToAccounts = () => {
      AccountsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToCourse = () => {
      CourseSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToPayments = () => {
      PaymentsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToContent = () => {
      ContentSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToIntellectual = () => {
      IntellectualSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToTermination = () => {
      TerminationSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToDisclaimers = () => {
      DisclaimersSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToIndemnification = () => {
      IndemnificationSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToGoverning = () => {
      GoverningSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToChildrens = () => {
        ChildrensSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToContact = () => {
      ContactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToUpdates = () => {
        UpdatesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSectionToggle = (section) => {
    setActiveSection(section);
  };

  //Organizations
   const AccountsSectionsRef = useRef(null);
    const CourseSectionsRef = useRef(null);
    const PaymentsSectionsRef = useRef(null);
    const ContentSectionsRef = useRef(null);
    const IntellectualSectionsRef = useRef(null);
    const TerminationSectionsRef = useRef(null);
    const DisclaimersSectionsRef = useRef(null);
    const ContactSectionsRef = useRef(null);

    const scrollToAccount = () => {
      AccountsSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToCourses = () => {
      CourseSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToPayment = () => {
      PaymentsSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToContents = () => {
      ContentSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToIntellectuals = () => {
      IntellectualSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToTerminations = () => {
      TerminationSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToDisclaimer = () => {
      DisclaimersSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToContacts = () => {
      ContactSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <div className= "students-flex">
      <Headerpage />
     <div className="homepagecolores">
     <div className="footerpage-top-buttons">
        <button
          className={`footertoggle-button ${activeSection === "learners" ? "active" : ""}`}
          onClick={() => handleSectionToggle("learners")}
        >
          Learners
        </button>
        <button
          className={`footertoggle-button ${activeSection === "organizations" ? "active" : ""}`}
          onClick={() => handleSectionToggle("organizations")}
        >
          Organizations
        </button>
      </div>
    <div ref={footerSectionRef} >
      {activeSection === "learners" && (
    <div className="terms-container">
      <h1>Privacy Policy for Learners</h1>
      <p className="last-updated">This Privacy Policy was last updated on January 18, 2025.</p>
      <p>
      Huebits Tech Private Limited <strong>(“Company,” “we,” “us,” or “our”)</strong> is committed to safeguarding
      your privacy and ensuring the protection of your personal information. 
      This Privacy Policy describes how we collect, use, disclose, and 
      secure your information when you access or use our website, mobile applications, 
      and related services <strong>(collectively, the “Platform”)</strong>. By using the Platform, you consent to the practices outlined in this Privacy Policy. 
      </p>
      <div className="terms-containers-div">
      <h2>Table of Contents</h2>
      <ul>
        <li onClick={scrollToAccounts}>1. Scope of This Privacy Policy</li>
        <li onClick={scrollToCourse}>2. Information We Collect</li>
        <li onClick={scrollToPayments}>3. Legal Basis for Processing Information</li>
        <li onClick={scrollToContent}>4. How We Use Your Information</li>
        <li onClick={scrollToIntellectual}>5. Sharing Your Information</li>
        <li onClick={scrollToTermination}>6. Data Security</li>
        <li onClick={scrollToDisclaimers}>7. Your Privacy Rights</li>
        <li onClick={scrollToIndemnification}>8. Data Retention</li>
        <li onClick={scrollToGoverning}>9. International Data Transfers</li>
        <li onClick={scrollToChildrens}>10. Childrens Privacy</li>
        <li onClick={scrollToUpdates}>11. Updates to This Privacy Policy</li>
        <li onClick={scrollToContact}>12. Contact Us</li>
      </ul>
      </div>
      <h2 ref={AccountsSectionRef}>1. Scope of This Privacy Policy</h2>
      <p>
      This Privacy Policy applies to all users of the Platform, including learners, 
      instructors, and other participants. It governs how we collect, process, 
      and safeguard information provided by you directly, automatically, or through third parties.       
      </p>

      <h2 ref={CourseSectionRef}>2. Information We Collect</h2>
      <p>
      We collect information to provide and improve our services, 
      ensure compliance, and personalize user experiences. The types of information we collect include:      
      </p>
      <h4><strong>2.1 Information You Provide to Us</strong></h4>
      <p>
        <strong>Account Information:</strong> Includes your name, email address, phone number, password, and profile details.
      </p>
      <p>
        <strong>Payment Information:</strong> Includes billing address, payment method details, and transaction records.
      </p>
      <p>
        <strong>Course Data:</strong> Includes assignments, feedback, messages, and other user-generated content shared on the Platform.
      </p>
      <p>
        <strong>Customer Support Interactions:</strong> Includes details of your communications with our support team.
      </p>

      <h4><strong>2.2 Information Collected Automatically</strong></h4>
      <p>
        <strong>Usage Data:</strong> Includes information about your activities on the Platform, such as courses accessed, features used, and session duration. 
      </p>
      <p>
        <strong>Device Data:</strong> Includes details about the device you use, such as IP address, browser type, operating system, device identifiers, and network information. 
      </p>
      <p>
        <strong>Cookies and Similar Technologies:</strong> We use cookies, web beacons, and other technologies to collect data about your preferences and interactions with the Platform. 
      </p>

      <h4><strong>2.3 Information from Third Parties</strong></h4>
      <p>
        <strong>Social Media and Authentication Services:</strong> If you sign up or log in using social media or third-party authentication providers, we may collect your profile information and any permissions you grant.  
      </p>
      <p>
        <strong>Third-Party Partners:</strong> Includes information provided by marketing or analytics partners and publicly available sources.  
      </p>

      <h2 ref={PaymentsSectionRef}>3. Legal Basis for Processing Information</h2>
      <p>We process your information based on:</p>
      <p>
        <strong>Consent:</strong> When you provide explicit consent, such as subscribing to communications or accepting cookies. 
      </p>
      <p>
        <strong>Contractual Necessity:</strong> To fulfill our obligations under the terms of service, such as providing access to courses and processing transactions. 
      </p>
      <p>
        <strong>Legitimate Interests:</strong> To improve our services, ensure security, and conduct business analytics. 
      </p>
      <p>
        <strong>Legal Obligations:</strong> To comply with applicable laws, regulations, and legal processes. 
      </p>

      <h2 ref={ContentSectionRef}>4. How We Use Your Information</h2>
      <p>We use your information to: </p>
      <ul className="terms-containers-ul">
        <li>Provide, manage, and enhance the Platform, including delivering courses and personalized content.</li>
        <li>Process payments and manage billing and refunds.</li>
        <li>Communicate with you regarding updates, promotions, and customer support.</li>
        <li>Analyze usage patterns to improve features, user experience, and content offerings.</li>
        <li>Ensure the security and integrity of the Platform by detecting and preventing fraud or unauthorized access.</li>
        <li>Comply with legal requirements and enforce our Terms of Use.</li>
      </ul>

      <h2 ref={IntellectualSectionRef}>5.  Sharing Your Information</h2>
      <p>We may share your information in the following scenarios:</p>
      <ul className="terms-containers-ul">
        <li><strong>Service Providers:</strong> With vendors that help us operate the Platform, such as payment processors, hosting providers, and customer support services.</li>
        <li><strong>Business Partners:</strong> For collaborative projects, co-branded services, or joint marketing efforts.</li>
        <li><strong>Legal Compliance:</strong> To comply with laws, regulations, or legal requests, or to protect our rights and safety.</li>
        <li><strong>Corporate Transactions:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.</li>
      </ul>
      <p>We do not sell your personal information to third parties.</p>

      <h2 ref={TerminationSectionRef}>6. Data Security</h2>
      <p>
      We implement robust security measures to protect your information, including encryption, access controls, and regular audits. 
      While we strive to safeguard your data, no system is completely secure, and we cannot guarantee absolute protection against all threats.       </p>

      <h2 ref={DisclaimersSectionRef}>7. Your Privacy Rights</h2>
      <p>You have the right to:</p>
      <ul className="terms-containers-ul">
        <li><strong>Access:</strong> Request access to the information we hold about you.</li>
        <li><strong>Correction:</strong> Request corrections to inaccurate or incomplete data.</li>
        <li><strong>Deletion:</strong> Request deletion of your data, subject to legal or contractual obligations.</li>
        <li><strong>Portability:</strong> Receive a copy of your data in a portable format.</li>
        <li><strong>Withdraw Consent:</strong> Withdraw consent for specific processing activities.</li>
        <li><strong>Opt-Out:</strong> Decline marketing communications or restrict certain processing activities.</li>
      </ul>
      <p>
       To exercise your rights, contact us at <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@huebits.com" 
            style={{ textDecoration: 'none'}} target="_blank" rel="noopener noreferrer" >
              support@huebits.com
        </a>
      </p>
      <h2 ref={IndemnificationSectionRef}>8. Data Retention</h2>
      <p>
      We retain your data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, 
      comply with legal obligations, resolve disputes, and enforce agreements. When data is no longer required, we securely delete or anonymize it. 
      </p>

      <h2 ref={GoverningSectionRef}>9. International Data Transfers</h2>
      <p>
      If you access the Platform from outside India, your information 
      may be transferred to and processed in India or other jurisdictions where we or our partners operate. By using the Platform, you consent to such data transfers in accordance with this Privacy Policy. 
      </p>

      <h2 ref={ChildrensSectionRef}>10. Children’s Privacy</h2>      <p>
      The Platform is not intended for children under 13 years of age, and we do not knowingly collect personal information from them. If we become aware that a child has provided us with personal data, we will take steps to delete it.       
      </p>
      <h2 ref={UpdatesSectionRef}>11. Updates to This Privacy Policy</h2>
      <p>
      We may update this Privacy Policy to reflect changes in our practices, legal requirements, or other circumstances. We encourage you to review this Policy periodically. The updated version will be effective as of the “Effective Date” stated above.       </p>

      <h2 ref={ContactSectionRef}>12. Contact Us</h2>
      <p>
      For questions, concerns, or requests regarding this Privacy Policy, please contact us: 
      </p>
      <p>
        <strong>Huebits Tech Private Limited</strong><br />
        Email: <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@huebits.com" 
            style={{ textDecoration: 'none'}} target="_blank" rel="noopener noreferrer" >
              support@huebits.com
        </a>
      </p>
      <p>Thank you for trusting <strong>Huebits</strong> to support your learning and professional journey.</p>
    </div>
      )}
    {/* Organizations */}
    {activeSection === "organizations" && (
    <div className="terms-container">
      <h1>Privacy Policy for Organizations</h1>
      <p className="last-updated"><strong>Effective Date:</strong> 20th January 2025</p>
      <p>
      At Huebits Tech Private Limited <strong>("Huebits," "we," "us," or "our")</strong>, we are committed to protecting
      and respecting your privacy. This Privacy Policy outlines how we collect, use, and protect 
      the information we receive when providing consulting services related to Proof of Concept (POC), 
      Innovation, and Research & Development (R&D) solutions to organizations.
      </p>
      <div className="terms-containers-div">
      <h2>Table of Contents</h2>
      <ul>
        <li onClick={scrollToAccount}>1. Information We Collect</li>
        <li onClick={scrollToCourses}>2. How We Use Your Information</li>
        <li onClick={scrollToPayment}>3. Sharing of Information</li>
        <li onClick={scrollToContents}>4. Data Security</li>
        <li onClick={scrollToIntellectuals}>5. Retention of Data</li>
        <li onClick={scrollToTerminations}>6. Your Rights</li>
        <li onClick={scrollToDisclaimer}>7. Changes to This Privacy Policy</li>
        <li onClick={scrollToContacts}>8. Contact Us</li>
      </ul>
      </div>
      <h2 ref={AccountsSectionsRef}>1. Information We Collect</h2>
      <p>
      When engaging with organizations for consulting services, we may collect the following types of information:        
      </p>
      <ul className="terms-containers-ul">
        <li><strong>Contact Information:</strong> Name, organization, job title, email address, and phone number.</li>
        <li><strong>Project-Related Information:</strong> Data related to POC, innovation strategies, R&D objectives, 
            technical specifications, business needs, and any other information shared in the course of consulting.</li>
        <li><strong>Usage Data:</strong> Information about how you interact with our services, including communication with our team, usage of our platforms, and feedback.</li>
      </ul>
      <h2 ref={CourseSectionsRef}>2. How We Use Your Information</h2>
      <p>
      We use the collected information for the following purposes:       
      </p>
      <ul className="terms-containers-ul">
        <li><strong>Providing Services:</strong> To deliver consulting services such as POC development, innovation strategies, and R&D solutions.</li>
        <li><strong>Communication:</strong> To communicate with you regarding our services, including updates, meetings, project milestones, and feedback requests.</li>
        <li><strong>Improving Services:</strong> To enhance the quality and effectiveness of our services, and to tailor our solutions to your organization's specific needs.</li>
        <li><strong>Legal and Compliance:</strong> To comply with applicable laws, regulations, and legal obligations related to consulting services.</li>
      </ul>

      <h2 ref={PaymentsSectionsRef}>3. Sharing of Information</h2>
      <p>We respect your privacy and will not share your information with third parties except under the following circumstances:</p>
      <ul className="terms-containers-ul">
        <li><strong>Service Providers:</strong> We may share information with trusted third-party service providers who assist in delivering
         our consulting services (e.g., software tools, cloud services). 
         These third parties are obligated to protect your data in accordance with our instructions and applicable privacy laws. </li>
        <li><strong>Legal Requirements:</strong> We may disclose your information to comply with legal obligations, such as responding to a subpoena or other legal processes, or to protect our rights, safety, and property.</li>
      </ul>

      <h2 ref={ContentSectionsRef}>4. Data Security </h2>
      <p>We implement appropriate technical and organizational measures to protect the information we collect. While we strive to use commercially acceptable means to protect your personal data, please note that
         no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

      <h2 ref={IntellectualSectionsRef}>5. Retention of Data </h2>
      <p>We retain the collected information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. When the information is no longer needed for these purposes, we will securely delete or anonymize it.</p>
      <p>We do not sell your personal information to third parties.</p>

      <h2 ref={TerminationSectionsRef}>6. Your Rights</h2>
      <p>
      Depending on your jurisdiction, you may have certain rights regarding your personal information, including:       
      </p>
      <ul className="terms-containers-ul">
        <li>The right to access, correct, or delete the data we hold about you.</li>
        <li>The right to object to or restrict the processing of your data.</li>
        <li>The right to withdraw consent, where applicable.</li>
        <li>The right to request data portability.</li>
      </ul>
      <p>To exercise any of these rights, please contact us using the details provided below.</p>

      <h2 ref={DisclaimersSectionsRef}>7. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. When we do, we will post the revised policy on our website and update the "Effective Date" at the top. We encourage you to review this policy periodically to stay informed about how we protect your information.</p>

      <h2 ref={ContactSectionsRef}>8. Contact Us</h2>
      <p>
      If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
      <p>
        <strong>Huebits Tech Private Limited</strong><br />
        Email: <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@huebits.com" 
            style={{ textDecoration: 'none'}} target="_blank" rel="noopener noreferrer" >
              support@huebits.com
        </a>
      </p>
      <p>Website: <a href="http://www.huebits.in" target="_blank" rel="noopener noreferrer" >http://www.huebits.in</a></p>
      {/* <p>Thank you for trusting <strong>Huebits</strong> to support your learning and professional journey.</p> */}
    </div>
    )}
     <div className="homepage-footers-images">
     <Footer />
    <footer className="homepage-footers">
     <hr className='homepage-footer-hr'/>
       <div className="footer-social">
       <div className="homepage-footer-copyright">
         <p className="homepage-footer-copyrightp">© Huebits[2025].All Rights Reserved.</p>
       </div>
       <div className='footer-social-icons'>
         <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
         <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
         <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
         <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
         {/* <Link><FaFacebook className="social-icon" /></Link> */}
         <Link onClick={scrollToFooterSection}>
         <FaArrowUp className="homepage-social-icon-link" />
         </Link>
       </div>
       </div>
     </footer>
     </div>
     </div>
     </div>
    </div>
  );
};

export default TermsOfUse;
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Link } from "react-router-dom";
import Huebits from "../../assets/New folder/2.png";
import Reveal from "../../helper/useScrollposition";
import "./huebits.css";

const Bestplan = () => {
    return (
            <div className="huebits-choose">
              <Reveal>
              <div className="huebits-Choose-Serviceses">
                <h1 className="homepagehubits-for-students">Why Learners Choose Us?</h1>
                <div className="huebits-homepage-flex">
                  <div className="huebits-iner-flex">
                  <img className="huebits-homepages" src={Huebits} alt="" />
                  <div className="huebits-inr2-3">
                  <p className="huebits-homepages1-span-hue">Master basics to advanced for full tech spectrum</p>
                  <div className="huebits-homepages1-span-hue-hr"><hr /></div>
                  <p className="huebits-homepages1-span-hue">Solve real problems, gain experience, seize opportunities</p>
                  <div className="huebits-homepages1-span-hue-hr"><hr /></div>
                  <p className="huebits-homepages1-span-hue">Access wealth: courses, projects, anytime, anywhere</p>
                  <div className="huebits-homepages1-span-hue-hr"><hr /></div>
                  </div>
                    </div>
                  </div>
                <div className="homepagehubits-for-students-courses-btn">
                <Link to="/learners">
               <button className="compaines-buttonget-1 link">
                Learn More
                <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
              </button>
             </Link>
           </div>
         </div>
         </Reveal>
     </div>
    )
}

export default Bestplan;
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const token = localStorage.getItem("company_token"); // Check for token in localStorage

  if (!token) {
    // Redirect to the login page if not logged in
    return <Navigate to="/companiessignin" replace />;
  }

  // Render all child routes if logged in
  return <Outlet />;
};

export default ProtectedRoutes;

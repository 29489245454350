import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Typography, Grid, Container, Box } from '@mui/material';
import InternetofThings from '../../../assets/learningpaths/1.png';
import DataScienceAIMLDL from '../../../assets/learningpaths/2.png';
import EmbeddedSystems from '../../../assets/learningpaths/3.png';
import Topbar from "./topbar";

const LearningPath = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        
        if (token) {
            localStorage.setItem("token", token);  // Store token in localStorage
            navigate("/dashboardpage");  // Redirect to learning path page or wherever you need
        } else {
            const storedToken = localStorage.getItem("token");
            if (!storedToken) {
                navigate("/login");
            }
        }
    }, [navigate]);    

    return (
    <div className="students-flex">
      <Topbar />
      <div className='mylearningpaths'>
        <Container>
            <Box mb={6}>
                <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 900, color: '#052659', paddingTop: '20px' }}>
                    Explore Your Future with Our Industry-Ready Programs
                </Typography>
                <Typography variant="h6" align="center" paragraph sx={{ color: '#333' }}>
                    Thank you for joining us! Choose your path to success
                </Typography>
            </Box>

            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={4} align="center">
                    <Box sx={{ boxShadow: 3, borderRadius: '8px', overflow: 'hidden',backgroundColor: "#ffff" }}>
                        <img
                            src={InternetofThings}
                            alt="IoT Developer"
                            style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'cover',
                            }}
                        />
                        <Box p={3}>
                            <Typography variant="h5" sx={{ fontWeight: 700, color: '#052659' }} gutterBottom>
                                Internet of Things
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Master the technology behind smart, connected solutions.
                            </Typography>
                            <Link to="/dashboard/internetofthings" style={{ textDecoration: 'none' }}>
                            <Button variant="contained" color="primary" 
                            sx={{ width: '100%', 
                            background: 'linear-gradient(135deg, #5483B3, #052659)',
                            '&:hover': {
                                background: 'linear-gradient(135deg,  #052659, #345A80)', // Hover background
                            },
                            }}>
                                Start
                            </Button>
                            </Link>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} align="center">
                    <Box sx={{ boxShadow: 3, borderRadius: '8px', overflow: 'hidden', backgroundColor: "#ffff"  }}>
                        <img
                            src={DataScienceAIMLDL}
                            alt="Data Science"
                            style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'cover',
                            }}
                        />
                        <Box p={3}>
                            <Typography variant="h5" sx={{ fontWeight: 700, color: '#052659' }} gutterBottom>
                                Data Science
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Harness the power of data to drive innovation and decisions.
                            </Typography>
                            <Button variant="outlined" color="secondary" sx={{ width: '100%' }} disabled>
                                Coming Soon
                            </Button>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} align="center">
                    <Box sx={{ boxShadow: 3, borderRadius: '8px', overflow: 'hidden', backgroundColor: "#ffff" }}>
                        <img
                            src={EmbeddedSystems}
                            alt="Embedded Systems"
                            style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'cover',
                            }}
                        />
                        <Box p={3}>
                            <Typography variant="h5" sx={{ fontWeight: 700, color: '#052659' }} gutterBottom>
                                Embedded Systems
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Build intelligent systems shaping the future of technology.
                            </Typography>
                            <Button variant="outlined" color="secondary" sx={{ width: '100%' }} disabled>
                                Coming Soon
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Box mt={5}>
                <Typography variant="body1" align="center" paragraph sx={{ color: '#333', paddingBottom: '40px' }}>
                    Your journey begins here. Select your program and take the first step today!
                </Typography>
            </Box>
        </Container>
          </div>
        </div>
    );
};

export default LearningPath;
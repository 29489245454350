import React from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { IoTdeveloperProjects } from "../hooks/projectsList"; // Import the courses data


const Start = () => {

  return (
    <div>
      <p className="mylearningpaths-p">Projects</p>
      <div className="mylearningpath">
        <div className="Wrapper-sc-fvyqwl-1 iZEyIQ AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
          <ul className="Circle-sc-fvyqwl-1">
            {IoTdeveloperProjects.map((project, index) => (
              <li key={project.projectId}>
                <div className="learningpath-courses-dashboard">
                  <div>
                  <img src={project.image} alt={project.projectTitle} className="smart-shoe-development" />
                  </div>
                  <div>
                  <p className={`${project.projectId}`} style={{paddingTop: "10px",}}>
                  {index + 1}. {project.projectTitle}
                  </p>
                  <div className="learningpath-courses-dashboard-progressbar">
                  <p className="learningpath-courses-dashboard-skills-completed">{project.completion}%</p>
                  <ProgressBar now={project.completion} className={`custom-progress-bar`} />
                  </div>
                  <div>
                     <div>
                    {project.completion === 100 ? (
                      <button className="learningpath-courses-dashboard-button-start">Download Certificate</button>
                    ) : project.completion >= 10 ? (
                      <button className="learningpath-courses-dashboard-button-start">Continue Learning</button>
                    ) : (
                      <button className="learningpath-courses-dashboard-button-start">Coming Soon</button>
                    )}
                  </div>
                  </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
);
};

export default Start;
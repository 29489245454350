export const BASE_URL = "https://api.huebits.in"; //http://3.110.223.160:8000

//Learnersapis
export const LearnersSignup = `${BASE_URL}/api/signup`;
export const LearnersLogin = `${BASE_URL}/api/login`;
export const LearnersSinglelogin = `${BASE_URL}/api/singlesignon`;
export const Learnersgooglelogin = `${BASE_URL}/api/auth/google/server`;
export const LearnersGenerateOtp = `${BASE_URL}/api/generate-otp`;
export const LearnersVerifyOtp = `${BASE_URL}/api/verify-otp`;
export const LearnersForgotPassword = `${BASE_URL}/api/forgot-password`;
export const LearnersProfile = `${BASE_URL}/api/learner-profile/`;
export const LearnersResume = `${BASE_URL}/api/learner-resume`;
export const LearnersAddCourses = `${BASE_URL}/api/add-course`;
export const LearnersAddProjects = `${BASE_URL}/api/add-project`;
export const LearnersCourses = `${BASE_URL}/api/get-courses`;
export const LearnersProjects = `${BASE_URL}/api/get-projects`;
//export const LearnersCoursesProgress = `${BASE_URL}/api/coursesprogress`;
//export const LearnersProjectsProgress = `${BASE_URL}/api/coursesprogress`;
export const LearnersSubscription = `${BASE_URL}/api/search-learner-trans`;
export const LearnersDashboard = `${BASE_URL}/api/dashboard`;
export const LearnersLearningpath = `${BASE_URL}/api/learning-path`;

//Organizations
export const OrganizationsSignup = `${BASE_URL}/api/organizations-signup`;
export const OrganizationsLogin = `${BASE_URL}/api/organizations-signin`;
export const OrganizationsGenerateOtp = `${BASE_URL}/api/organizations-generate-otp`;
export const OrganizationsVerifyOtp = `${BASE_URL}/api/organizations-verify-otp`;
export const OrganizationsForgotPassword = `${BASE_URL}/api/organizations-forgot-password`;
export const OrganizationsPapup = `${BASE_URL}/api/organizations`;
// export const OrganizationsPostProject = `${BASE_URL}/api/post-project`;
// export const OrganizationsSelectindustry = `${BASE_URL}/api/select-industry`;
// export const OrganizationsSelectrequired = `${BASE_URL}/api/skills-required`;
// export const OrganizationsSelectlanguages = `${BASE_URL}/api/select-languages`;
// export const OrganizationsCompletiontime = `${BASE_URL}/api/completion-time`;
export const OrganizationsSubmitproject = `${BASE_URL}/api/upload-project`;
export const OrganizationsCompaniesprofile = `${BASE_URL}/api/organizations-profile`;
export const Organizationsuploads = `${BASE_URL}/api/uploads`;

//Admindashboard
export const Adminsignup = `${BASE_URL}/api/admin-signup`;
export const Adminlogin = `${BASE_URL}/api/admin-login`;
export const AdminGenerateOtp = `${BASE_URL}/api/admin-generate-otp`;
export const AdminVerifyOtp = `${BASE_URL}/api/admin-verify-otp`;
export const AdminForgotPassword = `${BASE_URL}/api/admin-forgot-password`;
export const AdminLearnerslist = `${BASE_URL}/api/admin/learners`;
export const AdminLearnersuploads = `${BASE_URL}/uploads/`;
export const AdminOrganizationslist = `${BASE_URL}/api/admin/org-users`;
export const AdminOrganizationsuploads = `${BASE_URL}/uploads/`;








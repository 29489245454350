// src/hooks/useLearningProgress.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import { LearnersLearningpath } from "../../../../helper/base_url";

const useLearningProgress = () => {
  const [learningProgress, setLearningProgress] = useState([]);

  useEffect(() => {
    const fetchProgressData = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const { data } = await axios.get(`${LearnersLearningpath}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setLearningProgress(data.data.projects || []);
      } catch (err) {
        console.error("Something went wrong.");
      }
    };

    fetchProgressData();
  }, []);

  return learningProgress;
};

export default useLearningProgress;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { Container, TextField, Button, Typography, Box, LinearProgress, Paper } from "@mui/material";
import OrganizationsHeader from "../PopupOrganizations/topbar";

const Postprojects1 = () => {
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [loading, setLoading] = useState(false);

    // Use the useNavigate hook for navigation
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
    
        const title1 = event.target.title1.value.trim();
        const textarea = event.target.textarea.value.trim();
    
        // Validation for empty fields
        if (!title1) {
            toast.error("Please enter the project title.");
            setLoading(false);
            return;
        }
        if (!textarea) {
            toast.error("Please provide a project description.");
            setLoading(false);
            return;
        }
    
        // Save the project data to localStorage
       const projectData = { title1, textarea };
       localStorage.setItem("ProjectData", JSON.stringify(projectData));

    
        // Simulate submission success
        toast.success("Project details submitted successfully!");
        setCompletionPercentage((prev) => Math.min(prev + 10, 100));
    
        // Navigate to the next page
        setTimeout(() => {
            setLoading(false);
            navigate("/selectindustry");
        }, 500);
    };    

    return (
        <div className="students-flex">
            <OrganizationsHeader />
            <div className="signup-pages-flex">
                 <Box
                    sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%)",
                    padding: "20px 10px",
                    height: { xs: "auto", sm: "100vh", md: "100vh" },
                    }}
                    >
                    <ToastContainer position="top-center" reverseOrder={false} />
                    <Box sx={{ marginTop: 0 }}>
                        <Container maxWidth="sm">
                            <Paper elevation={3} sx={{ borderRadius: 2, textAlign: "center", padding: "10px 20px 30px 20px" }}>
                                <Box component="form" onSubmit={handleSubmit} noValidate>
                                    <Typography variant="h4" align="center" sx={{ fontWeight: 700, color: "#052659" }}>
                                        Post Your Project
                                    </Typography>
                                    <Typography align="center" sx={{ color: "text.secondary" }}>
                                        Share your project details and connect with skilled engineers.
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                        Project Title
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        name="title1"
                                        placeholder="e.g., Build a Chatbot for Customer Care"
                                        variant="outlined"
                                        required
                                        margin="normal"
                                    />
                                    <Typography variant="h6" gutterBottom>
                                        Project Description
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        name="textarea"
                                        placeholder="Describe your project goals and expectations in 2-3 sentences."
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        required
                                        margin="normal"
                                    />
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 3 }}>
                                        <Button
                                            component={Link}
                                            to="/organization"
                                            variant="outlined"
                                            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                                            disabled={loading}
                                            sx={{
                                                margin: 0.5,
                                                color: "#052659",
                                                "& .MuiChip-deleteIcon": {
                                                    color: "#052659",
                                                },
                                            }}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            endIcon={loading ? null : <FontAwesomeIcon icon={faArrowRight} />}
                                            disabled={loading}
                                            sx={{
                                                margin: 0.5,
                                                backgroundColor: "#043885",
                                                "&:hover": {
                                                    backgroundColor: "#052659",
                                                },
                                            }}
                                        >
                                            {loading ? "Submitting..." : "Next"}
                                        </Button>
                                    </Box>
                                    <Box sx={{ marginTop: 4 }}>
                                        <Typography variant="body2" gutterBottom>
                                            Step 1 of 6
                                        </Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={completionPercentage}
                                            sx={{
                                                borderRadius: 1,
                                                height: 10,
                                                backgroundColor: "#f5f5f5",
                                                "& .MuiLinearProgress-bar": {
                                                    backgroundColor: "green",
                                                },
                                            }}
                                        />
                                        <Typography
                                            variant="body2"
                                            align="right"
                                            sx={{ mt: 1, fontWeight: 600 }}
                                        >
                                            {completionPercentage}%
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ textAlign: "center", mt: 3, color: "text.secondary" }}>
                                    <Typography variant="caption"> &copy; 2024 Huebits All Rights Reserved </Typography>
                                    <br />
                                    <Typography variant="caption">Terms of Business</Typography>
                                </Box>
                            </Paper>
                        </Container>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default Postprojects1;
import React, {useRef} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faIndustry,
  faMicrochip,
  faBrain,
  faCogs,
  faRobot
} from "@fortawesome/free-solid-svg-icons";
// import InternetofThings3 from "../../assets/companiesimages/InternetofThings3.jpg";
//import { faArrowRight,faStar} from "@fortawesome/free-solid-svg-icons";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin,FaYoutube, FaArrowUp } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import "./OrganizationBanner.css"

const InternetofThings = () => {
    const footerSectionRef = useRef(null);
  
  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

    return (
        <div className="students-flex companies-flex">
        <Headrepage />
        <div className="homepagecolores">
          <div className="homepagecolor">
          <div className="organization-iot-tech-talent-banners1">
            <div className="organization-iot-tech-talent-banner1">
              <h1>Driving Success with IoT and Tech Talent</h1>
              <p>Empowering Your Business with IoT Solutions and Skilled Tech Talent</p>
              <button className="organization-iot-tech-talent-banner1-btn">Learn More</button>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner2">
              <h1>Harness the Full Potential of IoT with Our Tailored Solutions</h1>
            <div className="organization-iot-tech-talent-banner2-cards">
            {/* <img src={InternetofThings3} alt="InternetofThings" className="organization-iot-tech-talent-banner2-card-image"/> */}
            <div className="organization-iot-tech-talent-banner2-card">
              <p>From developing smart devices to building integrated IoT
                systems, we deliver customized solutions that enable innovation,
                optimize processes, and drive growth. Let us help you harness the
                power of IoT to turn real-time data into actionable insights and
                accelerate your success in the connected world.
              </p>
              <button className="organization-iot-tech-talent-banner2-btn">Get IoT Solutions</button>
              <button className="organization-iot-tech-talent-banner2-btn">Hire IoT Tech Talent</button>
            </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner3">
                <h1>IoT professional services include</h1>
                <div className="organization-iot-tech-talent-banner3-card">
        <div className="animated-card">
          <FontAwesomeIcon icon={faNetworkWired} className="icon animated-icon" />
          <p>Internet of Things</p>
        </div>
        <div className="animated-card">
          <FontAwesomeIcon icon={faIndustry} className="icon animated-icon" />
          <p>Industrial IoT</p>
        </div>
        <div className="animated-card">
          <FontAwesomeIcon icon={faMicrochip} className="icon animated-icon" />
          <p>IoT Applications</p>
        </div>
      </div>
      <div className="organization-iot-tech-talent-banner3-card">
        <div className="animated-card">
          <FontAwesomeIcon icon={faBrain} className="icon animated-icon" />
          <p>Edge AI</p>
        </div>
        <div className="animated-card">
          <FontAwesomeIcon icon={faCogs} className="icon animated-icon" />
          <p>Industry 4.0</p>
        </div>
        <div className="animated-card">
          <FontAwesomeIcon icon={faRobot} className="icon animated-icon" />
          <p>AIoT</p>
        </div>
        </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner4">
        <h1 className="organization-iot-tech-talent-banner4-h1">How We Drive Your Success</h1>
        <p className="organization-iot-tech-talent-banner4-div1-p">
          We collaborate with your team to develop innovative IoT and AIoT
          solutions, driving transformation across industries with seamless
          integration of smart technologies. Our expertise in IoT, AIoT, and
          Industry 4.0 enables us to deliver customized systems and solutions
          that break new ground and create limitless possibilities. Together,
          we’ll revolutionize your business, accelerate growth, and ensure you
          lead in the connected world.
        </p>
      <div className="organization-iot-tech-talent-banner4-card">
        <div className="organization-iot-tech-talent-banner4-card-1">
          <p>POC of IoT based Solution</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-2">
          <p>MVP of IoT based Product</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-3">
          <p>Custom Product Development Research</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-4">
          <p>IoT Data Solutions</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-5">
          <p>Hire Tech Talent</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-6">
          <p>Industrial IoT Solutions</p>
        </div>
      </div>
    </div>
          {/* iot */}
          <div className="organization-iot-tech-talent-banner5">
            <h1>Our Technology Expertise Framework</h1>
            <div>
            <div>
              <h1>Programming and Protocols</h1>
            </div>
            <div>
              <h1>Cloud Platforms</h1>
            </div>
            <div>
              <h1>Data Analytics and Monitoring</h1>
            </div>
            <div>
              <h1>IoT Hardware</h1>
            </div>
            <div>
              <h1>IoT App Development</h1>
            </div>
            <div>
              <h1>Data Science, AI, ML</h1>
            </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner6">
            <h1>Streamlined Process for IoT Solutions and Tech Talent Engagement</h1>
            <p>Achieve your IoT vision with our end-to-end workflow, seamlessly
              integrating every component—from sensors to data repositories.
              From development to deployment, we ensure flawless execution
              and lasting impact for your business.</p>
          <div className="organization-iot-tech-talent-banner6-steps">
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Discovery and Ideation</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step1</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Design and Prototyping</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step2</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Development and Integration</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step3</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Testing and Validation</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step4</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Deployment, Monitoring and Feedback</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step5</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Hire Tech Talent</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step6</div>
          </div>
        </div>
        </div>
          <div className="organization-iot-tech-talent-banner7">
            <h1 className="organization-iot-tech-talent-banner7-h1">Take the Next Step</h1>
            <p className="organization-iot-tech-talent-banner7-p">Whether you're looking to hire expert tech talent or need tailored
            data solutions, we're here to help!</p>
            <div className="organization-iot-tech-talent-banner7-contact">
              <h1>Contact Us</h1>
              <p>Schedule a meeting to discuss your needs</p>
            </div>
            <div className="organization-iot-tech-talent-banner7-btn">
              <div className="organization-iot-tech-talent-banner7-btn1">
              <button>Post Your Project</button>
              <p>Click here to share your requirements</p>
              </div>
              <div className="organization-iot-tech-talent-banner7-btn1">
                <button>Hire Tech Talent</button>
                <p>Click Here to find the perfect fit for your team</p>
              </div>
            </div>
          </div>
          <div>
             <p>Partners</p>
            <div>
            <div>
              <p>FlowFuse</p>
            </div>
            <div>
            <p>N3uron</p>
            </div>
            <div>
              <p>ThingsBoard</p>
            </div>
            <div>
              <p>EDGE IMPULSE</p>
            </div>
            <div>
              <p>AWS</p>
            </div>
            <div>
              <p>Azure</p>
            </div>
            </div>
          </div>
          <div className="homepage-footers-images">
          <Footer />
          <footer className="homepage-footers">
          <hr className='homepage-footer-hr'/>
          <div className="footer-social">
          <div className="homepage-footer-copyright">
            <p>© Huebits[2025].All Rights Reserved.</p>
          </div>
          <div className='footer-social-icons'>
              <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
              <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
              <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
              <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
              <Link><FaFacebook className="social-icon" /></Link>
              <Link onClick={scrollToFooterSection}>
              <FaArrowUp className="homepage-social-icon-link" />
              </Link>
          </div>
          </div>
        </footer>
      </div>
    </div>
    </div>
    )
}


export default InternetofThings;
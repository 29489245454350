import React, {useRef} from "react";
import { Link } from "react-router-dom";
import Internet from "../../assets/companiesimages/services/8.png";
import Industrial from "../../assets/companiesimages/services/9.png";
import IoT from "../../assets/companiesimages/services/10.png";
import Edge from "../../assets/companiesimages/services/11.png";
import Industry from "../../assets/companiesimages/services/12.png";
import AIoT from "../../assets/companiesimages/services/13.png";
//Technology
import Python from "../../assets/companiesimages/Technology/1.png";
import Numpy from "../../assets/companiesimages/Technology/34.png";
import Pandas from "../../assets/companiesimages/Technology/38.png";
import Learn from "../../assets/companiesimages/Technology/37.png";
import Pytorch from "../../assets/companiesimages/Technology/45.png";
import Tensorflow from "../../assets/companiesimages/Technology/41.png";
import Seaborn from "../../assets/companiesimages/Technology/42.png";
import Beautiful from "../../assets/companiesimages/Technology/43.png";
import Plotly from "../../assets/companiesimages/Technology/36.png";

import Spark from "../../assets/companiesimages/Technology/44.png";
import Keras from "../../assets/companiesimages/Technology/46.png";
import Kafka from "../../assets/companiesimages/Technology/35.png";
import Postgresql from "../../assets/companiesimages/Technology/47.png";
import Elastic from "../../assets/companiesimages/Technology/48.png";

import Powerbi from "../../assets/companiesimages/Technology/23.png";
import Tableau from "../../assets/companiesimages/Technology/24.png";
import Superset from "../../assets/companiesimages/Technology/49.png";
import Amazon from "../../assets/companiesimages/Technology/50.png";
import AzureSynapseAnalytics from "../../assets/companiesimages/Technology/62.png";
import AzureanalysisServices from "../../assets/companiesimages/Technology/51.png";
import Qlik from "../../assets/companiesimages/Technology/52.png";
import Grafana from "../../assets/companiesimages/Technology/20.png";

import Databricks from "../../assets/companiesimages/Technology/53.png";
import Flink from "../../assets/companiesimages/Technology/54.png";
import Airflow from "../../assets/companiesimages/Technology/55.png";
import Dbt from "../../assets/companiesimages/Technology/56.png";
import Datafactory from "../../assets/companiesimages/Technology/57.png";
import Datacatalog from "../../assets/companiesimages/Technology/58.png";
import Eventhubs from "../../assets/companiesimages/Technology/59.png";
import Azureiothub from "../../assets/companiesimages/Technology/60.png";
import Azuredata from "../../assets/companiesimages/Technology/61.png";
import Azuremachine from "../../assets/companiesimages/Technology/63.png";
import Azuredatabricks from "../../assets/companiesimages/Technology/64.png";
import Cosmosdb from "../../assets/companiesimages/Technology/65.png";
import Azurefunctions from "../../assets/companiesimages/Technology/66.png";
import Snowflake from "../../assets/companiesimages/Technology/68.png";
import Sql from "../../assets/companiesimages/Technology/69.png";

import Gpt from "../../assets/companiesimages/Technology/70.png";
import Whisper from "../../assets/companiesimages/Technology/71.png";
import Dall from "../../assets/companiesimages/Technology/72.png";
import Llama from "../../assets/companiesimages/Technology/73.png";
import Midjourney from "../../assets/companiesimages/Technology/74.png";
import Bert from "../../assets/companiesimages/Technology/75.png";
import Stablediffusion from "../../assets/companiesimages/Technology/76.png";
import Stanza from "../../assets/companiesimages/Technology/77.png";
import Flair from "../../assets/companiesimages/Technology/78.png";
import Spacy from "../../assets/companiesimages/Technology/79.png";
import Stylegan from "../../assets/companiesimages/Technology/80.png";
import Cyclegan from "../../assets/companiesimages/Technology/81.png";
import Huggingface from "../../assets/companiesimages/Technology/82.png";

//Partners
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import Snowflakes from "../../assets/companiesimages/Partners/8.png";
import Awses from "../../assets/companiesimages/Partners/6.png";
import Databrickses from "../../assets/companiesimages/Partners/10.png";
import Azures from "../../assets/companiesimages/Partners/7.png";
import Partners from "../../assets/companiesimages/Partners/1.jpg";

// import InternetofThings3 from "../../assets/companiesimages/InternetofThings3.jpg";
//import { faArrowRight,faStar} from "@fortawesome/free-solid-svg-icons";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin,FaYoutube, FaArrowUp } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import "./OrganizationBanner.css";

const InternetofThings = () => {
    const footerSectionRef = useRef(null);
  
  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

    return (
        <div className="students-flex companies-flex">
        <Headrepage />
        <div className="homepagecolores">
          <div className="homepagecolor">
          <div className="organization-data-science-tech-talent-banners1">
            <div className="organization-iot-tech-talent-banner1">
              <h1>Driving Innovation with Data Science & AI</h1>
              <p>Empowering Your Business with Advanced Data, AI Solutions, and Skilled Tech Talent</p>
              <button className="organization-iot-tech-talent-banner1-btn">Learn More</button>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner2">
              <h1>Unlock the Power of Data with Our Expert Solutions</h1>
            <div className="organization-iot-tech-talent-banner2-cards">
            {/* <img src={InternetofThings3} alt="InternetofThings" className="organization-iot-tech-talent-banner2-card-image"/> */}
            <div className="organization-iot-tech-talent-banner2-card">
              <p>Transform your business with cutting-edge Artificial Intelligence,Machine Learning, 
                and Data Engineering solutions. From building
                intelligent models to designing robust data pipelines, we deliver
                tailored solutions to help you innovate, optimize, and grow. Let us
                turn your data into actionable insights and empower your success
                in the digital age.
              </p>
              <button className="organization-iot-tech-talent-banner2-btn">Get Data Solutions</button>
              <button className="organization-iot-tech-talent-banner2-btn">Hire AI and Data Talent</button>
            </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner3">
                <h1>Data science professional services include</h1>
                <div className="organization-iot-tech-talent-banner3-card">
        <div className="animated-card">
          <img src={Internet} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Data Science</p>
        </div>
        <div className="animated-card">
          <img src={Industrial} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Data Engineering</p>
        </div>
        <div className="animated-card">
          <img src={IoT} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Machine Learning</p>
        </div>
      </div>
      <div className="organization-iot-tech-talent-banner3-card">
        <div className="animated-card">
          <img src={Edge} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Natural Language Processing</p>
        </div>
        <div className="animated-card">
          <img src={Industry} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Generative AI</p>
        </div>
        <div className="animated-card">
          <img src={AIoT} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Business Intelligenec</p>
        </div>
        </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner4">
        <h1 className="organization-iot-tech-talent-banner4-h1">How We Can Support Your Success</h1>
        <p className="organization-iot-tech-talent-banner4-div1-p">
          We collaborate with teams to deliver innovative solutions for projects
          of any scale or complexity. Leveraging our robust tech talent pool—
          spanning AI, data science, data engineering, IoT, and generative AI—
          we create tailored systems, solutions, and products that give your
          business a competitive edge. Together, we’ll achieve impactful results
          and drive your success.
        </p>
      <div className="organization-iot-tech-talent-banner4-card">
        <div className="organization-iot-tech-talent-banner4-card-1">
          <p>POC of AI based Solution</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-2">
          <p>MVP of AI based Product</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-3">
          <p>Custom Model Development</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-4">
          <p>Data Engineering Solutions</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-5">
          <p>Hire Tech Talent</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-6">
          <p>BI Solutions</p>
        </div>
      </div>
      </div>
          {/* iot */}
          <div className="organization-iot-tech-talent-banner5">
            <h1>Our Tech Expertise Matrix</h1>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Data Science</h2>
              <img src={Python} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Numpy} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Pandas} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Learn} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Pytorch} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Tensorflow} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Seaborn} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Beautiful} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Plotly} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Artificial intelligence</h2>
              <img src={Python} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Tensorflow} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Learn} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Spark} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Keras} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Stanza} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Kafka} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Postgresql} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Elastic} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Business Intelligence</h2>
              <img src={Powerbi} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Tableau} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Superset} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Amazon} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={AzureSynapseAnalytics} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={AzureanalysisServices} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Grafana} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Qlik} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Generative AI</h2>
              <img src={Gpt} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Whisper} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Dall} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Llama} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Midjourney} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Bert} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Stablediffusion} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Stanza} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Flair} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Spacy} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Stylegan} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Cyclegan} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Huggingface} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banners5-1">
              <h2>Data Engineering</h2>
              <img src={Python} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Databricks} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Spark} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Flink} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Airflow} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Dbt} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Kafka} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Datafactory} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Datacatalog} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Eventhubs} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Azureiothub} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Azuredata} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Powerbi} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={AzureSynapseAnalytics} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Azuremachine} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Azuredatabricks} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Cosmosdb} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Azurefunctions} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={AzureanalysisServices} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Snowflake} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Sql} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner6">
            <h1>Streamlined Process for Data Solutions and Tech Talent Engagement</h1>
            <p>Achieve your goals with our end-to-end process, combining
              tailored data solutions with top-tier tech talent. From project
              kickoff to deployment, we ensure seamless execution and lasting
              value for your business.
            </p>
          <div className="organization-iot-tech-talent-banner6-steps">
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Post Requirements & Kickoff</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step1</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Proof of Concept (PoC) or MVP Development</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step2</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Data Acquisition & Preparation</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step3</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Model Development & Integration</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step4</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Model Deployment & Feedback</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step5</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Hire Tech Talent</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step6</div>
          </div>
        </div>
        </div>
          <div className="organization-iot-tech-talent-banner7">
            <h1 className="organization-iot-tech-talent-banner7-h1">Take the Next Step</h1>
            <p className="organization-iot-tech-talent-banner7-p">Whether you're looking to hire expert tech talent or need tailored
            data solutions, we're here to help!</p>
            <div className="organization-iot-tech-talent-banner7-contact">
              <h1>Contact Us</h1>
              <p>Schedule a meeting to discuss your needs</p>
            </div>
            <div className="organization-iot-tech-talent-banner7-btn">
              <div className="organization-iot-tech-talent-banner7-btn1">
              <button>Post Your Project</button>
              <p>Click here to share your requirements</p>
              </div>
              <div className="organization-iot-tech-talent-banner7-btn1">
                <button>Hire Tech Talent</button>
                <p>Click Here to find the perfect fit for your team</p>
              </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner8">
             <p className="organization-iot-tech-talent-banner8-p"><FontAwesomeIcon icon={faHandshake} /> Partners</p>
            <div className="organization-iot-tech-talent-banner8-1">
              <div>
            <div className="organization-iot-tech-talent-banner8-cards">
            <div>
              <img src={Snowflakes} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            <div>
            <img src={Awses} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner8-cards">
            <div>
            <img src={Databrickses} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            <div>
            <img src={Azures} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            </div>
            </div>
            <img src={Partners} alt="" className="organization-iot-tech-talent-banner8-cards-Partners"/>
            </div>
          </div>
          <div className="homepage-footers-images">
          <Footer />
          <footer className="homepage-footers">
          <hr className='homepage-footer-hr'/>
          <div className="footer-social">
          <div className="homepage-footer-copyright">
            <p>© Huebits[2025].All Rights Reserved.</p>
          </div>
          <div className='footer-social-icons'>
              <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
              <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
              <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
              <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
              <Link><FaFacebook className="social-icon" /></Link>
              <Link onClick={scrollToFooterSection}>
              <FaArrowUp className="homepage-social-icon-link" />
              </Link>
          </div>
          </div>
        </footer>
      </div>
    </div>
    </div>
    )
}


export default InternetofThings;
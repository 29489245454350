import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import avatar from '../../../assets/profile.png';
import convertToBase64 from "../../../helper/Convert";
import { Country, State, City } from "country-state-city";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, TextField, Select, MenuItem, Card, Typography, Grid, Box, CircularProgress } from "@mui/material";
import { LearnersProfile } from "../../../helper/base_url";

const Userprofile = () => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(null);
  const [data, setData] = useState({
    email: "",
    mobile: "",
    city: "",
    state: "",
    country: "",
    profilePic: "",
  });
  const [userId, setUserId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (data.country) {
      setStates(State.getStatesOfCountry(data.country));
    }
  }, [data.country]);

  useEffect(() => {
    if (data.state) {
      setCities(City.getCitiesOfState(data.country, data.state));
    }
  }, [data.state, data.country]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onUpload = async (e) => {
    const base64 = await convertToBase64(e.target.files[0]);
    setData((prevData) => ({
      ...prevData,
      profilePic: base64
    }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const response = await axios.put(`${LearnersProfile}${userId}`, updatedData, {
        headers: {
          "content-type": "application/json",
          authorization: `jwt ${localStorage.getItem('token')}`
        },
      });
      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 3000 });
        setShowToast(true);
        setData(response.data.data);
      } else {
        toast.error(response.data.message, { autoClose: 3000 });
        setShowToast(true);
      }
    } catch (error) {
      toast.error("Network error: Please check your connection and try again.", { autoClose: 3000 });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const { mobile } = data;

    // Validate mobile number
    const mobileRegex = /^\d{10}$/;
    if (!mobileRegex.test(mobile)) {
      toast.error('Please enter a 10-digit mobile number');
      setLoading(false);
      return;
    }

    const updatedData = { ...data };
    toast.dismiss();
    await handleUserProfile(updatedData);
  };

  useEffect(() => {
    if (token) {
      setUserId(userId);
      handleUserProfile(); 
    }
  }, [token, userId, handleUserProfile]);

  return (
    <div>
    <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
      backgroundColor: "#f9f9f9",
    }}
  >
    <Card
      sx={{
        maxWidth: 600,
        width: "100%",
        padding: "20px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
      }}
    >
      {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
      <p className="students-profile-update-p"><Typography variant="h4" component="h2" align="center" sx={{ mb: 3 }}>
      Contact Information
      </Typography></p>
      <form onSubmit={handleSubmit}>
        {/* Profile Picture */}
        <Box sx={{ textAlign: "center", mb: 3 }}>
          <label htmlFor="profile" style={{ cursor: "pointer", position: "relative" }}>
            <img
              src={data.profilePic || avatar}
              alt="Profile"
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                objectFit: "cover",
                border: "3px solid #043885",
              }}
            />
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#043885",
                color: "#fff",
                padding: "2px 6px",
                borderRadius: "4px",
                display: "none",
              }}
              className="profile-overlay"
            >
              Change Picture
            </Typography>
          </label>
          <input
            type="file"
            id="profile"
            name="profile"
            onChange={onUpload}
            style={{ display: "none" }}
          />
        </Box>
  
        {/* Form Fields */}
        <TextField
          label="E-MAIL ID"
          value={data.email}
          fullWidth
          InputProps={{ readOnly: true }}
          sx={{ mb: 2 }}
        />
          <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={14}>
            <Select
              name="country"
              value={data.country}
              onChange={handleOnChange}
              fullWidth
              displayEmpty
              sx={{ mb: 2 }}
            >
              <MenuItem>
                <em>Select Country</em>
              </MenuItem>
              {countries.map((country) => (
                <MenuItem key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={14}>
            <Select
              name="state"
              value={data.state}
              onChange={handleOnChange}
              fullWidth
              displayEmpty
              disabled={!data.country}
              sx={{ mb: 2 }}
            >
              <MenuItem>
                <em>Select State</em>
              </MenuItem>
              {states.map((state) => (
                <MenuItem key={state.isoCode} value={state.isoCode}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={14}>
            <Select
              name="city"
              value={data.city}
              onChange={handleOnChange}
              fullWidth
              displayEmpty
              disabled={!data.state}
              sx={{ mb: 2 }}
            >
              <MenuItem>
                <em>Select City</em>
              </MenuItem>
              {cities.map((city) => (
                <MenuItem key={city.name} value={city.name}>
                  {city.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
  
        {/* Submit Button */}
        <Box sx={{ textAlign: "center" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#043885",
                "&:hover": {
                  backgroundColor: "#052659",
                },
                padding: "10px 20px",
              }}
              disabled={loading}
            >
              Update Profile
            </Button>
          )}
        </Box>
      </form>
    </Card>
  </Box>
  </div>  
  );
};

export default Userprofile;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemButton, ListItemText, Modal, Button, Box } from "@mui/material";
import PopupImage from "../../assets/Popup6.png";

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [openModel, setOpenModel] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const closeMenu = () => setMenuActive(false);

  const menuItems = [
    {
      label: "Learners",
      path: "/learners",
    },
    {
      label: "Organizations",
      path: "/organizations",
    },
    {
      label: "Log In",
      onClick: () => { closeMenu(); setOpenModel(true); },
    },
  ];

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          bgcolor: "#ffff", // Modern dark blue background
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: { xs: 2, sm: 4, md: 6 },
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          {/* Brand Name */}
          <Typography
            variant="h1"
            sx={{
              fontSize: "40px",
              fontWeight: "700",
              color: "rgb(2, 41, 100)",
              textDecoration: "none",
            }}
            component={Link}
            to="/"
          >
            Hue<span style={{ color: "#1c84e4" }}>bits</span>
          </Typography>

          {/* Responsive Menu Button */}
          <IconButton
            edge="end"
            onClick={toggleMenu}
            sx={{
              color: "rgb(2, 41, 100)",
              display: { xs: "block", md: "none" }, // Show only on mobile/tablets
            }}
          >
            <MenuOutlinedIcon fontSize="large" />
          </IconButton>

          {/* Desktop Menu */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: 3,
            }}
          >
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                onClick={item.onClick}
                style={{ textDecoration: "none" }}
              >
                <Box
                  sx={{
                    color: "#052659",
                    fontWeight: 700,
                    fontSize: "20px",
                    padding: "8px 16px",
                    borderRadius: "10px",
                    transition: "background-color 0.3s ease", // Smooth hover effect
                    "&:hover": {
                      backgroundColor: "#052659", // Hover background color
                      color: "#ffff", // Hover text color
                    },
                  }}
                >
                  {item.label}
                </Box>
              </Link>
            ))}
          </Box>
        </Toolbar>

        {/* Drawer for Mobile/Tablet Menu */}
        <Drawer
          anchor="left"
          open={menuActive}
          onClose={closeMenu}
          sx={{
            "& .MuiDrawer-paper": {
              bgcolor: "#ffff",
              color: "white",
              width: 250,
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={closeMenu}
              sx={{
                color: "#052659",
                fontWeight: 700,
                fontSize: "1rem",
                padding: "8px 16px",
                borderRadius: "4px",
                transition: "background-color 0.3s ease", // Smooth hover effect
                "&:hover": {
                  backgroundColor: "#052659", // Hover background color
                  color: "#ffff", // Hover text color
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            {menuItems.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  component={Link}
                  to={item.path}
                  onClick={item.onClick}
                >
                  <ListItemText
                    primary={item.label}
                    sx={{
                      color: "#052659",
                      fontWeight: 700,
                      padding: "8px 16px",
                      borderRadius: "4px",
                      transition: "background-color 0.3s ease", // Smooth hover effect
                      "&:hover": {
                        backgroundColor: "#052659", // Hover background color
                        color: "#ffff", // Hover text color
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </AppBar>

      {/* Modal for Log In */}
      <Modal
        open={openModel}
        onClose={() => setOpenModel(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        <Box sx={{
          width: { xs: 300, sm: 400, md: 600 },
          height: { xs: 400, sm: 400, md: 500 },
          backgroundImage: `url(${PopupImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: 2,
          p: -1,
          position: 'relative',
        }}>
          <CloseIcon
            sx={{
              position: 'absolute',
              top: { xs: -16, md: -24 },
              right: { xs: -16, md: -24 },
              cursor: 'pointer',
              color: '#ffff'
            }}
            onClick={() => setOpenModel(false)}
          />
          <Box sx={{ mt: { xs: 4, md: 8 }, textAlign: 'center' }}>
            <Link to="/login">
              <Button
                variant="contained"
                sx={{
                  background: 'linear-gradient(135deg, #5483B3, #052659)',
                  color: 'white',
                  borderRadius: 2,
                  px: { xs: 2, md: 4 },
                  py: { xs: 1.5, md: 2 },
                  fontWeight: 700,
                  fontSize: { xs: 16, md: 18 },
                  marginTop: { xs: 8, md: 8 },
                  transform: 'scale(0.90) translateY(-5px)',
                  '&:hover': {
                    background: 'linear-gradient(135deg, #052659, #5483B3)',
                    transform: 'scale(1) translateY(-5px)'
                  }
                }}
              >
                Learners Log in
              </Button>
            </Link>
            <Box sx={{ mt: { xs: 1, md: 2 } }}>
              <Link to="/companiessignin">
                <Button
                  variant="contained"
                  sx={{
                    background: 'linear-gradient(135deg, #5483B3, #052659)',
                    color: 'white',
                    borderRadius: 2,
                    px: { xs: 2, md: 4 },
                    py: { xs: 1.5, md: 2 },
                    fontWeight: 700,
                    fontSize: { xs: 16, md: 18 },
                    marginTop: { xs: 14, md: 18 },
                    transform: 'scale(0.90) translateY(-5px)',
                    '&:hover': {
                      background: 'linear-gradient(135deg, #052659, #5483B3)',
                      transform: 'scale(1) translateY(-5px)'
                    }
                  }}
                >
                  Organizations Log in
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Header;
import React, { useState, useEffect } from 'react'; // Import React hooks
import { Box, Grid, Paper } from "@mui/material";
import Coursesprogress from './coursesprogres';  // Import your components
import Projectsprogress from './ProjectsProgress';
import Learningpathprogress from './Learningpathprogress';
import Hoescoreprogress from './HOEScore';
import Getaccesstoallcourses from "../learningpath/hooks/getAccesstoallCourses";
import { LearnersSubscription } from "../../../helper/base_url";

const LearningPathProgressDisplay = ({ selectedOption, responseData }) => {

  const [learnerSubscribed, setLearnerSubscribed] = useState(false);

  useEffect(() => {
    const checkLearnerSubscription = async () => {
      try {
        const response = await fetch(`${LearnersSubscription}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const data = await response.json();
        if (data.status === 200 && data.learner) {
          setLearnerSubscribed(true); // Learner is subscribed
        } else {
          setLearnerSubscribed(false); // Learner is not subscribed
        }
      } catch (error) {
        console.error("Error checking learner subscription:", error);
        setLearnerSubscribed(false); // Default to not subscribed in case of error
      }
    };

    checkLearnerSubscription();
  }, []);

  if (!learnerSubscribed) {
    return (
      <Box sx={{ padding: "20px" }}>
        <Getaccesstoallcourses />
      </Box>
    );
  }

  return (
      <Box sx={{ padding: "20px", width: "100%" }}>
      <Grid container spacing={4}>
        {/* Courses Progress */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper 
            elevation={3} 
            sx={{
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              backgroundColor: "#f5f7fa",
            }}
          >
             {selectedOption && <Coursesprogress totalCoursesProgress={responseData.totalCoursesProgress} />}
          </Paper>
        </Grid>
        {/* Projects Progress */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper 
            elevation={3} 
            sx={{
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              backgroundColor: "#f5f7fa",
            }}
          >
             {selectedOption && <Projectsprogress totalProjectsProgress={responseData.totalProjectsProgress} />}
          </Paper>
        </Grid>
        {/* Learning Path Progress */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper 
            elevation={3} 
            sx={{
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              backgroundColor: "#f5f7fa",
            }}
          >
            {selectedOption && <Learningpathprogress learningPathProgress={responseData.learningPathProgress} />}
          </Paper>
        </Grid>
        {/* HOE Score */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper 
            elevation={3} 
            sx={{
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              backgroundColor: "#f5f7fa",
            }}
          >
             {selectedOption && <Hoescoreprogress HOE={responseData.HOE} />}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LearningPathProgressDisplay;
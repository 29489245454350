import React, { useState } from "react";
import {LearnersSinglelogin} from "../../../helper/base_url";

const Singlelogin = ({ videoUrl }) => {
  const [error, setError] = useState("");
  const token = localStorage.getItem("token");

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous error messages

    try {
      const response = await fetch(`${LearnersSinglelogin}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ videoUrl }),
      });

      if (response.ok) {
        const ssoUrl = await response.text(); // Get the Graphy SSO URL
        window.open(ssoUrl, '_blank'); // Open the SSO URL in a new tab
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div>
      <form onSubmit={handleLogin}>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div>
          <button
            type="submit"
            style={{
              background: "none",
              color: "blue",
              border: "none",
              marginTop: "-10px",
            }}
          >
            Resume
          </button>
        </div>
      </form>
    </div>
  );
};

export default Singlelogin;
import React, {useRef} from "react";
import { Link } from "react-router-dom";
import Internet from "../../assets/companiesimages/services/2.png";
import Industrial from "../../assets/companiesimages/services/3.png";
import IoT from "../../assets/companiesimages/services/4.png";
import Edge from "../../assets/companiesimages/services/5.png";
import Industry from "../../assets/companiesimages/services/6.png";
import AIoT from "../../assets/companiesimages/services/7.png";
//Technology
import Python from "../../assets/companiesimages/Technology/1.png";
import Arduion from "../../assets/companiesimages/Technology/2.png";
import Coap from "../../assets/companiesimages/Technology/3.png";
import Http from "../../assets/companiesimages/Technology/4.png";
import Mqtt from "../../assets/companiesimages/Technology/5.png";
import Javascript from "../../assets/companiesimages/Technology/6.png";
import Bluetooth from "../../assets/companiesimages/Technology/7.png";
import Wifi from "../../assets/companiesimages/Technology/8.png";
import Lorawan from "../../assets/companiesimages/Technology/9.png";
import Gps from "../../assets/companiesimages/Technology/10.png";
import Gsm from "../../assets/companiesimages/Technology/11.png";
import Aws from "../../assets/companiesimages/Technology/12.png";
import Azure from "../../assets/companiesimages/Technology/13.png";
import Hivemq from "../../assets/companiesimages/Technology/14.png";
import Blynk from "../../assets/companiesimages/Technology/15.png";
import Thingspeak from "../../assets/companiesimages/Technology/16.png";
import Thngsboard from "../../assets/companiesimages/Technology/17.png";
import N3uron from "../../assets/companiesimages/Technology/18.png";
import Flowfuse from "../../assets/companiesimages/Technology/19.png";
import Grafana from "../../assets/companiesimages/Technology/20.png";
import Kibana from "../../assets/companiesimages/Technology/21.png";
import Influxdb from "../../assets/companiesimages/Technology/22.png";
import Powerbi from "../../assets/companiesimages/Technology/23.png";
import Tableau from "../../assets/companiesimages/Technology/24.png";
import Raspberrypi from "../../assets/companiesimages/Technology/25.png";
import Espressif from "../../assets/companiesimages/Technology/26.png";
import Stm32 from "../../assets/companiesimages/Technology/27.png";
import Texas from "../../assets/companiesimages/Technology/28.png";
import Flutter from "../../assets/companiesimages/Technology/29.png";
import Flask from "../../assets/companiesimages/Technology/30.png";
import Mern from "../../assets/companiesimages/Technology/31.png";
import Thunkable from "../../assets/companiesimages/Technology/32.png";
import Nodered from "../../assets/companiesimages/Technology/33.png";
import Numpy from "../../assets/companiesimages/Technology/34.png";
import Kafka from "../../assets/companiesimages/Technology/35.png";
import Plotly from "../../assets/companiesimages/Technology/36.png";
import Learn from "../../assets/companiesimages/Technology/37.png";
import Pandas from "../../assets/companiesimages/Technology/38.png";
import Mongodb from "../../assets/companiesimages/Technology/39.png";
import Matpltlib from "../../assets/companiesimages/Technology/40.png";

//Partners
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import FlowFuses from "../../assets/companiesimages/Partners/2.png";
import N3urons from "../../assets/companiesimages/Partners/3.png";
import ThingsBoard from "../../assets/companiesimages/Partners/4.png";
import EdgeImpulse from "../../assets/companiesimages/Partners/5.png";
import Awses from "../../assets/companiesimages/Partners/6.png";
import Azures from "../../assets/companiesimages/Partners/7.png";
import Partners from "../../assets/companiesimages/Partners/1.jpg";

// import InternetofThings3 from "../../assets/companiesimages/InternetofThings3.jpg";
//import { faArrowRight,faStar} from "@fortawesome/free-solid-svg-icons";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin,FaYoutube, FaArrowUp } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import "./OrganizationBanner.css";

const InternetofThings = () => {
    const footerSectionRef = useRef(null);
  
  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

    return (
        <div className="students-flex companies-flex">
        <Headrepage />
        <div className="homepagecolores">
          <div className="homepagecolor">
          <div className="organization-iot-tech-talent-banners1">
            <div className="organization-iot-tech-talent-banner1">
              <h1>Driving Success with IoT and Tech Talent</h1>
              <p>Empowering Your Business with IoT Solutions and Skilled Tech Talent</p>
              <button className="organization-iot-tech-talent-banner1-btn">Learn More</button>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner2">
              <h1>Harness the Full Potential of IoT with Our Tailored Solutions</h1>
            <div className="organization-iot-tech-talent-banner2-cards">
            {/* <img src={InternetofThings3} alt="InternetofThings" className="organization-iot-tech-talent-banner2-card-image"/> */}
            <div className="organization-iot-tech-talent-banner2-card">
              <p>From developing smart devices to building integrated IoT
                systems, we deliver customized solutions that enable innovation,
                optimize processes, and drive growth. Let us help you harness the
                power of IoT to turn real-time data into actionable insights and
                accelerate your success in the connected world.
              </p>
              <button className="organization-iot-tech-talent-banner2-btn">Get IoT Solutions</button>
              <button className="organization-iot-tech-talent-banner2-btn">Hire IoT Tech Talent</button>
            </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner3">
                <h1>IoT professional services include</h1>
                <div className="organization-iot-tech-talent-banner3-card">
        <div className="animated-card">
          <img src={Internet} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Internet of Things</p>
        </div>
        <div className="animated-card">
          <img src={Industrial} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Industrial IoT</p>
        </div>
        <div className="animated-card">
          <img src={IoT} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>IoT Applications</p>
        </div>
      </div>
      <div className="organization-iot-tech-talent-banner3-card">
        <div className="animated-card">
          <img src={Edge} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Edge AI</p>
        </div>
        <div className="animated-card">
          <img src={Industry} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Industry 4.0</p>
        </div>
        <div className="animated-card">
          <img src={AIoT} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>AIoT</p>
        </div>
        </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner4">
        <h1 className="organization-iot-tech-talent-banner4-h1">How We Drive Your Success</h1>
        <p className="organization-iot-tech-talent-banner4-div1-p">
          We collaborate with your team to develop innovative IoT and AIoT
          solutions, driving transformation across industries with seamless
          integration of smart technologies. Our expertise in IoT, AIoT, and
          Industry 4.0 enables us to deliver customized systems and solutions
          that break new ground and create limitless possibilities. Together,
          we’ll revolutionize your business, accelerate growth, and ensure you
          lead in the connected world.
        </p>
      <div className="organization-iot-tech-talent-banner4-card">
        <div className="organization-iot-tech-talent-banner4-card-1">
          <p>POC of IoT based Solution</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-2">
          <p>MVP of IoT based Product</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-3">
          <p>Custom Product Development Research</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-4">
          <p>IoT Data Solutions</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-5">
          <p>Hire Tech Talent</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-6">
          <p>Industrial IoT Solutions</p>
        </div>
      </div>
    </div>
          {/* iot */}
          <div className="organization-iot-tech-talent-banner5">
            <h1>Our Technology Expertise Framework</h1>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Programming and Protocols</h2>
              <img src={Python} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Arduion} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Coap} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Http} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Mqtt} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Javascript} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Bluetooth} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Wifi} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Lorawan} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Gps} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Gsm} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Cloud Platforms</h2>
              <img src={Aws} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Azure} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Hivemq} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Blynk} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Thingspeak} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Thngsboard} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={N3uron} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Flowfuse} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Data Analytics and Monitoring</h2>
              <img src={Grafana} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Kibana} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Influxdb} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Powerbi} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Tableau} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>IoT Hardware</h2>
              <img src={Raspberrypi} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Espressif} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Stm32} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Texas} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>IoT App Development</h2>
              <img src={Flutter} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Flask} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Mern} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Thunkable} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Nodered} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Data Science, AI, ML</h2>
              <img src={Numpy} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Kafka} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Plotly} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Learn} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Pandas} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Mongodb} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Matpltlib} alt="" className="organization-iot-tech-talent-banner5-logos"/>
            </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner6">
            <h1>Streamlined Process for IoT Solutions and Tech Talent Engagement</h1>
            <p>Achieve your IoT vision with our end-to-end workflow, seamlessly
              integrating every component—from sensors to data repositories.
              From development to deployment, we ensure flawless execution
              and lasting impact for your business.</p>
          <div className="organization-iot-tech-talent-banner6-steps">
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Discovery and Ideation</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step1</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Design and Prototyping</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step2</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Development and Integration</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step3</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Testing and Validation</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step4</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Deployment, Monitoring and Feedback</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step5</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Hire Tech Talent</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step6</div>
          </div>
        </div>
        </div>
          <div className="organization-iot-tech-talent-banner7">
            <h1 className="organization-iot-tech-talent-banner7-h1">Take the Next Step</h1>
            <p className="organization-iot-tech-talent-banner7-p">Whether you're looking to hire expert tech talent or need tailored
            data solutions, we're here to help!</p>
            <div className="organization-iot-tech-talent-banner7-contact">
              <h1>Contact Us</h1>
              <p>Schedule a meeting to discuss your needs</p>
            </div>
            <div className="organization-iot-tech-talent-banner7-btn">
              <div className="organization-iot-tech-talent-banner7-btn1">
              <button>Post Your Project</button>
              <p>Click here to share your requirements</p>
              </div>
              <div className="organization-iot-tech-talent-banner7-btn1">
                <button>Hire Tech Talent</button>
                <p>Click Here to find the perfect fit for your team</p>
              </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner8">
             <p className="organization-iot-tech-talent-banner8-p"><FontAwesomeIcon icon={faHandshake} /> Partners</p>
            <div className="organization-iot-tech-talent-banner8-1">
              <div>
            <div className="organization-iot-tech-talent-banner8-cards">
            <div>
              <img src={FlowFuses} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            <div>
            <img src={N3urons} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner8-cards">
            <div>
            <img src={ThingsBoard} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            <div>
            <img src={EdgeImpulse} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner8-cards">
            <div>
            <img src={Awses} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            <div>
            <img src={Azures} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            </div>
            </div>
            <img src={Partners} alt="" className="organization-iot-tech-talent-banner8-cards-Partners"/>
            </div>
          </div>
          <div className="homepage-footers-images">
          <Footer />
          <footer className="homepage-footers">
          <hr className='homepage-footer-hr'/>
          <div className="footer-social">
          <div className="homepage-footer-copyright">
            <p>© Huebits[2025].All Rights Reserved.</p>
          </div>
          <div className='footer-social-icons'>
              <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
              <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
              <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
              <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
              <Link><FaFacebook className="social-icon" /></Link>
              <Link onClick={scrollToFooterSection}>
              <FaArrowUp className="homepage-social-icon-link" />
              </Link>
          </div>
          </div>
        </footer>
      </div>
    </div>
    </div>
    )
}


export default InternetofThings;
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Project1 from "../../../assets/New folder/7.png";
import Project2 from "../../../assets/New folder/10.png";
import Project3 from "../../../assets/New folder/6.png";
import Project4 from "../../../assets/dashboard-images/images/images/projects/29.png";
import Project5 from "../../../assets/dashboard-images/images/images/projects/32.png";
import Project6 from "../../../assets/dashboard-images/images/images/project19.jpg";
import Project7 from "../../../assets/dashboard-images/images/images/projects/34.png";
import Project8 from "../../../assets/dashboard-images/images/images/projects/6.png";
import Project9 from "../../../assets/dashboard-images/images/images/projects/24.png";
import "./projects.css";

const Slide = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: <button className="slick-prev"><i className="fas fa-chevron-left"></i></button>,
        nextArrow: <button className="slick-next"><i className="fas fa-chevron-right"></i></button>,
        responsive: [
            {
                breakpoint: 1024, 
                settings: {
                    slidesToShow: 2, 
                }
            },
            {
                breakpoint: 768, 
                settings: {
                    slidesToShow: 2, 
                }
            },
            {
                breakpoint: 480, 
                settings: {
                    slidesToShow: 1, 
                }
            }
        ]
    };

    return (
        <div className="smartcity-slider-container">
        <Slider {...settings}>
            <div className="iot-smartcity-slide-item">
                <img className="SmartCity" src={Project1} alt="Smart City"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Smart City</h1>
                    <p className="IoTUniverse3-card-title-paths-p">
                        Implementing an ESP32-based IoT solution for Smart Cities, 
                        integrating sensors and actuators for data collection and 
                        urban management.
                    </p>
                </div>
            </div>
            <div className="iot-smartcity-slide-item">
                <img className="SmartCity" src={Project2} alt="Smart Street Lighting System"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Smart Street Lighting System</h1>
                    <p className="IoTUniverse3-card-title-paths-p">
                        Developing ESP32 Arduino-based smart street lighting system 
                        for energy-efficient, automated illumination, enhancing 
                        urban safety and sustainability
                    </p>
                </div>
            </div>
            <div className="iot-smartcity-slide-item">
                <img className="SmartCity" src={Project3} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Smart Manufacturing</h1>
                    <p className="IoTUniverse3-card-title-paths-p">
                        Creating Raspberry Pi-based smart manufacturing 
                        system with Python for data analytics, process 
                        automation, and real-time monitoring in factories
                    </p>
                </div>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/MQTT-based-Environment-Monitoring-in-factories-for-Employee-Safety--Raspberry-Pi--66e82e835f6cf505f4d50eb3" >
                <img className="SmartCity" src={Project4} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">MQTT based Environment Monitoring in factories for Employee Safety (Raspberry Pi)</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Smart-Agriculture-using-Internet-of-Things-and-MQTT-Protocol--Raspberry-Pi--66e82df6153e7d6569f24c18">
                <img className="SmartCity" src={Project5} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Smart Agriculture using Internet of Things and MQTT Protocol (Raspberry Pi)</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Smart-Air-Quality-Monitoring-using-ESP32-660e8b75547d1953124c3fb5">
                <img className="SmartCity" src={Project6} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Smart Air Quality Monitoring using ESP32 and Node-Red</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Smart-Light-Bulb-Controlling-on-Raspberry-Pi-Using-Flask-Web-App-66e82ff2db4d465ff95057c3">
                <img className="SmartCity" src={Project7} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Smart Light Bulb Controlling on Raspberry Pi Using Flask Web App</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Developing-Smart-Workspace-Based-IOT-with-Artificial-intelligence-and-using-Telegram-CHATBOT--Raspberry-Pi--66e82f3e688b0e0b4daf3367">
                <img className="SmartCity" src={Project8} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Developing Smart Workspace Based IOT with Artificial intelligence and using Telegram CHATBOT</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Machine-Health-Monitoring-Using-Power-BI--66e8363468f4bf5bfc36162a">
                <img className="SmartCity" src={Project9} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Machine Health Monitoring Using Power BI</h1>
                </div>
            </Link>
            </div>
        </Slider>
        </div>
    );
};

export default Slide;

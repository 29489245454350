import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Box, Button, Typography, Container, Paper, CircularProgress } from "@mui/material";
import Congratulations from "../../assets/congratulations.png";
import OrganizationsHeader from "../PopupOrganizations/topbar";

const PostprojectsUploads = () => {
    const [nextPageLoading, setNextPageLoading] = useState(true);
  
    useEffect(() => {
        // Simulate loading process, change this logic as per actual data fetching
        const timer = setTimeout(() => {
          setNextPageLoading(false);
        }, 2000); // Assuming data loads in 2 seconds for demonstration
    
        return () => clearTimeout(timer); // Cleanup on component unmount
      }, []);

  return (
    <div className="students-flex">
      <OrganizationsHeader />
      <div className="signup-pages-flex">
      {nextPageLoading ? (
        // Display loading screen if next page is loading
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "91vh",
            background: "#f5f5f5",
          }}
        >
          <CircularProgress size={60} />
          <Typography sx={{ marginLeft: 2, fontSize: 18 }}>Loading...</Typography>
        </Box>
       ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%)",
        padding: "20px",
        height: "91vh"
        }} >
      <Box sx={{marginTop: 0}}>
        <Container maxWidth="sm">
        <Paper elevation={4} sx={{ p: 4, borderRadius: 2, textAlign: "center"}} >
          <img src={Congratulations} alt="Congratulations" style={{ width: '100%', maxWidth: '200px', height:'150px', marginBottom: '20px' }} />
          <Typography variant="h4" sx={{ fontWeight: 700 }}>Congratulations!</Typography>
          <Typography variant="h5" sx={{ fontWeight: 700, marginTop: '10px' }}>Your project is received</Typography>
          <Typography variant="body1" sx={{ marginTop: '20px', color: 'text.secondary' }}>
            Expect further instructions via email, including a meeting to discuss technical details.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 3 }}>
          <Button
            component={Link}
            to="/submitproject"
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            sx={{
              margin: 0.5,
              color: "#052659", // Ensures text is readable
              "& .MuiChip-deleteIcon": {
                color: "#052659",
              },
            }}
            >
             Back
        </Button>
        <Button
          component={Link}
          to="/compainesdashboard"
          variant="contained"
          endIcon={<FontAwesomeIcon icon={faArrowRight} />}
          sx={{
            margin: 0.5,
            backgroundColor: "#043885",
            "&:hover": {
              backgroundColor: "#052659",
            },
          }}
        >
          VIEW MY DASHBOARD
        </Button>
        </Box>
          </Paper>
        </Container>
        </Box>
    </div>
       )}
    </div>
    </div>
  );
};

export default PostprojectsUploads;
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Box, Typography, TextField, Button, LinearProgress, Paper, Container } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import OrganizationsHeader from "../PopupOrganizations/topbar";

const Postproject5 = () => {
  const navigate = useNavigate();
  const [completionPercentage, setCompletionPercentage] = useState(70);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    let Projects = {
      type: document.getElementById("type").value,
      Type: document.getElementById("Type").value,
    };
  
    //console.log("Data being sent:", Projects); // Log the data being sent
  
    // Save the projects data to localStorage
    localStorage.setItem('projectData', JSON.stringify(Projects));

    // Simulate a delay (like a network request)
    setCompletionPercentage((prev) => prev + 20);
    setTimeout(() => {
      toast.success("Data submitted successfully!", {
        position: "top-center",
        autoClose: 3000,
      });
      setLoading(false);
      navigate("/submitproject");
    }, 1000);
  };  

  return (
    <div className="students-flex">
      <OrganizationsHeader />
      <div className="signup-pages-flex">
         <Box
            sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%)",
            padding: "10px",
            height: { xs: "auto", sm: "100vh", md: "100vh" },
            }}
            >
          <ToastContainer position="top-center" reverseOrder={false} />
          <Box sx={{ paddingTop: { xs: 0, sm: 0 } }}><Container maxWidth="sm">
              <Paper elevation={2} sx={{ borderRadius: 2, textAlign: "center", padding: "10px 20px 30px 20px" }}>
                <Typography variant="h4" style={{ color: "#052659" }}>
                  When do you need the project completed?
                </Typography>
                <Typography color="textSecondary">
                  Tell us about the timeframe and budget for the project.
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    id="type"
                    label="Expected Timeframe (No. of Days)"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 3, marginTop: "20px" }}
                    required
                  />
                  <TextField
                    id="Type"
                    label="Budget (₹)"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 3 }}
                    required
                  />
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 3 }}>
                    <Button
                      component={Link}
                      to="/selectlanguages"
                      variant="outlined"
                      startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                      disabled={loading}
                      sx={{
                        margin: 0.5,
                        color: "#052659",
                        "& .MuiChip-deleteIcon": {
                          color: "#052659",
                        },
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                      sx={{
                        margin: 0.5,
                        backgroundColor: "#043885",
                        "&:hover": {
                          backgroundColor: "#052659",
                        },
                      }}
                    >
                      {loading ? "Submitting..." : "Next"}
                    </Button>
                  </Box>
                </form>
                <Box sx={{ marginTop: 4 }}>
                  <Typography variant="body2" gutterBottom>
                    Step 5 of 6
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={completionPercentage}
                    sx={{
                      borderRadius: 1,
                      height: 10,
                      backgroundColor: "#f5f5f5",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "green",
                      },
                    }}
                  />
                </Box>
                <Typography variant="body2" align="right" sx={{ mt: 1, fontWeight: 600 }}>
                  {completionPercentage}%
                </Typography>
                <Box sx={{ textAlign: "center", mt: 3, color: "text.secondary" }}>
                  <Typography variant="caption"> &copy; 2024 Huebits All Rights Reserved </Typography>
                  <br />
                  <Typography variant="caption">Terms of Business</Typography>
                </Box>
              </Paper>
            </Container>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Postproject5;
import React from "react";
import LearingPathList from "./learningPathList";
//import DashboardLiast from "./dashboardLiast";


const Dashboard = () => {
  return (
    <div className="mylearningpaths">
    <div>
      <LearingPathList />
    </div>
    </div>
  );
}

export default Dashboard;
import React,{useState} from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import addActiveProject from "../hooks/AddactiveProjects";
import useLearnerStatus from '../hooks/LlearnersSubscription';  // Import the custom hook
import useLearningProgress from '../hooks/projectslearningPath';
import { IoTdeveloperProjects } from "../hooks/projectsList"; // Import the courses data


const Start = () => {
  const [, setMessage] = useState(null);
  const learnerFound = useLearnerStatus();  // Use the custom hook for learner status
  const learningProgress = useLearningProgress(); // Use the custom hook for learning progress


  return (
    <div>
      <p className="mylearningpaths-p">Projects</p>
      <div className="mylearningpath">
        <div className="Wrapper-sc-fvyqwl-1 iZEyIQ AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
          <ul className="Circle-sc-fvyqwl-1">
               {IoTdeveloperProjects.map((project, index) => {
                const courseProgress = learningProgress.find(
                  (progress) => progress.productId === project.videoUrl
                );
                const progress = courseProgress ? courseProgress.productProgress : 0;

                return (
                  <li key={`${project.projectId}-${index}`}>
                    <div className="learningpath-courses-dashboard">
                      <div>
                        <img src={project.image} alt={project.projectTitle} className="smart-shoe-development" />
                      </div>
                      <div>
                        <p className={`pythoncourses${project.projectId}`} style={{ paddingTop: "10px" }}>
                          {index + 1}. {project.projectTitle}
                        </p>
                        <div className="learningpath-courses-dashboard-progressbar">
                          <p className="learningpath-courses-dashboard-skills-completed">{Math.min(progress || 0, 100).toFixed(0)}%</p>
                          <ProgressBar now={progress} className={`custom-progress-bar`} />
                        </div>
                        <div>
                        {progress === 100 ? (
                          <button className="learningpath-courses-dashboard-button-start">Completed</button>
                          ) : progress >= 100 ? (
                              <button onClick={() => addActiveProject(project.projectTitle, project.videoUrl, setMessage)} className="learningpath-courses-dashboard-button-start">Completed</button>
                          ) : progress >= 1 && progress <= 99 ? (
                              <button onClick={() => addActiveProject(project.projectTitle, project.videoUrl, setMessage)} className="learningpath-courses-dashboard-button-start">
                                  Continue Learning
                              </button>
                          ) : (
                              <>
                                  {learnerFound === false ? (
                                      <button onClick={() => addActiveProject(project.projectTitle, project.videoUrl, setMessage)} className="learningpath-courses-dashboard-button-start">
                                          Subscribe
                                      </button>
                                  ) : (
                                      <button onClick={() => addActiveProject(project.projectTitle, project.videoUrl, setMessage)} className="learningpath-courses-dashboard-button-start">
                                          Start
                                      </button>
                                  )}
                              </>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
        </div>
      </div>
    </div>
);
};

export default Start;
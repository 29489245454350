import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { TextField, Button, IconButton, Card, Box, Typography } from '@mui/material';
import Headrepage from "../../../mainpage/Headerpages/learners_hederpage";
import Footer from "../../../mainpage/Footer/Footer";
import { LearnersGenerateOtp, LearnersVerifyOtp, LearnersForgotPassword } from '../../../helper/base_url';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setOTP] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [timer, setTimer] = useState(null);
  const [otpVerified, setOtpVerified] = useState(false);  // Track OTP verification status
  const navigate = useNavigate();

  const startTimer = () => {
    setTimeLeft(60);
    setTimer(setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          setOTP('');
        }
        return prevTime - 1;
      });
    }, 1000));
  };

  useEffect(() => {
    if (timeLeft === 0) {
      clearInterval(timer);
    }
  }, [timeLeft, timer]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post(`${LearnersGenerateOtp}`, { email });
      setOTP(data.code);
      toast.success(data.message);
      startTimer();
    } catch (error) {
      toast.error("Failed to send OTP.");
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      const { data } = await axios.post(`${LearnersVerifyOtp}`, { email, code });
      if (data.success) {
        toast.success('OTP verified successfully!');
        setOtpVerified(true);  // Set OTP as verified
        clearInterval(timer);
        setTimeLeft(0);
        setOTP('');
        return true;
      }
      toast.error('Invalid OTP');
      return false;
    } catch {
      toast.error('Verification failed.');
    }
  };

  const resetPassword = async () => {
    if (newPassword !== confirmPassword || newPassword.length < 8) {
      toast.error("Password validation failed.");
      return;
    }
    try {
      const { data } = await axios.post(`${LearnersForgotPassword}`, { email, newPassword });
      if (data.success) {
        toast.success('Password reset successful!');
        setTimeout(() => navigate("/login"), 1500);
      } else {
        toast.error(data.message || 'Reset failed.');
      }
    } catch (error) {
      //console.error(error);  // Log error for debugging
      toast.error(`Error: ${error.response?.data?.message || 'Error resetting password.'}`);
    }
  };

  return (
    <div className="students-flex">
      <Headrepage />
      <div className="signup-pages-flex">
        <div className="studentssign-cssimagesflex-color" id="studentssign-cssimagesflex-color">
          <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: 'auto' }}>
            <Card sx={{ width: '100%', maxWidth: 500, padding: 2 }}>
              <ToastContainer position="top-center" />
              <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 2 , color: '#043885'}}>Forgot Password</Typography>
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex',  gap: 2 }}>
                  <TextField label="Enter E-Mail" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} required/>
                  <Button type="submit" variant="contained" fullWidth disabled={loading} 
                   sx={{
                    background: "linear-gradient(135deg, #5483B3, #052659)",
                    "&:hover": {
                      background: "linear-gradient(135deg,  #052659, #5483B3 )",
                    },
                    width: 150}}>
                    {loading ? "Sending OTP..." : "Send OTP"}
                  </Button>
                </Box>
              </form>
              {otpVerified ? (
                // OTP Verified: Do not show OTP input field
                <>
                  <div style={{ position: "relative" }}>
                    <TextField type={showPassword ? "text" : "password"} fullWidth value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" required sx={{ marginTop: 2 }} />
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                      className="toggle-password-icon"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {showPassword ? "👁" : "🔒"}
                    </IconButton>
                  </div>
                  <TextField type="password" fullWidth value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" sx={{ marginTop: 2 }} />
                  <Button variant="contained" fullWidth onClick={resetPassword} disabled={loading}
                  sx={{ marginTop: 2, background: "linear-gradient(135deg, #5483B3, #052659)",
                      "&:hover": {
                        background: "linear-gradient(135deg,  #052659, #5483B3 )",
                      },}}>
                    {loading ? 'Resetting...' : 'Reset Password'}
                  </Button>
                </>
              ) : (
                // OTP not verified: Show OTP input field
                <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
                  <TextField fullWidth value={code} onChange={(e) => setOTP(e.target.value)} placeholder="Enter OTP" required/>
                  <Button onClick={verifyOTP} variant="contained" 
                  sx={{ width: 160, background: "linear-gradient(135deg, #5483B3, #052659)",
                     "&:hover": {
                      background: "linear-gradient(135deg, #052659, #5483B3 )",
                     },
                  }}>Verify OTP</Button>
                  {timeLeft > 0 && <Typography variant="body2" sx={{ color: 'red', marginTop: 1 }}>Time left: {timeLeft}s</Typography>}
                </Box>
              )}
            </Card>
          </Box>
        </div>
        <div className="homepage-footers-images">
          <Footer />
          <footer className="homepage-footers">
            <hr className='homepage-footer-hr'/>
            <div className="footer-social">
              <div className="homepage-footer-copyright">
                <p>© Huebits[2025].All Rights Reserved.</p>
              </div>
              <div className='footer-social-icons'>
                <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

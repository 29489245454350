import React, {useRef} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faArrowRight  } from "@fortawesome/free-solid-svg-icons";
import product from "../../assets/Thirdmana4.png";
import customers1 from "../../assets/customers1.png";
import customers2 from "../../assets/customers2.png";
import customers3 from "../../assets/customers3.jpg";
import customers4 from "../../assets/customers4.png";
import {Companieslearningpaths,Companieslearning} from "./Organizationslearningpaths";
import {Customersreviews} from "./OrganizationsReviews";
import { BasketSimpleIcon, MeetingIcon, ShareIcon, WebDesignIcon, ImageComponent } from "./icons";
import Background from "./Background";
import { FaTwitter, FaInstagram,FaLinkedin,FaYoutube, FaArrowUp } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import postInternships from "../../assets/Post Internships.jpg";
import hireFreshers from "../../assets/Hire Freshers.jpg";
import Reveal from '../../helper/useScrollposition';
import "./organizations.css";


const Compaines = () => {
  const compainesSectionRef = useRef(null);
  const hireTechtalentRef = useRef(null);
  const footerSectionRef = useRef(null);

  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToCompainesSection = () => {
    compainesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollTohireTechtalent = () => {
    hireTechtalentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

    return (
      <div className="students-flex companies-flex">
      <Headrepage />
        <div className="homepagecolores">
        <div className="aboutus-1">
        <Reveal>
        <div className="homepagecolor">
            <div ref={footerSectionRef} className="getstarted-flex companies-getstarted-flex-1">
            <ul>
                <li><h1 className="brilliant">Accelerating Innovation With <span className="span1">Next-Gen Tech Talent <span className="span2"> and</span> Transformative Solutions for Industries</span></h1>
                <p className="spansexports">Empowering Innovation, Bridging Talent, and Driving Business Excellence.</p></li>
                <div className="buttonget-companies">
                <button className="compaines-buttonget-1" onClick={scrollToCompainesSection}>Post Project
                <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                </button>
                <button className="compaines-buttonget-1 link" onClick={scrollTohireTechtalent}>Hire Tech Talent
                <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                </button>
                </div>
              </ul>
            <div className="huebitsimgdiv">
              <img className="Thirdman" src={product} alt="" />
            </div>
            </div>
            </div>
            </Reveal>
            <div className="customersbuild">
            <Reveal>
              <h1 className="customersbuild-h1">Some Of Our Happy Customers</h1>
              <div className="customersbuild-imgs">
              <img className="customers-imgs" src={customers1} alt="" />
              <img className="customers-imges" src={customers2} alt="" />
              <img className="customers-imgs" src={customers3} alt="" />
              <img className="customers-imgs" src={customers4} alt="" />
              </div>
            </Reveal>
            </div>
            <div className="companies-page-center">
            <Reveal>
              <h1 className="companiess">Industry Solutions: From Problem To Prototype</h1>
              <p className="companiesspan">We offer solutions in the following domains</p>
            </Reveal>
              <Companieslearningpaths />
              </div>
              <div className="solutions-for-Companies">
            <Reveal>
              <h1 className="solutions-for-Companies-companiess">Explore The Benefits</h1>
                <Companieslearning />
                </Reveal>
            </div>
            <div className="HowitWorks1">
            <Reveal>
              <h1 className="howitworks-1">How It Works</h1>
            </Reveal>
            <Reveal>
              <div className="HowitWorks">
              <div className="sharpost-1">
              <p className="ShareIcon"><ShareIcon /></p>
              <p><span className="spans-1">Submit Your Project</span></p>
              </div>
              <div className="meetingiconsexperts">
              <p className="MeetingIcon"><MeetingIcon /></p>
              <p className="executionplan"><span className="spans-1">Get Feasibility Report</span></p>
              </div>
              <div className="webdesignicons">
              <p className="WebDesignIcon"><WebDesignIcon /></p>
                <p className="timeline"><span className="spans-1">Project Execution</span></p>
              </div>
              <div className="basketsimapleicons">
              <p className="BasketSimpleIcon"><BasketSimpleIcon /></p>
              <p className="theirportfolios"><span className="spans-1">Project Delivery</span></p>
              </div>
              </div>
              <div className="brz-img-1-container">
            <ImageComponent />
            </div>
            </Reveal>
            </div>
            <div>
            <div ref={compainesSectionRef} className="Choose-Services">
            <Reveal>
              <div className="Choose-Service">
              <h1 className="Choose">Choose Service</h1>
              </div>
              <div className="Choose-Serviceses">
                  <p>
                  <div className="inr2-3-lakh-flex">
                  <div className="inr2-3-box">
                  <div className="companies-inr2-3">
                    <div className="Productsp">
                    <h1 className="companies-products-1">Rapid POC & Product Development</h1>
                    </div>
                    <div className="flex-Internet-of-Things">
                      <div className="Internet-of-Things-1">
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                         Internet of Things</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Embedded Systems</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Data Science</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Web Application Development</p>
                        </div>
                        <div className="btn-post-a-projects-links-div1">
                        <Link to="/organization"><button className="btn-post-projects-link-manth">Post Project
                        <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                        </button></Link>
                        </div>
                    </div>
                    </div>
                    </div>
                    <div className="inr2-4-box">
                    <div className="companies-inr2-4">
                    <div className="Productsp">
                    <h1  className="companies-products-1">Expert Research Consultancy</h1>
                    </div>
                    <div className="flex-Internet-of-Things">
                      <div className="Internet-of-Things-1">
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Data Science </p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Digital Marketing</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Content Development</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Business Intelligence</p>
                        </div>
                        <div className="btn-post-a-projects-links-div1">
                        <Link to="/organization"><button className="btn-post-projects-link">Post Project
                        <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                        </button></Link>
                        </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  </p>
              </div>
              </Reveal>
            </div>
            <div className="compaines-hiretechtalent1" ref={hireTechtalentRef}>
            <div className="compaines-hiretechtalent1-container">
            <h1 className="compaines-hiretechtalent1-h1">Hire Tech Talent</h1>
            <p className="compaines-hiretechtalent1-p">
              Accelerate your business growth with next-generation innovators at Huebits.
              <span className="highlight-text"> Trusted by industry leaders, we specialize in providing top-tier talent that drives transformative success.</span>
              <span className="highlight-text"> Explore your ideal candidate from our carefully curated pool of exceptional professionals, equipped with the skills to elevate your business to new heights.</span>
            </p>
          </div>
          <div className="compaines-hiretechtalent1-containers">
              <div className="compaines-hiretechtalent1-container1">
              <div className="compaines-hiretechtalent1-container2">
                <div className="compaines-hiretechtalent1-container1-cord">
                 <h1 className="compaines-hiretechtalent1-container1-h1">Post Internships</h1>
                <p className="compaines-hiretechtalent1-container1-p">With Huebits, tap into our vast talent pool of agile, adaptable, and innovative interns 
                  from over 100 prestigious colleges and universities. 
                  Each intern comes with a Hands-on Experience Score (HOE), 
                  ensuring they are not only skilled but also ready to contribute to your organization's growth and success from day one.
                </p>
                </div>
                <img src={postInternships} alt="Post Internships" className="compaines-hiretechtalent1-container1-image"/>
                </div>
              </div>
              <div className="compaines-hiretechtalent1-containers1">
                <div  className="compaines-hiretechtalent1-container2 compaines-hiretechtalent1-containers1">
                <img src={hireFreshers} alt="Post Internships" className="compaines-hiretechtalent1-container1-image1"/>
                <div  className="compaines-hiretechtalent1-container1-cord">
                <h1 className="compaines-hiretechtalent1-container1-h1">Hire Freshers</h1>
                <p className="compaines-hiretechtalent1-container1-p">Huebits is your go-to solution for efficiently hiring fresh, 
                  next-generation innovative talent from our extensive talent pool. 
                  With Huebits' Fresher Hiring Solutions, you gain access to top-notch 
                  candidates who bring a Hands-on Experience Score (HOE) and industrial experience, 
                  ensuring they are well-equipped to drive your organization's success from day one
                </p>
                </div>
              </div>
               <img src={hireFreshers} alt="Post Internships" className="compaines-hiretechtalent1-container1-image-phone"/>
              </div>
            </div>
            <div>
            <h1 className="compaines-hiretechtalent1-h1">Contact Sales</h1>
              <p className="compaines-hiretechtalent1-p">
                  <a 
                      href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@huebits.in" 
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      target="_blank"
                      rel="noopener noreferrer" >
                      sales@huebits.in
                  </a>
              </p>
            </div>
            </div>
            <div className="compaines-creativeproducts-reviews">
                <Reveal>
                  <Customersreviews />
                </Reveal>
            </div>
            </div>
            <Reveal>
         <div className="backgroundimage-1">
          <Background />
        </div>
        </Reveal>
        <div className="homepage-footers-images">
        <Footer />
        <footer className="homepage-footers">
        <hr className='homepage-footer-hr'/>
        <div className="footer-social">
        <div className="homepage-footer-copyright">
          <p>© Huebits[2025].All Rights Reserved.</p>
        </div>
        <div className='footer-social-icons'>
          <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
          <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
          <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
          <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
          {/* <Link><FaFacebook className="social-icon" /></Link> */}
          <Link onClick={scrollToFooterSection}>
          <FaArrowUp className="homepage-social-icon-link" />
          </Link>
        </div>
        </div>
        </footer>
      </div>
      </div>
    </div>
    </div>
    )
}


export default Compaines;
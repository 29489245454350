import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemButton, ListItemText, CircularProgress, Box } from "@mui/material";

const LearnersHeader = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [loading, setLoading] = useState(false);

  // Check if user is logged in by checking only the email in localStorage
  const learnersLoggedIn = localStorage.getItem("token") !== null;

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  const handleLoginClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const menuItems = [
    {
      label: "Learners",
      path: "/learners",
    },
    {
      label: learnersLoggedIn ? "Dashboard" : "Log in",
      path: learnersLoggedIn ? "/dashboardpage" : "/login",
      action: learnersLoggedIn ? closeMenu : handleLoginClick,
    },
  ];

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "#ffff", // Modern dark blue background
        boxShadow: "none"
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 2, sm: 4, md: 6 },
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
        }}
      >
        {/* Brand Name */}
        <Typography
          variant="h1"
          sx={{
            fontSize: "40px",
            fontWeight: "700",
            color: "rgb(2, 41, 100)",
            textDecoration: "none",
          }}
          component={Link}
          to="/"
        >
          Hue<span style={{ color: "#1c84e4" }}>bits</span>
        </Typography>

        {/* Responsive Menu Button */}
        <IconButton
          edge="end"
          onClick={toggleMenu}
          sx={{
            color: "rgb(2, 41, 100)",
            display: { xs: "block", md: "none" }, // Show only on mobile/tablets
          }}
        >
          <MenuOutlinedIcon fontSize="large" />
        </IconButton>

        {/* Desktop Menu */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            gap: 3,
          }}
        >
          {menuItems.map((item, index) => (
            <Link
            key={index}
            to={item.path}
            onClick={item.action}
            style={{ textDecoration: "none" }}
          >
            <Box
              sx={{
                color: "#052659",
                fontWeight: 700,
                fontSize: "20px",
                padding: "8px 16px",
                borderRadius: "10px",
                transition: "background-color 0.3s ease", // Smooth hover effect
                "&:hover": {
                  backgroundColor: "#052659", // Hover background color
                  color: "#ffff", // Hover text color
                },
              }}
            >
              {loading && item.label === "Log in" ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                item.label
              )}
            </Box>
          </Link>          
          ))}
        </Box>
      </Toolbar>

      {/* Drawer for Mobile/Tablet Menu */}
      <Drawer
        anchor="left"
        open={menuActive}
        onClose={closeMenu}
        sx={{
          "& .MuiDrawer-paper": {
            bgcolor: "#ffff",
            color: "white",
            width: 250,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={closeMenu}
          sx={{
            color: "#052659",
            fontWeight: 700,
            fontSize: "1rem",
            padding: "4px 16px",
            borderRadius: "4px",
            transition: "background-color 0.3s ease", // Smooth hover effect
            "&:hover": {
              backgroundColor: "#052659", // Hover background color
              color: "#ffff", // Hover text color
            },
          }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                component={Link}
                to={item.path}
                onClick={item.action}
              >
                <ListItemText
                  primary={
                    loading && item.label === "Log in" ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      item.label
                    )
                  }
                  sx={{
                    color: "#052659",
                    fontWeight: 700,
                    padding: "8px 16px",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease", // Smooth hover effect
                    "&:hover": {
                      backgroundColor: "#052659", // Hover background color
                      color: "#ffff", // Hover text color
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default LearnersHeader;

import React, { useState, useCallback, useEffect } from "react";
import axios from 'axios';
import avatar from '../../assets/profile.png';
import convertToBase64 from '../../helper/Convert';
import { ToastContainer, toast } from 'react-toastify';
import { OrganizationsCompaniesprofile } from "../../helper/base_url";
import { TextField, Button, Box, Typography, CircularProgress } from "@mui/material";

const CompaniesProfile = () => {
    const [loading, setLoading] = useState(false);
     const [showToast, setShowToast] = useState(false);
    const [, setFile] = useState(null);
    const [data, setData] = useState({
      companyName: "",
      name: "",
      mobile: "",
      profilePic : "",
    });

    const token = localStorage.getItem("company_token");

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    const onUpload = async (e) => {
        const base64 = await convertToBase64(e.target.files[0]);
        setFile(base64);
        setData((prevData) => ({
          ...prevData,
          profilePic: base64 
        }));
    };

    const handleUserProfile = useCallback(async (updatedData) => {
      try {
        const { data: response } = await axios.put(
          `${OrganizationsCompaniesprofile}`,
          updatedData,
          {
            headers: {
              "content-type": "application/json",
              authorization: `jwt ${token}`,
            },
          }
        );
        response.success
          ? toast.success(response.message)
          : toast.error(response.message);
          setShowToast(true);
        setData(response.data || {});
      } catch {
        toast.error("Network error. Try again.");
        setShowToast(true);
      } finally {
        setLoading(false);
      }
    }, [token]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      await handleUserProfile(data);
    };
  
    useEffect(() => {
      if (token) handleUserProfile();
    }, [token, handleUserProfile]);

    return (
        <Box sx={{ 
            width: { xs: "300px", sm: "600px", md: "600px" },
            padding: 2, 
            margin: '0 auto', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' 
        }}>
        {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
            {/* <Typography variant="h4" gutterBottom>Companies Profile</Typography> */}
            <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <label htmlFor="profile">
                        <img 
                            src={data.profilePic ? data.profilePic : avatar} 
                            alt="Profile" 
                            style={{ width: 100, height: 100, borderRadius: '50%' }} 
                        />
                    </label>
                    <input onChange={onUpload} type="file" id="profile" name="profile" style={{ display: 'none' }} />
                </Box>
                <TextField 
                    label="Company Name" 
                    variant="outlined" 
                    name="companyName" 
                    value={data.companyName} 
                    onChange={handleOnChange} 
                    fullWidth 
                />
                <TextField 
                    label="Full Name" 
                    variant="outlined" 
                    name="name" 
                    value={data.name} 
                    onChange={handleOnChange} 
                    fullWidth 
                />
                <TextField 
                    label="Mobile No" 
                    variant="outlined" 
                    name="mobile" 
                    value={data.mobile} 
                    onChange={handleOnChange} 
                    fullWidth 
                />
                <Typography variant="body2" sx={{ mt: 2 }}>Member Since:</Typography>
                <Typography variant="body2">Total Payouts: $0.00</Typography>
                
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button type="submit" variant="contained" color="primary"
                        sx={{
                          margin: 0.5,
                          backgroundColor: "#043885",
                          "&:hover": {
                            backgroundColor: "#052659",
                          },
                        }}>
                          Profile Update</Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CompaniesProfile;

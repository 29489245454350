import React, {useState} from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Industrialiot from "../learningpath/projects/Industrial-IoT-projects";
import addActiveCourse from "./hooks/AddactiveCourses";
import useLearnerStatus from './hooks/LlearnersSubscription';  // Import the custom hook
import useLearningProgress from './hooks/Courseslearnigpath';
import { IndustrialiotCourses } from "./hooks/coursesData"; // Import the courses data


const Start = () => {
    const [,setMessage] = useState(null);
    const learnerFound = useLearnerStatus();  // Use the custom hook for learner status
    const learningProgress = useLearningProgress(); // Use the custom hook for learning progress
    
  return (
    <div>
    <div className="mylearningpaths">
      <h1 className="mylearningpaths-h1">Industrial IoT and Insutry4.0</h1>
      <p className="mylearningpaths-p">Courses</p>
      <div className="mylearningpath">
        <div className="Wrapper-sc-fvyqwl-1 iZEyIQ AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
          <ul className="Circle-sc-fvyqwl-1">
              {IndustrialiotCourses.map((course, index) => {
                const courseProgress = learningProgress.find(
                  (progress) => progress.productId === course.videoUrl
                );
                const progress = courseProgress ? courseProgress.productProgress : 0;

                return (
                  <li key={`${course.courseId}-${index}`}>  {/* Combining courseId and index */}
                    <div className="learningpath-courses-dashboard">
                      <div>
                        <img src={course.image} alt={course.courseTitle} className="smart-shoe-development" />
                      </div>
                      <div>
                        <p className={`pythoncourses${course.courseId}`} style={{ paddingTop: "10px" }}>
                          {index + 1}. {course.courseTitle}
                        </p>
                        <div className="learningpath-courses-dashboard-progressbar">
                          <p className="learningpath-courses-dashboard-skills-completed">{Math.min(progress || 0, 100).toFixed(0)}%</p>
                          <ProgressBar now={progress} className={`custom-progress-bar`} />
                        </div>
                        <div>
                          <div>
                            {progress === 100 ? (
                              <button className="learningpath-courses-dashboard-button-start">Completed</button>
                            ) : progress >= 100 ? (
                              <button onClick={() => addActiveCourse(course.courseTitle, course.videoUrl, setMessage)} className="learningpath-courses-dashboard-button-start">Completed</button>
                            ) : progress >= 1 && progress <= 99 ? (
                              <button onClick={() => addActiveCourse(course.courseTitle, course.videoUrl, setMessage)} className="learningpath-courses-dashboard-button-start">
                                Continue Learning
                              </button>
                            ) : (
                              <>
                                {learnerFound === false ? (
                                  <button onClick={() => addActiveCourse(course.courseTitle, course.videoUrl, setMessage)} className="learningpath-courses-dashboard-button-start">
                                    Subscribe
                                  </button>
                                ) : (
                                  <button onClick={() => addActiveCourse(course.courseTitle, course.videoUrl, setMessage)} className="learningpath-courses-dashboard-button-start">
                                    Start
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
        </div>
      </div>
      <Industrialiot />
    </div>
  </div>
);
};

export default Start;
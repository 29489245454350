import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Googlereview from "../../assets/google review.jpg";
import Reveal from "../../helper/useScrollposition";
import "./learners_reviews.css";

// Custom slide component
const SlideItem = ({ name, code, feedback }) => (
    <div className="homepage-slide-items-color">
        <div className="homepage-slide-item">
            <p className="homepage-slide-item-p">{code}</p>
            <div className="homepage-card-body">
                <h1 className="homepage-card-title-paths">{name}</h1>
                <p className="homepage-card-title-paths-p">{feedback}</p>
            </div>
        </div>
    </div>
);

const Slide = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        // Remove custom arrows for now to test the default behavior
        //prevArrow: null,  // Removing custom arrow
        //nextArrow: null,  // Removing custom arrow
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="homepage-experience-learners">
            <Reveal>
                <h1 className="huebits-experience-homepages1-students-h1">
                    What Our Learners Say About Us
                </h1>
                <p className="huebits-homepages1-review-p">
                    Tremendous Feedback from learners across India
                </p>
                <img className="homepage-googlereview" src={Googlereview} alt="Google Review" />
                <div className="homepage-reviewsflexs">
                    <Slider {...settings}>
                        <SlideItem
                            name="Kishore Rao"
                            code="KR"
                            feedback="Service is really good and I like his explanation. I have taken IoT course from here and they provide kit also."
                        />
                        <SlideItem
                            name="Sai Bhaskar"
                            code="SB"
                            feedback="Thanks for educating us for IoT, Especially BAPUJI Sir thanks for teaching us. Learned well and good."
                        />
                        <SlideItem
                            name="Ravikiran Munganda"
                            code="RM"
                            feedback="It was amazing, experienced and the best faculty at Huebits platform, it was my dream to learn projects."
                        />
                        <SlideItem
                            name="SaSi Kanth"
                            code="SK"
                            feedback="It's a good organisation. Learned a lot of knowledge in just 15 days of internship."
                        />
                        <SlideItem
                            name="Suryarao Nagiredla"
                            code="SN"
                            feedback="Best place to learn machine learning course. Organizers are very friendly and will provide good amount of content."
                        />
                        <SlideItem
                            name="Divya Gedela"
                            code="DG"
                            feedback="It's fortune to have internship at Huebits platform. They explain the concept clearly and care about you like family."
                        />
                    </Slider>
                </div>
            </Reveal>
        </div>
    );
};

export default Slide;
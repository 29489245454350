import axios from 'axios';
import { LearnersDashboard } from "../../../helper/base_url";

export const getLearningPathData = async (learningPath, courses, projects = [], setResponseData) => {
  console.group(`Courses for ${learningPath}:`);
  if (courses && courses.length > 0) {
    courses.forEach((course, index) => {
      console.log(`Course ${index + 1}:`);
      console.log(`  Title: ${course.courseTitle}`);
      console.log(`  Video URL: ${course.videoUrl}`);
    });
  } else {
    console.log('No courses available for this learning path.');
  }
  console.groupEnd();

  console.group(`Projects for ${learningPath}:`);
  if (projects.length > 0) {
    projects.forEach((project, index) => {
      console.log(`Project ${index + 1}:`);
      console.log(`  Title: ${project.projectTitle}`);
      console.log(`  Video URL: ${project.videoUrl}`);
    });
  } else {
    console.log('No projects available for this learning path.');
  }
  console.groupEnd();

  try {
    const response = await axios.post(
      `${LearnersDashboard}`,
      {
        selectedPath: learningPath,
        courses,
        projects,
      },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    // Add a delay (e.g., 2 seconds)
    await new Promise((resolve) => setTimeout(resolve, 10000));

    // Update state with response data after the delay
    setResponseData(response.data);
    console.log('Backend Response:', response.data);
    console.log('Backend Response1:', response.data.learningPathProgress);

  } catch (error) {
    console.error('Error sending data to backend:', error);
  }
};

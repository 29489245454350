import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LearnersSinglelogin } from "../../../../helper/base_url";
import { Button, Typography, Box } from '@mui/material';

const Getaccesstoallcourses = () => {
  //const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ssoUrl, setSsoUrl] = useState('');

  const handleClick = async () => {
    //setLoading(true);
    setError(null);
    
    try {
      // Send POST request to the backend without videoUrl
      const response = await axios.post(`${LearnersSinglelogin}`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      // Use the SSO URL returned from the backend
      setSsoUrl(response.data);

    } catch (err) {
      setError('Error generating SSO token. Please try again later.');
      //console.error(err);
    }
  };

  // Fetch the SSO URL on initial render if not already fetched
  useEffect(() => {
    if (!ssoUrl) {
      handleClick();
    }
  }, [ssoUrl]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "rgb(2, 36, 80)", // Properly formatted as a string
          padding: "10px",
          width: "auto",
          margin: "10px 40px 0px 40px",
          borderRadius: "20px"
        }} >
        <Typography variant="h4" sx={{color: "#ffff"}}>
          Looks like you are not subscribed with us
        </Typography>
        {ssoUrl && (
          <Box display="flex" justifyContent="left" margin="10px">
            <Button
              variant="contained"
              color="primary"
              href={ssoUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ backgroundColor: 'white', color: '#043885', fontWeight: 900 }}
            >
             Get Access To All Courses
            </Button>
          </Box>
        )}
        {error && <p className='error-message'>{error}</p>}
      </div>
    </div>
  );
};

export default Getaccesstoallcourses;
import React from "react";
import Projectdata from "./projectData";
import { Box, Container, Paper } from "@mui/material";
import { ToastContainer } from "react-toastify";
import OrganizationsHeader from "../PopupOrganizations/topbar";


const Postproject6 = () => {

  return (
    <div className="students-flex">
        <OrganizationsHeader />
        <div className="signup-pages-flex">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              "linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%)",
            padding: "40px 10px 40px 10px",
          }}
        >
          <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
          <Box
            sx={{
              marginTop: 0,
              width: {
                xs: "100%", // for phones
                sm: "90%", // for small laptops
                md: "60%",  // for desktops
              },
            }}
          >
            <Container>
              <Paper elevation={2} sx={{ borderRadius: 2, textAlign: "center", padding: "10px 20px 30px 20px" }}>
                {/* <Typography variant="h4" style={{ color: "#052659" }}>
                  Project Data
                </Typography> */}
                <Projectdata />
              </Paper>
            </Container>
          </Box>
        </div>
        </div>
    </div>
  );
};

export default Postproject6;
import React, {useState,useRef} from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaArrowUp,FaLinkedin, FaYoutube  } from 'react-icons/fa';
import Headerpage from "../Headerpages/HeaderPage";
import Footer from "./Footer";
import "./termsofuse.css";

const TermsOfUse = () => {
  const [activeSection, setActiveSection] = useState("learners");
  const footerSectionRef = useRef(null);
  const AccountsSectionRef = useRef(null);
  const CourseSectionRef = useRef(null);
  const PaymentsSectionRef = useRef(null);
  const ContentSectionRef = useRef(null);
  const IntellectualSectionRef = useRef(null);
  const TerminationSectionRef = useRef(null);
  const DisclaimersSectionRef = useRef(null);
  const IndemnificationSectionRef = useRef(null);
  const GoverningSectionRef = useRef(null);
  const ModificationsSectionRef = useRef(null);
  const ContactSectionRef = useRef(null);
    
    const scrollToFooterSection = () => {
      footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToAccounts = () => {
      AccountsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToCourse = () => {
      CourseSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToPayments = () => {
      PaymentsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToContent = () => {
      ContentSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToIntellectual = () => {
      IntellectualSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToTermination = () => {
      TerminationSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToDisclaimers = () => {
      DisclaimersSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToIndemnification = () => {
      IndemnificationSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToGoverning = () => {
      GoverningSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToModifications = () => {
      ModificationsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToContact = () => {
      ContactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSectionToggle = (section) => {
      setActiveSection(section);
    };

    //Organization
    const AccountsSectionsRef = useRef(null);
    const CourseSectionsRef = useRef(null);
    const PaymentsSectionsRef = useRef(null);
    const ContentSectionsRef = useRef(null);
    const IntellectualSectionsRef = useRef(null);
    const TerminationSectionsRef = useRef(null);
    const DisclaimersSectionsRef = useRef(null);
    const ContactSectionsRef = useRef(null);

    const scrollToAccount = () => {
      AccountsSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToCours = () => {
      CourseSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToPayment = () => {
      PaymentsSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToContents = () => {
      ContentSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToIntellectuals = () => {
      IntellectualSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToTerminations = () => {
      TerminationSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToDisclaimer = () => {
      DisclaimersSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }; const scrollToContacts = () => {
      ContactSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <div className= "students-flex">
      <Headerpage />
     <div className="homepagecolores">
     <div className="footerpage-top-buttons">
        <button
          className={`footertoggle-button ${activeSection === "learners" ? "active" : ""}`}
          onClick={() => handleSectionToggle("learners")}
        >
          Learners
        </button>
        <button
          className={`footertoggle-button ${activeSection === "organizations" ? "active" : ""}`}
          onClick={() => handleSectionToggle("organizations")}
        >
          Organizations
        </button>
      </div>
    <div ref={footerSectionRef}>
      {activeSection === "learners" && (
    <div className="terms-container">
      <h1>Terms and Conditions for Learners</h1>
      <p className="last-updated">These Terms of Use <strong>("Terms")</strong> were last updated on January 18, 2025.</p>
      <p>
        Welcome to Huebits Platform <strong>(“Huebits”)</strong>, operated by Huebits Tech Private Limited
         <strong>(“Company,” “we,” “us,” or “our”)</strong>. These Terms of Use <strong>(“Terms”)</strong> govern your use of our website, 
         mobile applications, and other services. By accessing or using the Platform, you agree to be bound by these Terms. If you do not 
         agree to these Terms, you may not use our services.
      </p>

      <h2>Our Mission</h2>
      <p>
        At Huebits, our mission is to empower learners and professionals by providing cutting-edge education and consulting
         in Emerging Technologies. We aim to bridge the gap between academia and industry, fostering innovation and expertise through our comprehensive learning platform.
      </p>
      <div className="terms-containers-div">
      <h2>Contents</h2>
      <ul>
        <li onClick={scrollToAccounts}>1. Accounts</li>
        <li onClick={scrollToCourse}>2. Course Enrollment and Access</li>
        <li onClick={scrollToPayments}>3. Payments and Refunds</li>
        <li onClick={scrollToContent}>4. Content and Behavior Rules</li>
        <li onClick={scrollToIntellectual}>5. Intellectual Property</li>
        <li onClick={scrollToTermination}>6. Termination</li>
        <li onClick={scrollToDisclaimers}>7. Disclaimers and Limitation of Liability</li>
        <li onClick={scrollToIndemnification}>8. Indemnification</li>
        <li onClick={scrollToGoverning}>9. Governing Law and Dispute Resolution</li>
        <li onClick={scrollToModifications}>10. Modifications to These Terms</li>
        <li onClick={scrollToContact}>11. Contact Us</li>
      </ul>
      </div>
      <h2 ref={AccountsSectionRef}>1. Accounts</h2>
      <p>
        <strong>Account Registration:</strong> You need an account to use most features of the Platform. You must provide accurate and complete information when registering for an account.
      </p>
      <p>
        <strong>Account Responsibilities:</strong> You are responsible for maintaining the security of your account and all activities under it. You must notify us immediately of any unauthorized use of your account.
      </p>
      <p>
        <strong>Account Restrictions:</strong> You may not share your account credentials with others or transfer your account to someone else.
      </p>

      <h2 ref={CourseSectionRef}>2. Course Enrollment and Access</h2>
      <p>
        <strong>Access:</strong> When you enroll in a course or access content, we grant you a limited, non-exclusive, non-transferable license to access the content for personal, non-commercial purposes.
      </p>
      <p>
        <strong>Restrictions:</strong> You may not reproduce, redistribute, transmit, assign, sell, broadcast, rent, share, modify, or otherwise transfer the content unless we give you explicit permission.
      </p>
      <p>
        <strong>Revocation:</strong> We reserve the right to revoke any license to access content at our discretion.
      </p>

      <h2 ref={PaymentsSectionRef}>3. Payments and Refunds</h2>
      <p>
        <strong>Pricing:</strong> The price for courses and services will be stated at the time of purchase. We reserve the right to change prices at any time.
      </p>
      <p>
        <strong>Payments:</strong> You agree to pay all fees associated with your use of the Platform. Payment details must be accurate and complete.
      </p>
      <p>
        <strong>Refunds:</strong> Refunds are available as per our refund policy, which may vary depending on the course or service.
      </p>

      <h2 ref={ContentSectionRef}>4. Content and Behavior Rules</h2>
      <p>
        <strong>User Content:</strong> You retain ownership of any content you post on the Platform, but you grant us a license to use, reproduce, and share it as needed to provide our services.
      </p>
      <p>
        <strong>Prohibited Conduct:</strong> You agree not to engage in any behavior that is illegal, abusive, or violates the rights of others, including:
      </p>
      <ul className="terms-containers-ul">
        <li>Posting inappropriate or offensive content.</li>
        <li>Sharing unauthorized commercial messages.</li>
        <li>Attempting to gain unauthorized access to the Platform.</li>
      </ul>

      <h2 ref={IntellectualSectionRef}>5. Intellectual Property</h2>
      <p>
        <strong>Platform Content:</strong> All content provided by us, including courses, trademarks, and software, is protected by intellectual property laws and is owned by the Company or our licensors.
      </p>
      <p>
        <strong>Your Use:</strong> You may not use our content without permission, except as allowed under these Terms.
      </p>

      <h2 ref={TerminationSectionRef}>6. Termination</h2>
      <p>
        We may terminate or suspend your account or access to the Platform at any time, with or without notice, if you breach these Terms or engage in inappropriate conduct.
      </p>

      <h2 ref={DisclaimersSectionRef}>7. Disclaimers and Limitation of Liability</h2>
      <p>
        <strong>No Warranty:</strong> The Platform and content are provided “as is” without warranties of any kind.
      </p>
      <p>
        <strong>Limitation of Liability:</strong> To the fullest extent permitted by law, we are not liable for any indirect, incidental, or consequential damages arising from your use of the Platform.
      </p>

      <h2 ref={IndemnificationSectionRef}>8. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless the Company, its officers, directors, employees, and affiliates from any claims, losses, or damages arising from your breach of these Terms or your use of the Platform.
      </p>

      <h2 ref={GoverningSectionRef}>9. Governing Law and Dispute Resolution</h2>
      <p>
        These Terms are governed by the laws of India. Any disputes will be resolved through binding arbitration under the Arbitration and Conciliation Act, 1996, with the arbitration venue in Visakhapatnam, India.
      </p>

      <h2 ref={ModificationsSectionRef}>10. Modifications to These Terms</h2>
      <p>
        We may update these Terms from time to time. Continued use of the Platform after changes are posted constitutes your acceptance of the modified Terms.
      </p>

      <h2 ref={ContactSectionRef}>11. Contact Us</h2>
      <p>
        If you have any questions about these Terms, you can contact us at:
      </p>
      <p>
        <strong>Huebits Tech Private Limited</strong><br />
        Email: <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@huebits.com" 
            style={{ textDecoration: 'none'}} target="_blank" rel="noopener noreferrer" >
              support@huebits.com
        </a>
      </p>

      <p>Thank you for using Huebits!</p>
    </div>
     )}
    {/* Organization */}
    {activeSection === "organizations" && (
    <div className="terms-container">
      <h1>Terms and Conditions for Organization</h1>
      <p className="last-updated"><strong>Effective Date:</strong> 20th January 2025</p>
      <p>
      These Terms and Conditions <strong>(“Terms”)</strong> govern the relationship between Huebits Tech Private Limited
       <strong>(“Huebits”)</strong> and any organization <strong>(“Client”)</strong> engaging in business with Huebits. 
       By utilizing Huebits’ services or entering into a business relationship, the Client agrees to comply with these Terms. 
      </p>
      <div className="terms-containers-div">
      <h2>Contents</h2>
      <ul>
        <li onClick={scrollToAccount}>1. Definitions</li>
        <li onClick={scrollToCours}>2. Usage Rights and Licenses</li>
        <li onClick={scrollToPayment}>3. Data Privacy and Confidentiality</li>
        <li onClick={scrollToContents}>4. Termination of Usage Rights</li>
        <li onClick={scrollToIntellectuals}>5. Dispute Resolution and Governing Law</li>
        <li onClick={scrollToTerminations}>6. Amendments</li>
        <li onClick={scrollToDisclaimer}>7. Entire Agreement</li>
        <li onClick={scrollToContacts}>8. Contact Information</li>
      </ul>
      </div>
      <h2 ref={AccountsSectionsRef}>1. Definitions</h2>
      <ul className="terms-containers-ul">
        <li><strong>Huebits Services:</strong> Refers to all products, solutions, consulting, and services provided by Huebits to organizations.</li>
        <li><strong>Client Data:</strong> Any data, content, or information provided by the Client to Huebits for service delivery.</li>
        <li><strong>Deliverables:</strong> The tangible or intangible outputs provided by Huebits as part of its services to the Client.</li>
      </ul>
      <h2 ref={CourseSectionsRef}>2. Usage Rights and Licenses</h2>
      <h4><strong>2.1 Rights to Use Huebits Services </strong></h4>
      <ul className="terms-containers-ul">
        <li>Huebits grants the Client a non-exclusive, non-transferable, and revocable right to use its services strictly for the purposes agreed upon in a specific service agreement or statement of work.</li>
        <li>The use of Huebits’ services must align with the outlined business terms and applicable laws.</li>
      </ul>
      <h4><strong>2.2 Intellectual Property Ownership</strong></h4>
      <ul className="terms-containers-ul">
        <li>Huebits retains all intellectual property rights to its tools, platforms, methodologies, and deliverables unless explicitly transferred through a written agreement.</li>
        <li>The Client is granted a limited license to use the deliverables solely for internal business purposes. Redistribution, resale, or unauthorized sharing of Huebits’ deliverables is prohibited.</li>
      </ul>
      <h4>2.3 Client Data Rights</h4>
      <ul className="terms-containers-ul">
        <li>The Client retains ownership of any data shared with Huebits.</li>
        <li>The Client grants Huebits a limited license to process, analyze, and utilize this data solely for fulfilling service obligations. Huebits will not use the data for any unauthorized purpose.</li>
      </ul>
      <h4>2.4 Restrictions</h4>
      <p>The Client shall not:</p>
      <ul className="terms-containers-ul">
        <li>Reverse engineer, decompile, or disassemble any tools or platforms provided by Huebits.</li>
        <li>Use Huebits services for any illegal or unauthorized purposes.</li>
        <li>Share proprietary information, tools, or deliverables with third parties without prior written consent.</li>
      </ul>
      <h2 ref={PaymentsSectionsRef}>3. Data Privacy and Confidentiality</h2>
      <ul className="terms-containers-ul">
        <li>Huebits commits to maintaining the confidentiality of all Client Data and complying with applicable data protection laws.</li>
        <li>Both parties agree to protect proprietary or sensitive information shared during the business relationship and ensure it is not disclosed to unauthorized parties.</li>
      </ul>

      <h2 ref={ContentSectionsRef}>4. Termination of Usage Rights</h2>
      <ul className="terms-containers-ul">
        <li>Usage rights granted under these Terms may be terminated if the Client breaches these Terms or the associated service agreement.</li>
        <li>Upon termination, the Client must cease all usage of Huebits’ tools, deliverables, and intellectual property.</li>
      </ul>

      <h2 ref={IntellectualSectionsRef}>5. Dispute Resolution and Governing Law</h2>
      <ul className="terms-containers-ul">
        <li>Any disputes shall be resolved through good-faith negotiations. If unresolved, disputes will be subject to arbitration in <strong>Visakhapatnam, Andhra Pradesh, India.</strong></li>
        <li>These Terms are governed by and construed in accordance with the laws of <strong>India.</strong></li>
      </ul>

      <h2 ref={TerminationSectionsRef}>6. Amendments</h2>
      <p>
      Huebits reserves the right to update these Terms. Clients will be notified of any changes, which will take effect upon written confirmation or continued use of services.</p>
      
      <h2 ref={DisclaimersSectionsRef}>7. Entire Agreement</h2>
      <p>These Terms, along with any additional agreements or statements of work, constitute the complete agreement between Huebits and the Client, superseding all prior understandings.</p>

      <h2 ref={ContactSectionsRef}>8. Contact Information</h2>
      <p>
      For any questions or concerns regarding these Terms, please contact Huebits at:</p>
      <p>
        <strong>Huebits Tech Private Limited</strong><br />
        Email: <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@huebits.com" 
            style={{ textDecoration: 'none'}} target="_blank" rel="noopener noreferrer" >
              support@huebits.com
        </a>
      </p>
      {/* <p>Thank you for trusting <strong>Huebits</strong> to support your learning and professional journey.</p> */}
    </div>
    )}
     <div className="homepage-footers-images">
     <Footer />
    <footer className="homepage-footers">
     <hr className='homepage-footer-hr'/>
       <div className="footer-social">
       <div className="homepage-footer-copyright">
         <p className="homepage-footer-copyrightp">© Huebits[2025].All Rights Reserved.</p>
       </div>
       <div className='footer-social-icons'>
         <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
         <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
         <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
         <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
         {/* <Link><FaFacebook className="social-icon" /></Link> */}
         <Link onClick={scrollToFooterSection}>
         <FaArrowUp className="homepage-social-icon-link" />
         </Link>
       </div>
       </div>
     </footer>
     </div>
     </div>
     </div>
    </div>
  );
};

export default TermsOfUse;
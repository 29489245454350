import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import { getLearningPathData } from './api';
import { IoTdevelopercourses, IoTDataScientistcourses, IndustrialiotCourses } from '../learningpath/hooks/coursesData';
import { IoTdeveloperProjects, IoTDataScientistProjects } from '../learningpath/hooks/projectsList';
//import DashboardLiast from './dashboardLiast';
import LearningPathProgressDisplay from './dashboardLiast'; // Import the new component

const DropdownMenu = () => {
  const [selectedOption, setSelectedOption] = useState(localStorage.getItem('selectedLearningPath') || '');
  const [responseData, setResponseData] = useState({
    HOE: "",
    learningPathProgress: "",
    totalCoursesProgress: "",
    totalProjectsProgress: "",
  });

  const options = [
    'IoT Developer / Engineer',
    'IoT Data Scientist',
    'Industrial IoT & Industry 4.0',
  ];

  useEffect(() => {
    if (selectedOption) {
      switch (selectedOption) {
        case 'IoT Developer / Engineer':
          getLearningPathData(selectedOption, IoTdevelopercourses, IoTdeveloperProjects, setResponseData);
          break;
        case 'IoT Data Scientist':
          getLearningPathData(selectedOption, IoTDataScientistcourses, IoTDataScientistProjects, setResponseData);
          break;
        case 'Industrial IoT & Industry 4.0':
          getLearningPathData(selectedOption, IndustrialiotCourses, [], setResponseData);
          break;
        default:
          console.log(`Invalid learning path in localStorage: "${selectedOption}"`);
      }
    }
  }, [selectedOption]);

  const handleChange = (event) => {
    const selected = event.target.value;
    setSelectedOption(selected);
    localStorage.setItem('selectedLearningPath', selected);

    console.clear();
    console.log(`Selected Learning Path: ${selected}`);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'flex-start',flexWrap: 'wrap', margin: '10px' }}>
        <Typography variant="h6" style={{ color: '#333', marginLeft: '20px' }}>
          Choose Your Learning Path:
        </Typography>
                <FormControl
            variant="outlined"
            sx={{
              minWidth: '200px',
              // marginLeft: { sm: '10px', xs: '0' },
              margin: { xs: '20px 0px 0px 20px', sm: '0 0 0 10px' }, // Add space between elements for small screens
            }}
          >          
          <InputLabel htmlFor="dropdown">Learning Path</InputLabel>
          <Select
            label="Learning Path"
            id="dropdown"
            value={selectedOption}
            onChange={handleChange}
            style={{ width: '100%', height: '40px' }}
          >
            <MenuItem value="">
              <em>--Select--</em>
            </MenuItem>
            {options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {(selectedOption === 'IoT Developer / Engineer' || selectedOption === 'IoT Data Scientist' || selectedOption === 'Industrial IoT & Industry 4.0') &&   <LearningPathProgressDisplay selectedOption={selectedOption} responseData={responseData} />}
    </div>
  );
};

export default DropdownMenu;
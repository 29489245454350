import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Grid, Container, Box } from '@mui/material';
import InternetofThings from '../../../assets/learningpaths/1.png';
import DataScienceAIMLDL from '../../../assets/learningpaths/2.png';
import EmbeddedSystems from '../../../assets/learningpaths/3.png';
import useLearnerStatus from '../learningpath/hooks/LlearnersSubscription';
import Topbar from "./topbar";

const LearningPathCard = ({ image, title, description, buttonLabel, onClick, isDisabled }) => (
  <Grid item xs={12} sm={4} align="center">
    <Box sx={{ boxShadow: 3, borderRadius: '8px', overflow: 'hidden', backgroundColor: "#ffff" }}>
      <img
        src={image}
        alt={title}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
      />
      <Box p={3}>
        <Typography variant="h5" sx={{ fontWeight: 700, color: '#052659' }} gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" paragraph>
          {description}
        </Typography>
        <Button
          variant={isDisabled ? "outlined" : "contained"}
          color={isDisabled ? "secondary" : "primary"}
          sx={{
            width: '100%',
            background: !isDisabled && 'linear-gradient(135deg, #5483B3, #052659)',
            '&:hover': !isDisabled && { background: 'linear-gradient(135deg, #052659, #345A80)' },
          }}
          onClick={onClick}
          disabled={isDisabled}
        >
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  </Grid>
);

const LearningPath = () => {
  const navigate = useNavigate();
  const learnerFound = useLearnerStatus();
  const [, setClickedPaths] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const hasStartedProgram = localStorage.getItem("hasStartedProgram");
  
    if (token) {
      localStorage.setItem("token", token);
      navigate("/dashboardpage");
    } else {
      const storedToken = localStorage.getItem("token");
      if (storedToken && hasStartedProgram) {
        // User has started a program and is logged in
        const lastPath = localStorage.getItem("lastPath") || "internetofthings";        
        navigate(`/dashboard/${lastPath}`);
      } else if (!storedToken) {
        // No token, redirect to login
        navigate("/login");
      }
    }
  }, [navigate]);
  
  const handleStartClick = (path) => {
    setClickedPaths((prev) => ({ ...prev, [path]: true }));
    
    // Set the program started flag in localStorage
    localStorage.setItem("hasStartedProgram", "true");
    
    navigate(`/dashboard/${path}`);
  };  

  const learningPaths = [
    {
      id: "internetofthings",
      image: InternetofThings,
      title: "Internet of Things",
      description: "Master the technology behind smart, connected solutions.",
      isDisabled: false,
    },
    {
      id: "datascience",
      image: DataScienceAIMLDL,
      title: "Data Science",
      description: "Harness the power of data to drive innovation and decisions.",
      isDisabled: true,
    },
    {
      id: "embeddedsystems",
      image: EmbeddedSystems,
      title: "Embedded Systems",
      description: "Build intelligent systems shaping the future of technology.",
      isDisabled: true,
    },
  ];

  return (
    <div className="students-flex">
      <Topbar />
      <div className="mylearningpaths">
        <Container>
          <Box mb={6}>
            <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 900, color: '#052659', paddingTop: '20px' }}>
              Explore Your Future with Our Industry-Ready Programs
            </Typography>
            <Typography variant="h6" align="center" paragraph sx={{ color: '#333' }}>
              Thank you for joining us! Choose your path to success.
            </Typography>
          </Box>

          <Grid container spacing={4} justifyContent="center">
            {learningPaths.map((path) => (
              <LearningPathCard
                key={path.id}
                image={path.image}
                title={path.title}
                description={path.description}
                buttonLabel={path.isDisabled ? "Coming Soon" : learnerFound ? "Start" : "Subscribe"}
                onClick={() => !path.isDisabled && handleStartClick(path.id)}
                isDisabled={path.isDisabled}
              />
            ))}
          </Grid>

          <Box mt={5}>
            <Typography variant="body1" align="center" paragraph sx={{ color: '#333', paddingBottom: '40px' }}>
              Your journey begins here. Select your program and take the first step today!
            </Typography>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default LearningPath;
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const token = localStorage.getItem("token"); // Check for token in localStorage

  if (!token) {
    // Redirect to the login page if not logged in
    return <Navigate to="/login" replace />;
  }

  // Render all child routes if logged in
  return <Outlet />;
};

export default ProtectedRoute;

import React from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Course1 from "../../../assets/dashboard-images/images/images/projects/16.png";
import Course2 from "../../../assets/dashboard-images/images/images/4.png";
import Course3 from "../../../assets/dashboard-images/images/images/projects/27.png";
import Course4 from "../../../assets/dashboard-images/images/images/9.png";
import Course5 from "../../../assets/dashboard-images/images/images/7.png";
import Course6 from "../../../assets/images/course.png";
import Course7 from "../../../assets/dashboard-images/images/images/10.png";
import Course8 from "../../../assets/dashboard-images/images/images/projects/19.png";
import "./projects.css";

const Slide = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: <button className="slick-prev"><i className="fas fa-chevron-left"></i></button>,
        nextArrow: <button className="slick-next"><i className="fas fa-chevron-right"></i></button>,
        responsive: [
            {
                breakpoint: 1024, 
                settings: {
                    slidesToShow: 2, 
                }
            },
            {
                breakpoint: 768, 
                settings: {
                    slidesToShow: 2, 
                }
            },
            {
                breakpoint: 480, 
                settings: {
                    slidesToShow: 1, 
                }
            }
        ]
    };

    return (
        <div className="smartcity-slider-container">
        <Slider {...settings}>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Introduction-to-Internet-of-Things--66e8028064721c6daa143ae8">
               <img className="iot-courses-list-images" src={Course1} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-courses-iot">
                    <h1 className="IoTUniverse3-card-title-paths-courses">Introduction to Internet of Things</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Internet-of-Things-using-Raspberry-Pi-and-Python-66044b9cdf77f21d16e2c40c">
                <img className="iot-courses-list-images" src={Course2} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-courses-iot">
                    <h1 className="IoTUniverse3-card-title-paths-courses">IoT using Raspberry Pi and Python</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/MING-Technologies--MQTT-InfluxDB-Node-Red-Grafana-66e8190e268fd80c0d02f1ec">
                <img className="iot-courses-list-images" src={Course3} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-courses-iot">
                    <h1 className="IoTUniverse3-card-title-paths-courses">MING Technologies in Industrial IoT</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Master-BLE-and-Bluetooth-Technology-660e80340229d83cda0e6e48">
                <img className="iot-courses-list-images" src={Course4} alt="Smart City"/>
                <div className="IoTUniverse3-card-courses-iot">
                    <h1 className="IoTUniverse3-card-title-paths-courses">Master ESP32, Bluetooth, and BLE</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Internet-of-Things-using-ESP32-and-Arduino-660e7e601e0fac451502fb0c">
                <img className="iot-courses-list-images" src={Course5} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-courses-iot">
                    <h1 className="IoTUniverse3-card-title-paths-courses">IoT and Embedded Systems with ESP32</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Developing-Web-Applications-for-IoT-Using-Python-andJavaScript-66e807c6ece5194df3bc5213">
                <img className="iot-courses-list-images" src={Course6} alt="Smart Street Lighting System"/>
                <div className="IoTUniverse3-card-courses-iot">
                    <h1 className="IoTUniverse3-card-title-paths-courses">Developing Web Applications for IoT Using Python and JavaScript</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/Full-Stack-IoT-using-Raspberry-Pi-and-JavaScript-660e7f660229d83cda0e6da7">
                <img className="iot-courses-list-images" src={Course7} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-courses-iot">
                    <h1 className="IoTUniverse3-card-title-paths-courses">Full stack IoT using JavaScript, MERN stack and Raspberry Pi</h1>
                </div>
            </Link>
            </div>
            <div className="iot-smartcity-slide-item">
            <Link to="https://learn.huebits.in/courses/IoT-Data-Visualization-using-PowerBI-Tableau-Grafana-and-Kibana-66ace17c277599313406d4f1">
                <img className="iot-courses-list-images" src={Course8} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-courses-iot">
                    <h1 className="IoTUniverse3-card-title-paths-courses">IoT Data Visualization using PowerBI, Tableau, Grafana and Kibana</h1>
                </div>
            </Link>
            </div>
        </Slider>
        </div>
    );
};

export default Slide;
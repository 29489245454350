import { useState, useEffect } from 'react';
import { LearnersSubscription } from '../../../../helper/base_url';

const Subscription = () => {
    const [learnerFound, setLearnerFound] = useState(false);

    useEffect(() => {
        const fetchLearnerStatus = async () => {
            try {
                const response = await fetch(`${LearnersSubscription}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                const data = await response.json();
                if (data.status === 200 && data.learner) {
                    setLearnerFound(true); // Learner found
                } else {
                    setLearnerFound(false); // Learner not found
                }
            } catch (error) {
                console.error("Error fetching learner status:", error);
                setLearnerFound(false); // Default to false on error
            }
        };

        fetchLearnerStatus();
    }, []);

    return learnerFound;
};

export default Subscription;
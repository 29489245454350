import React from 'react';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Footerhome from "../../assets/hue1 w.png";
import './footer.css';

const Footer = () => {
  return (
    <div className='homepage-footer'>
      <footer>
        <div className="homepage-footer-links">
          <ul>
          <li className="homepage-footer-linkh1">Quick links</li>
          <div className='homepage-footer-linkh1-ul'>
            <div>
            <li><Link to="/" className="homepage-footer-link">Home</Link></li>
            <li><Link to="/learners" className="homepage-footer-link">Learners</Link></li>
            <li><Link to="/organizations" className="homepage-footer-link">Organizations</Link></li>
            <li><Link to="/internetofthings" className="homepage-footer-link">Internet of Things</Link></li>
            <li><Link className="homepage-footer-link">Embedded Systems</Link></li>
            <li><Link className="homepage-footer-link">Data Science- AI/ML/DL</Link></li>
            </div>
            {/* <li><Link to="/internetofthings" className="homepage-footer-link">Internet of Things</Link></li>
            <li><Link to="/embeddedsystems" className="homepage-footer-link">Embedded Systems</Link></li>
            <li><Link to="/datascience" className="homepage-footer-link">Data Science- AI/ML/DL</Link></li> */}
            </div>
          </ul>
          <div className="footer-contact-info">
          <h3 className='footer-contact-infoh1'> Contact Us</h3>
          <p className='footer-contact-infoh1-icon'><FaEnvelope className="footer-contact-icon" /> hello@huebits.in</p>
          <p className='footer-contact-infoh1-icon'><FaMapMarkerAlt className="footer-contact-icon" /> India</p>
        </div>
        <Link to="/">
        <img className="footer-hompage-image" src={Footerhome} alt="" />
        </Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown,faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Internet from "../../../assets/learningpaths/1.png";
import Datasciences from "../../../assets/learningpaths/2.png";
import Embedded from "../../../assets/learningpaths/3.png";
import Reveal from '../../../helper/useScrollposition';
import './Students-learning-path.css';

const Learning = () => {
  return (
    <div>
      <Reveal>
      <div className="learningpath-ourney1">
        <ul className="learningpath-ourney-navbar-nav">
          <li className="learningpath-ourney-nav-item">
            Comprehensive Training <FontAwesomeIcon icon={faChevronDown} />
            <ul className="learningpath-ourney-dropdown-menu">
              <li>Unlock the full spectrum of each technology with our exclusive training modules. 
                From fundamentals to advanced concepts, we've got you covered.
              </li>
            </ul>
          </li>
        </ul>
        <ul className="learningpath-ourney-navbar-nav">
          <li className="learningpath-ourney-nav-item">
            AI-Powered Dynamic Learning Paths <FontAwesomeIcon icon={faChevronDown} />
            <ul className="learningpath-ourney-dropdown-menu">
              <li>Experience personalized learning at its finest. Our platform harnesses the power 
                of AI to tailor your learning journey, ensuring it aligns perfectly with your needs and goals.
              </li>
            </ul>
          </li>
        </ul>
        <ul className="learningpath-ourney-navbar-nav">
          <li className="learningpath-ourney-nav-item">
            Real Industrial Experiences <FontAwesomeIcon icon={faChevronDown} />
            <ul className="learningpath-ourney-dropdown-menu">
              <li>Bridge theory with practice seamlessly. Solve real industry problems, earn valuable experience, 
                and receive stipends through our industrial collaborations and get hired.
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="learningpath-ourneys">
      <ul className="learningpath-ourney-navbar-nav">
        <li className="learningpath-ourney-nav-item">
          Unlimited Access <FontAwesomeIcon icon={faChevronDown} />
          <ul className="learningpath-ourney-dropdown-menu">
            <li>Access our platform and its extensive resources, including courses and projects, anytime, anywhere, 24/7.</li>
          </ul>
        </li>
      </ul>
      <ul className="learningpath-ourney-navbar-nav">
        <li className="learningpath-ourney-nav-item">
          Live Support and Mentoring <FontAwesomeIcon icon={faChevronDown} />
          <ul className="learningpath-ourney-dropdown-menu">
            <li>Receive personalized guidance every step of the way. Our dedicated mentors provide live support, 
              ensuring you stay on track and maximize your learning potential.
            </li>
          </ul>
        </li>
      </ul>
    </div>
    </Reveal>
    </div>
  );
}

const Studentslearningpaths = () => {
  return (
    <div>
    <Reveal>
    <div className="students-learning-patha-cardflexs">
  <div className="students-learning-patha-card">
 <img className="students-learning-patha-card-image" src={Internet} alt="" />
    <div className="students-learning-patha-card-body">
    <h5 className="students-learning-patha-card-title">Internet of Things</h5>
    <p className="students-learning-patha-card-text"><span className="spansp">Become an IoT expert with our step-by-step learning path, 
    covering everything from basics to advanced topics through courses and projects.</span></p>
     <div>
      <p className='students-button-Learn-More learn-more'>
    <Link to="/internetofthings"><button className="students-Learn-More-button learn-more">Learn More
    <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
    </button></Link>
    </p>
    </div>
  </div>
  </div>
  <div className="students-learning-patha-card">
    <img className="students-learning-patha-card-image" src={Datasciences} alt="" />
    <div className="students-learning-patha-card-body">
    <h5 className="students-learning-patha-card-title">Data Science- AI/ML/DL</h5>
    <p className="students-learning-patha-card-text"><span className="spansp">Embark on your Data Scientist journey with us! From 
    math and stats to programming and algorithms, explore it all with our array of courses and projects.</span></p>
    <p className='students-button-Learn-More'>
    <button className="students-Learn-More-button coming-soon">Coming Soon
    <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
    </button>
    </p>
        {/* <Link to="/datascience"></Link> */}
    </div>
    </div>
    <div className="students-learning-patha-card">
  <img className="students-learning-patha-card-image" src={Embedded} alt="" />
    <div className="students-learning-patha-card-body">
    <h5 className="students-learning-patha-card-title">Embedded Systems</h5>
    <p className="students-learning-patha-card-text"><span className="spansp">Gain hands-on experience with diverse embedded systems technologies, 
    firmware, and hardware to become an embedded systems engineer.</span></p>
     <div>
      <p className='students-button-Learn-More'>
    <button className="students-Learn-More-button coming-soon">Coming Soon
    <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
    </button>
    </p>
    {/* <Link to="/embeddedsystems"></Link> */}
    </div>
  </div>
  </div>
  </div>
  </Reveal>
  </div>
  )
}


export {Learning,Studentslearningpaths};

import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Photo from "./Photo";
import Basic from "./Basic";
import Contact from "./Contact-Information";
import "./User-profile.css";

const UserProfile = () => {
    const [activeLink, setActiveLink] = useState('basic');

    const handleLinkClick = (linkName) => {
        setActiveLink(linkName);
    };

    return (
        <div>
        <div className="mylearningpaths">
      <div className="students-works">
        <div className='students-dashboard-workses'>
        <div className='students-dashboard-works'>
            <div className='students-dashboard-works-links'>
                    <div>
                        <Photo />
                    </div>
                    <div className='profile-link' onClick={() => handleLinkClick('basic')}>
                        <Link to="#" className={activeLink === 'basic' ? 'active-basic-link' : ''}>
                            <p className='active-profile'>
                                Basic Information <FontAwesomeIcon icon={faEdit} className='faedit'/>
                            </p>
                        </Link>
                    </div>
                    <div className='profile-link' onClick={() => handleLinkClick('contact')}>
                        <Link to="#" className={activeLink === 'contact' ? 'active-contact-link' : ''}>
                            <p className='active-profile'>
                                Contact Information <FontAwesomeIcon icon={faEdit} className='faedit'/>
                            </p>
                        </Link>
                    </div>
                </div>
                <div className='myprofilelinks'>
                    {activeLink === 'basic' && <div className='myprofilelink active'><Basic /></div>}
                    {activeLink === 'contact' && <div className='myprofilelink active'><Contact /></div>}
                </div>
                </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default UserProfile;

import React from 'react';
import Commingsoon from "../../../assets/Coming-soon/2.png";

const Technical = () => {
  return (
    <div>
      <h1>Apply For Internships or Freelancing </h1>
    <img src={Commingsoon} alt="comingsoon" className="students-comingsoon-image" />
    <button className="capstonestart livesessions">Coming Soon</button>
  </div>
  )
}

export default Technical;
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField, Grid, Box, Paper, Typography, CircularProgress } from "@mui/material";
import { LearnersProfile } from "../../../helper/base_url";

const Userprofile = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    currenteducational: "",
    studymajor: "",
    yearofstudy: "",
    gpaacademic: "",
  });
  const [userId, setUserId] = useState(null); // Handle userId properly
  const token = localStorage.getItem("token");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const response = await axios.put(`${LearnersProfile}${userId}`, updatedData, {
        headers: { "Authorization": `jwt ${token}` },
      });
      toast.success(response.data.message, { autoClose: 2000 });
      setData(response.data.data);
    } catch (error) {
      toast.error("Network error: Please check your connection.", { autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  }, [userId, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    if (!/^\d+(\.\d+)?%$/.test(data.gpaacademic.trim())) { // Trim spaces for GPA field
      toast.error("Invalid GPA! Please enter a valid percentage like '85%'");
      setLoading(false);
      return;
    }
    await handleUserProfile(data);
  };

  useEffect(() => {
    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode JWT to get userId
      setUserId(decodedToken?.userId || ""); // Set userId from token payload
    }
  }, [token]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
      {loading && <CircularProgress />}
      {token && <ToastContainer position="top-center" />}
      <Paper elevation={3} sx={{ maxWidth: '500px', padding: 2 }}>
        <Typography variant="h5" sx={{ marginBottom: 2, textAlign: 'center' }}>Academic Background</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Organization / Institution"
                fullWidth
                name="currenteducational"
                value={data.currenteducational}
                onChange={handleOnChange}
                required
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Field of Study"
                fullWidth
                name="studymajor"
                value={data.studymajor}
                onChange={handleOnChange}
                required
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Year of Study"
                fullWidth
                name="yearofstudy"
                value={data.yearofstudy}
                onChange={handleOnChange}
                required
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="GPA/Academic Performance"
                fullWidth
                name="gpaacademic"
                value={data.gpaacademic}
                onChange={handleOnChange}
                required
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#043885",
                    "&:hover": {
                      backgroundColor: "#052659",
                    },
                    padding: "10px 20px",
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Save Data"}
                </Button>
          </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default Userprofile;
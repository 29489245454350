import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { OrganizationsCompaniesprofile } from "../../helper/base_url";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Divider } from '@mui/material';

export const CompaniesDashboard = () => {
    const [postProjects, setPostProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(0); // Track the current page
    const itemsPerPage = 1; // Display one project at a time

    // Fetch data on component mount
    useEffect(() => {
        const fetchPostProjects = async () => {
            try {
                const response = await axios.post(`${OrganizationsCompaniesprofile}`, {}, {
                    headers: {
                        "content-type": "application/json",
                        authorization: `jwt ${localStorage.getItem('company_token')}`
                    },
                });
                setPostProjects(response.data.data.postprojects);
            } catch (error) {
                //console.error('Error fetching user details:', error);
            }
        };
        fetchPostProjects();
    }, []);

    // Handle the "Next" button click
    const handleNextPage = () => {
        if ((currentPage + 1) * itemsPerPage < postProjects.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handle the "Previous" button click
    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Get the current set of items to display
    const currentItems = postProjects.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    return (
        <Box sx={{ padding: 1, backgroundColor: '#f4f6f8' }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ color: '#052659' }}>
                Pending Projects
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', color: '#052659' }}>Field</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: '#052659' }}>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {/* style={{ fontSize: '20px' }} */}
                        {currentItems.map((project) => (
                            <>
                                {project.projectTitle && (
                                    <TableRow key={`${project._id}-title`}>
                                        <TableCell><strong>Development:</strong></TableCell>
                                        <TableCell>{project.projectTitle}</TableCell>
                                    </TableRow>
                                )}
                                {project.projectSkill && (
                                    <TableRow key={`${project._id}-skill`}>
                                        <TableCell><strong>Development Skill:</strong></TableCell>
                                        <TableCell>{project.projectSkill}</TableCell>
                                    </TableRow>
                                )}
                                {project.title1 && (
                                    <TableRow key={`${project._id}-title1`}>
                                        <TableCell><strong>Project Name:</strong></TableCell>
                                        <TableCell>{project.title1}</TableCell>
                                    </TableRow>
                                )}
                                {project.textarea && (
                                    <TableRow key={`${project._id}-description`}>
                                        <TableCell><strong>Project Description:</strong></TableCell>
                                        <TableCell>{project.textarea}</TableCell>
                                    </TableRow>
                                )}
                                {project.skills && (
                                    <TableRow key={`${project._id}-industry`}>
                                        <TableCell><strong>Industry:</strong></TableCell>
                                        <TableCell>{project.skills}</TableCell>
                                    </TableRow>
                                )}
                                {project.skills1 && (
                                    <TableRow key={`${project._id}-skills`}>
                                        <TableCell><strong>Skills:</strong></TableCell>
                                        <TableCell>{project.skills1}</TableCell>
                                    </TableRow>
                                )}
                                {project.skills3 && (
                                    <TableRow key={`${project._id}-programming`}>
                                        <TableCell><strong>Programmings:</strong></TableCell>
                                        <TableCell>{project.skills3}</TableCell>
                                    </TableRow>
                                )}
                                {project.type && (
                                    <TableRow key={`${project._id}-duration`}>
                                        <TableCell><strong>Duration:</strong></TableCell>
                                        <TableCell>{project.type}</TableCell>
                                    </TableRow>
                                )}
                                {project.Type && (
                                    <TableRow key={`${project._id}-budget`}>
                                        <TableCell><strong>Budget:</strong></TableCell>
                                        <TableCell>{project.Type}</TableCell>
                                    </TableRow>
                                )}
                                {/* {project.path && (
                                    <TableRow key={`${project._id}-path`}>
                                        <TableCell><strong>Path:</strong></TableCell>
                                        <TableCell>
                                        <a href={`${Organizationsuploads}${project.path}`} download>Download</a>
                                        </TableCell>
                                    </TableRow>
                                )} */}
                                <TableRow key={`${project._id}-divider`}>
                                    <TableCell colSpan={2}>
                                        <Divider sx={{ marginY: 1, backgroundColor: '#ccc' }} />
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Navigation Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 4 }}>
            <Button
                variant="contained"
                onClick={handlePreviousPage}
                disabled={currentPage === 0}
                sx={{
                    backgroundColor: '#052659',
                    color: '#fff',
                    marginRight: 2,
                    '&:disabled': {
                        backgroundColor: '#B0BEC5',  // Disabled button color
                    },
                }} >
                Previous
            </Button>
            <Button
                variant="contained"
                onClick={handleNextPage}
                disabled={(currentPage + 1) * itemsPerPage >= postProjects.length}
                sx={{
                    backgroundColor: '#052659',
                    color: '#fff',
                    '&:disabled': {
                        backgroundColor: '#B0BEC5',  // Disabled button color
                    },
                }}
            > 
                Next
            </Button>
            </Box>
        </Box>
    );
};

export default CompaniesDashboard;
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./learningpathprogress.css";

const CoursesProgress = ({ totalCoursesProgress }) => {
  // Ensure totalCoursesProgress is a valid number; default to 0 if invalid
  const progress = isNaN(totalCoursesProgress) || !isFinite(totalCoursesProgress)
    ? 0
    : Math.max(0, Math.min(100, Number(totalCoursesProgress))); // Clamp value between 0 and 100

  return (
    <div className="learningpathprogresesbar-backend">
      <div style={{ padding: "10px" }} className="learningpathprogressbar-backend">
        <div className="default1">
          <h1 className="learningpathprogressbar">Courses Progress</h1>
          <div>
            <div className="total-courses-progress">
              <div className="learningpath-progress-bar">
                <CircularProgressbar
                  value={progress}
                  text={`${progress.toFixed(0)}%`} // Display the progress percentage
                  styles={buildStyles({
                    pathTransitionDuration: 0.5,
                    pathColor: "#052659",
                    textColor: "#052659",
                    trailColor: "white",
                    textSize: "18px",
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesProgress;
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Typography, TextField, Button, CircularProgress, InputAdornment, IconButton, Grid, Paper } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {AdminGenerateOtp, AdminVerifyOtp, AdminForgotPassword } from "../../helper/base_url";

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [otp, setOTP] = useState('');
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null); 
  const [timeLeft, setTimeLeft] = useState(0);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const startTimer = () => {
    setTimeLeft(60);
    setTimer(setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          setOTP('');
        }
        return prevTime - 1;
      });
    }, 1000));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${AdminGenerateOtp}`, { username });
      setOTP(response.data.code);
      toast.success(response.data.message);
      startTimer();
    } catch (error) {
      toast.error("Failed to send OTP. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      const response = await axios.post(`${AdminVerifyOtp}`, { username, code: otp });
      if (response.data.success) {
        toast.success('OTP verified successfully!');
        clearInterval(timer);
        setTimeLeft(0);
        setOTP('');
        return true;
      } else {
        toast.error('Invalid OTP.');
        return false;
      }
    } catch (error) {
      toast.error('Failed to verify OTP.');
      return false;
    }
  };

  const resetPassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(`${AdminForgotPassword}`, {
        username,
        newPassword,
        confirmPassword
      });

      if (response.data.success) {
        toast.success('Password reset successful!');
        setTimeout(() => navigate('/adminlogin'), 2000);
      } else {
        toast.error(response.data.message || 'Failed to reset password.');
      }
    } catch (error) {
      toast.error('Failed to reset password.');
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      clearInterval(timer);
    }
  }, [timeLeft, timer]);

  return (
    <div className="admin-dashboard-users-data">
      <ToastContainer position="top-center" />
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={6} md={4} sx={{marginTop:10}}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          Admin Forgot Password
        </Typography>
        <Typography variant="body1">
          Recover your account by resetting your password.
        </Typography>
      </Box>

      {/* Email and Send OTP */}
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <TextField
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          sx={{
            whiteSpace: "nowrap",
            background: "linear-gradient(135deg, #5483B3, #052659)", // Corrected syntax
            color: "#fff",
            "&:hover": {
              background: "linear-gradient(135deg, #052659, #5483B3)", // Corrected syntax
            },
          }}
          >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Send OTP"}
          </Button>
        </Box>
      {/* OTP and Verify OTP */}
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <TextField
          label="Enter OTP"
          type="text"
          fullWidth
          variant="outlined"
          value={otp}
          onChange={(e) => setOTP(e.target.value)}
        />
        <Button
          onClick={verifyOTP}
          type="submit"
          variant="contained"
          disabled={loading}
          sx={{
            whiteSpace: "nowrap",
            background: "linear-gradient(135deg, #5483B3, #052659)", // Corrected syntax
            color: "#fff",
            "&:hover": {
              background: "linear-gradient(135deg, #052659, #5483B3)", // Corrected syntax
            },
          }}
          >
          Verify OTP
        </Button>
      </Box>
      {timeLeft > 0 && (
        <Typography variant="body2" color="error" textAlign="center" sx={{ mb: 2 }}>
          Time left for OTP verification: {timeLeft} seconds
        </Typography>
      )}

      {/* Password Reset Section */}
      <Box sx={{ mb: 3 }}>
        <TextField
          label="New Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          label="Confirm Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{ mb: 2 }}
        />
      </Box>
      <div className="users-login-btn">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={resetPassword}
          fullWidth
          disabled={loading}
          className="students-signup-btn"
        >
          {loading ? 'ResetPassword...' : 'Reset Password'}
        </Button>
      </div>
      </Paper>
      </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
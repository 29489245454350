import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Box, Typography, AppBar, Toolbar, Drawer, List, ListItem, ListItemText, Divider, Grid, useMediaQuery, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AssignmentTurnedIn, Person, ExitToApp, PendingActions, Assignment, CreateNewFolder } from '@mui/icons-material';
import { OrganizationsCompaniesprofile } from "../../helper/base_url";
import Active from './Active';
import Pending from './Pending';
import Completed from './Completed';
import Profile from './organizationProfile';
import './postprojects.css';

export const CompainesDashboard = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [data, setData] = useState({ companyName: "" });
    const [showToast, ] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem("company_token");
    const isTabletOrMobile = useMediaQuery('(max-width:960px)');  // Check if screen width is less than 960px

    const isMobile = useMediaQuery('(max-width:600px)');  // Check if screen width is less than 600px

    const [openDrawer, setOpenDrawer] = useState(false);  // State to control the drawer

    const handleUserProfile = useCallback(async () => {
        try {
            const response = await axios.post(`${OrganizationsCompaniesprofile}${data.companyName}`, {}, {
                headers: {
                    "content-type": "application/json",
                    authorization: `jwt ${localStorage.getItem('company_token')}`,
                },
            });

            if (response.data.success) {
                toast.success(response.data.message, { autoClose: 3000 });
                setData(response.data.data);
            } else {
                toast.error(response.data.message, { autoClose: 3000 });
            }
        } catch (error) {
            //console.error('Failed to update profile:', error);
            toast.error("Network error: Please check your connection and try again.", { autoClose: 3000 });
        }
    }, [data.companyName]);

    useEffect(() => {
        if (!token) {
            navigate("/");
        } else {
            handleUserProfile();
        }
    }, [token, navigate, handleUserProfile]);

    const handleLogOut = ()=>{
      console.log("Logout button clicked");
      localStorage.clear()
      navigate("/organizations")
  }

  const handleProject = ()=>{
    navigate("/organization")
}

    const handleToggleDrawer = () => setOpenDrawer(!openDrawer);  // Toggle drawer for mobile screens

    return (
        <div className="students-flex">
            <Box sx={{ display: 'flex', height: '99vh', backgroundColor: '#f5f5f5' }}>
                {/* Sidebar */}
                <Drawer
                    sx={{
                        width: 250,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 250,
                            boxSizing: 'border-box',
                            backgroundColor: '#052659',
                            color: '#fff',
                        },
                    }}
                    variant={isTabletOrMobile ? 'temporary' : 'permanent'}  // Use `temporary` for tablets and mobile                    anchor="left"
                    open={openDrawer}
                    onClose={handleToggleDrawer}
                >
                    <List>
                        <ListItem>
                          <Typography variant="h1" component={Link} to="/organizations"
                            sx={{
                                fontSize: { xs: "40px", md: "40px" },
                                fontWeight: "700",
                                color: "rgb(246, 248, 250)",
                                textDecoration: "none",
                            }}
                        >
                        Hue<span style={{ color: "#1c84e4" }}>bits</span>
                        </Typography>
                        </ListItem>
                <Divider />
                        
                        {/* Active Projects with Icon */}
                <ListItem
                button
                onClick={() => {
                    setActiveTab(0);
                    handleToggleDrawer(); 
                }}
                sx={{
                    cursor: 'pointer',
                    padding: 1,
                    backgroundColor: activeTab === 0 ? '#fff' : 'transparent',  // White background for active tab
                    color: activeTab === 0 ? '#1c84e4' : 'inherit',  // Blue text color for active tab
                    '&:hover': {
                    backgroundColor: activeTab === 0 ? '#fff' : 'rgba(255, 255, 255, 0.1)',  // Hover effect
                    },
                }}
                >
                <ListItemIcon sx={{ color: activeTab === 0 ? '#1c84e4' : '#fff', minWidth: 'auto', marginRight: 1 }}>
                    <AssignmentTurnedIn />
                </ListItemIcon>
                <ListItemText primary="Active Projects" sx={{ marginLeft: 0 }} />
                </ListItem>

                    {/* Pending Projects with Icon */}
                <ListItem
                button
                onClick={() => {
                    setActiveTab(1);
                    handleToggleDrawer();  // Close the drawer when the item is clicked
                }}
                sx={{
                    cursor: 'pointer',
                    padding: 1,
                    backgroundColor: activeTab === 1 ? '#fff' : 'transparent',
                    color: activeTab === 1 ? '#1c84e4' : 'inherit',
                    '&:hover': {
                    backgroundColor: activeTab === 1 ? '#fff' : 'rgba(255, 255, 255, 0.1)',
                    },
                }}
                >
                <ListItemIcon sx={{ color: activeTab === 1 ? '#1c84e4' : '#fff', minWidth: 'auto', marginRight: 1 }}>
                    <PendingActions />
                </ListItemIcon>
                <ListItemText primary="Pending Projects" sx={{ marginLeft: 0 }} />
                </ListItem>

                    {/* Completed Projects with Icon */}
                <ListItem
                button
                onClick={() => {
                    setActiveTab(2);
                    handleToggleDrawer();  // Close the drawer when the item is clicked
                }}
                sx={{
                    cursor: 'pointer',
                    padding: 1,
                    backgroundColor: activeTab === 2 ? '#fff' : 'transparent',
                    color: activeTab === 2 ? '#1c84e4' : 'inherit',
                    '&:hover': {
                    backgroundColor: activeTab === 2 ? '#fff' : 'rgba(255, 255, 255, 0.1)',
                    },
                }}
                >
                <ListItemIcon sx={{ color: activeTab === 2 ? '#1c84e4' : '#fff', minWidth: 'auto', marginRight: 1 }}>
                    <Assignment />
                </ListItemIcon>
                <ListItemText primary="Completed Projects" sx={{ marginLeft: 0 }} />
                </ListItem>

                    {/* Profile with Icon */}
                <ListItem
                button
                onClick={() => {
                    setActiveTab(3);
                    handleToggleDrawer();
                }}
                sx={{
                    cursor: 'pointer',
                    padding: 1,
                    backgroundColor: activeTab === 3 ? '#fff' : 'transparent',
                    color: activeTab === 3 ? '#1c84e4' : 'inherit',
                    '&:hover': {
                    backgroundColor: activeTab === 3 ? '#fff' : 'rgba(255, 255, 255, 0.1)',
                    },
                }}
                >
                <ListItemIcon sx={{ color: activeTab === 3 ? '#1c84e4' : '#fff', minWidth: 'auto', marginRight: 1 }}>
                    <Person />
                </ListItemIcon>
                <ListItemText primary="Profile" sx={{ marginLeft: 0 }} />
                </ListItem>

                    {/* Post Projects with Icon */}
                <ListItem
                button
                onClick={() => {
                    handleProject();
                    handleToggleDrawer();
                }}
                sx={{
                    cursor: 'pointer',
                    padding: 1,
                    backgroundColor: activeTab === 4 ? '#fff' : 'transparent',
                    color: activeTab === 4 ? '#1c84e4' : 'inherit',
                    '&:hover': {
                    backgroundColor: activeTab === 4 ? '#fff' : 'rgba(255, 255, 255, 0.1)',
                    },
                }}
                >
                <ListItemIcon sx={{ color: activeTab === 4 ? '#1c84e4' : '#fff', minWidth: 'auto', marginRight: 1 }}>
                    <CreateNewFolder />
                </ListItemIcon>
                <ListItemText primary="Postprojects" sx={{ marginLeft: 0 }} />
                </ListItem>

                {/* Hire Tech Talent with Icon */}
                <ListItem
                button
                onClick={() => {
                    handleToggleDrawer();
                }}
                sx={{
                    cursor: 'pointer',
                    padding: 1,
                    backgroundColor: activeTab === 4 ? '#fff' : 'transparent',
                    color: activeTab === 4 ? '#1c84e4' : 'inherit',
                    '&:hover': {
                    backgroundColor: activeTab === 4 ? '#fff' : 'rgba(255, 255, 255, 0.1)',
                    },
                }}
                >
                <ListItemIcon sx={{ color: activeTab === 4 ? '#1c84e4' : '#fff', minWidth: 'auto', marginRight: 1 }}>
                    <CreateNewFolder />
                </ListItemIcon>
                <ListItemText primary="Hire Tech Talent" sx={{ marginLeft: 0 }} />
                </ListItem>

                {/* Logout with Icon */}
                <ListItem button onClick={handleLogOut} sx={{ cursor: 'pointer', padding: 1 }}>
                    <ListItemIcon sx={{ color: '#fff', minWidth: 'auto', marginRight: 1 }}>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Logout" sx={{ marginLeft: 0 }} />
                </ListItem>
                    </List>
                </Drawer>

                {/* Main Content */}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        bgcolor: '#f5f5f5',
                        padding: 2,
                    }}
                >
                    <AppBar position="fixed" sx={{ backgroundColor: '#052659' }}>
                    {/* <AppBar position="sticky" sx={{ backgroundColor: '#052659' }}> */}
                        <Toolbar>
                            <Typography variant="h6" sx={{ flexGrow: 1,textAlign:'center', fontSize: isMobile ? '1.2rem' : '1.5rem' }}>
                                Welcome back, {data?.companyName}
                            </Typography>
                            {/* Menu button for tablets and mobile */}
                            {isTabletOrMobile && (
                                <Typography 
                                    variant="body1" 
                                    onClick={handleToggleDrawer} 
                                    sx={{ cursor: 'pointer', color: '#fff' }}
                                >
                                    <MenuIcon 
                                    onClick={handleToggleDrawer} 
                                    sx={{ cursor: 'pointer', color: '#fff' }} 
                                />
                                </Typography>
                            )}
                        </Toolbar>
                    </AppBar>

                    {/* Toast Notification */}
                    {showToast && <ToastContainer position="top-center" reverseOrder={false} />}

                    {/* Content Area */}
                    <Grid container spacing={1} sx={{ marginTop: 0 }}>
                        <Grid item xs={12}>
                            {activeTab === 0 && <Active />}
                            {activeTab === 1 && <Pending />}
                            {activeTab === 2 && <Completed />}
                            {activeTab === 3 && <Profile />}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
};

export default CompainesDashboard;
import { useState, useEffect } from 'react';
import { LearnersSubscription } from "../../../../helper/base_url";

const useLearnerStatus = () => {
const [learnerFound, setLearnerFound] = useState(false);
    
useEffect(() => {
    // Call the function inside useEffect
    fetchLearnerStatus();
  }, []);

const fetchLearnerStatus = async () => {
try {
    const response = await fetch(`${LearnersSubscription}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
    });

    const data = await response.json();
    if (data.status === 200 && data.learner) {
        setLearnerFound(true); // Learner found, do not show Getaccesstoallcourses
    } else {
        setLearnerFound(false); // Learner not found, show Getaccesstoallcourses
    }
} catch (error) {
    console.error("Error fetching learner status:", error);
    setLearnerFound(false); // Default to showing Getaccesstoallcourses on error
}
};
return learnerFound;
};

export default useLearnerStatus;
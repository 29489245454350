import React from 'react';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Footerhome from "../../assets/hue1 w.png";
import './footer.css';

const Footer = () => {
  return (
    <div className='homepage-footer'>
      <footer>
        <div className="homepage-footer-links">
          <div className='footer-linkh1-ul'>
          <ul>
            <h2 className="footer-linkh1">Quick links</h2>
            <li><Link to="/learners" className="footer-link">Learners</Link></li>
            <li><Link to="/internetofthings" className="footer-link">Internet of Things</Link></li>
            <li><Link className="footer-link">Data Science- AI/ML/DL</Link></li>
            <li><Link className="footer-link">Embedded Systems</Link></li>
          </ul>
          </div>

          <div className='footer-linkh1-ul'>
          <ul>
          <h2 className='footer-contact-infoh1'> Contact Us</h2>
          <li className='footer-contact-infoh1-icon'><FaEnvelope className="footer-contact-icon" /> hello@huebits.in</li>
          <li className='footer-contact-infoh1-icon'><FaMapMarkerAlt className="footer-contact-icon" /> India</li>
        </ul>
        </div>

          <div className='footer-linkh1-ul'>
          <ul>
            <h2 className="footer-linkh1">Legal & Accessibility</h2>
            <li><Link to="/terms-and-conditions" className="footer-link">Terms and Conditions</Link></li>
            <li><Link to="/privacy-policy" className="footer-link">Privacy Policy</Link></li>
          </ul>
          </div>
        <Link to="/">
        <img className="footer-hompage-image" src={Footerhome} alt="" />
        </Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

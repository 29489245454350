import React from 'react';
import { Box } from '@mui/material';
import "./postprojects.css"

export const Completed = () => {
  return (
    <Box sx={{ backgroundColor: '#f4f6f8', padding: '60px 20px 40px 20px' }}>
        <h1>Completed</h1>
    </Box>
  )
}

export default Completed;

import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Link } from "react-router-dom";
import { LearnersProfile } from "../../../helper/base_url";
import axios from "axios";

const Topbar = () => {
  const [data, setData] = useState({
    firstname: "",
    profilePic: "",
  });

  const token = localStorage.getItem("token");

  const handleUserProfile = async () => {
    try {
      const response = await axios.post(`${LearnersProfile}`, {}, {
        headers: {
          "content-type": "application/json",
          Authorization: `jwt ${localStorage.getItem('token')}`,
        },
      });
      setData(response.data.data);
    } catch (error) {
      // console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (token) {
      handleUserProfile();
    }
  }, [token]);

  // Media query for desktop screen size (screen width >= 790px)
  const isDesktop = useMediaQuery("(min-width: 790px)");

  return (
    <Box 
    sx={{
      justifyContent: "space-between",
      backgroundColor: "#f5f5f5",
      padding: "0px 20px 20px 0px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      textAlign: isDesktop ? "left" : "left", // Left align on desktop, center align on phone/tablets
    }}
    >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: isDesktop ? "left" : "center", // Left align on desktop, center align on phone/tablets
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#043885",
          fontWeight: "bold",
          lineHeight: "1.5",
          marginLeft: { xs: 6, sm: 10, md: "20px" }, // No margin on phone and tablet
          fontSize: { xs: "16px", sm: "20px", md: "30px" }, // Adjust text size based on screen size
          textAlign: { xs: "center", sm: "center", md: "left" }, // Center text on phone and tablet, left-align on desktop
        }}
      >
        Welcome{" "}
        <span style={{ color: "#4081c2", fontWeight: "bold" }}>
          {data?.fullname || data?.username || data?.displayName }
        </span>
        <span> Keep Going </span>
      </Typography>
      <Box display="flex" alignItems="center">
        <Link to="/dashboard/userprofile" style={{ position: "relative" }}>
          <IconButton>
            <Box
              sx={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
              }}
            >
              {data.profilePic ? (
                <img
                  src={data.profilePic}
                  alt="Profile"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <PersonOutlinedIcon sx={{ color: "#043885" }} />
              )}
            </Box>
          </IconButton>
        </Link>
      </Box>
    </Box>
    <Typography
    sx={{
        color: "#4b4d4f",
        fontSize: '16px',
        marginLeft: {
          xs: '0', // Phone view
          sm: '80px', // Tablet view
          md: '24px',  // Desktop view
      },
      marginTop: {
        xs: '0px', // Phone view
        sm: '0px', // Tablet view
    },
    textAlign: {
      xs: 'center', // Center text on phone view
      sm: 'left',   // Align left for tablet and larger views
  },
    }} >
      <span>
          You are close to realizing your dreams.
        </span>
      </Typography>
    </Box>
  );
};

export default Topbar;
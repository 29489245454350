import React, {useState, useEffect} from "react";
import { Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import behaves from "../../assets/pexels copy.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Grid, Button, IconButton} from '@mui/material';
import { FaTwitter, FaInstagram,FaLinkedin, FaYoutube } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import {OrganizationsLogin} from "../../helper/base_url";

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ username: "", password: "", userType: "company" });
    const navigate = useNavigate();

    // Check if user is already logged in
    useEffect(() => {
        if (localStorage.getItem('company_token')) {
            navigate("/organizations");
        }
    }, [navigate]);

    const handleOnChange = (e) => setData(prev => ({ ...prev, [e.target.name]: e.target.value }));

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const toastId = toast.loading("Your login request is being processed...");
        try {
            const response = await axios.post(`${OrganizationsLogin}`, data, {
                headers: { "Content-Type": "application/json" },
              });
              const { message, token, error } = response.data;
      toast.update(toastId, { render: message, type: error ? "error" : "success", isLoading: false, autoClose: 5000 });
      if (!error) {
        localStorage.setItem('company_token', token);
        // setTimeout(() => navigate("/organizations"), 1500);
        setTimeout(() => navigate("/compainesdashboard"), 1500);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Network error, please try again.';
      toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  };

    return (
        <div className="students-flex">
            <Headrepage />
            <div className="signup-pages-flex">
            <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
            <div className="studentssign-cssimagesflex-color" id="studentssign-cssimagesflex-color">
                <Grid container spacing={3} alignItems="center" justifyContent="center" className="studentssignup-cssimagesflex">
                <Grid item xs={12} sm={6} md={4} className="students-signup-background-color">
                <p className="center-signup">Log in to your Huebits account</p>
                <form id="signup-form" onSubmit={submitHandler}>
                <TextField 
                    type="email"
                    id="username"
                    name="username"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={data.username}
                    onChange={handleOnChange}
                    required
                />
                    <div style={{ position: "relative" }}>
                <TextField
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={data.password}
                    onChange={handleOnChange}
                    required
                />
                <IconButton
                    onClick={() => setShowPassword(prev => !prev)}
                    className="toggle-password-icon"
                    style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)"
                    }}
                >
                    {showPassword ? <span>👁</span> : <span>🔒</span>}
                </IconButton>
                </div>
                <div className="users-login-btn">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    className="students-signup-btn"
                >
                    {loading ? 'Logging in...' : 'Log in'}
                </Button>
                </div>
                </form>
                    <p className="students-frogot-password"><Link to="/companiesforgotpassword" className="students-frogot-password">Forgot Password</Link></p>
                    <div className="students-signup-accounts">
                        <p>
                            <span className="students-accounts-p">Don't have an account?{" "} </span>
                            <Link to="/companiessignup" className="signuphere">
                                Create an account
                            </Link>
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <img className="students-loginimage" src={behaves} alt="Login" />
                </Grid>
            </Grid>
           </div>
           <div className="homepage-footers-images">
            <Footer />
            <footer className="homepage-footers">
            <hr className='homepage-footer-hr'/>
              <div className="footer-social">
              <div className="homepage-footer-copyright">
                <p>© Huebits[2025].All Rights Reserved.</p>
              </div>
              <div className='footer-social-icons'>
                  <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                  <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                  <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                  <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
            </div>
            </div>
            </footer>
          </div>
          </div>
        </div>
    );
}

export default Login;
